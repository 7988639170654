import {Alert, Card} from "react-bootstrap";

import Loading from "../../Components/Layout/Elements/Loading";

import React, {useEffect, useState} from "react";
import MemeberAddForm from "../../Components/Members/MemeberAddForm";
import {addNewMember} from "../../Api/Members/MembersFetch";
import {useLocation, useNavigate} from "react-router-dom";
import {emptyError, handleErrors} from "../../Components/Errors";

const AddMember = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [newMember, setNewMember] = useState({})
    const [message, setMessage] = useState();
    const navigate = useNavigate()
    const [errors,setErrors]= useState()

    useEffect(() => {
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    useEffect(() => {
        if (Object.keys(newMember).length > 0) {
            emptyError()
            const emptyfields = document.querySelector('span.error_empty_field')

            if (!emptyfields) {
                setIsLoading(true)
                addNewMember({newMember, setIsLoading, navigate, setMessage, setErrors})
            }
        }
    }, [newMember])

    return (

        <>

            <Card className={'px-2 py-4 border-0 shadow'}>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
                <MemeberAddForm setNewMember={setNewMember} newMember={newMember}/>
                {isLoading && <Loading/>}
            </ Card>


        </>

    )
}

export default AddMember