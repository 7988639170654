import {Button, Col, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {CiEdit} from "react-icons/ci";
import CloneTeamModal from "../../Modals/Team/CloneTeamModal";
import {cloneTeam} from "../../../Api/Teams/TeamsFetch";
import { HiOutlineDuplicate } from "react-icons/hi";

const TeamCard = (props) => {
    const [show, setShow] = useState(false)
    const [withPlayers, setWithPlayers] = useState(0)
    const navigate = useNavigate()
    useEffect(() => {
        console.log(withPlayers)
    }, [withPlayers])
    const handleConfirm = () => {
        console.log(withPlayers)
        cloneTeam({
            id: props.id,
            withPlayers,
            setMessage: props.setMessage,
            setErrors: props.setErrors,
            setIsLoading: props.setIsLoading,
            navigate
        })
        setShow(false)
    }

    const handleCloneModal = () => {
        setShow(true)

    }
    return (
        <>
            <Row className={'align-items-center'}>
            <Col md={12} className={'d-flex justify-content-end '}>
                <Link to={''} onClick={handleCloneModal}
                      className={'btn btn-sm text-white me-2 bkg-primary text-decoration-none'}> <HiOutlineDuplicate
                    className={'fs-5'} title={'clona'}/> Clona </Link>
                <Link to={'/teams/modifica-team/' + props.id} className={'btn-sm btn bkg-second text-white text-decoration-none'}>
                    <CiEdit
                        className={'fs-5 '}/> Modifica
                </Link></Col>


            <Col md={12} className={'text-start'}>

                <h3 className={'fw-bold pt-2'}>
                        <span style={{backgroundColor: props.color ? props.color : '#333'}}
                              className={'rounded-5 fs-4  d-inline py-2 me-2 px-3 text-white'}>{props.name && props.name.charAt(0)}</span>
                    {props.name}
                </h3>

                <Row className={'align-items-center px-3'}>
                    <Col md={6} className={'text-start'}>
                        <h6 className={'fw-bold py-2'}>Coach: {props.coach}</h6>
                        Fondato il: 20/11/2012
                    </Col>
                    <Col md={6} className={'text-start'}>{props.description}.</Col>
                </Row>
                <Row>


                </Row>
            </Col>

        </Row>


            <Row className={'mx-0'}>

                <Col md={12} className={'bkg-second text-white rounded-3 mb-2 mt-0'}>
                </Col>


            </Row>
            <CloneTeamModal team_name={props.name} setShow={setShow} show={show} handleConfirm={handleConfirm}
                            setWithPlayers={setWithPlayers}/>
        </>
    )

}

export default TeamCard