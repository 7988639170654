import {Button, Col, Form, Modal, Row} from "react-bootstrap";

import {Link} from "react-router-dom";

const ConvocationsNoteModal = (props) => {
    const handleClose = () => {
        props.setShow(false)
        props.setNotes('')
    }
    const handleChange = (e) => {
        props.setNotes(e.target.value)

    }
    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton>Aggiungi o modifica nota</Modal.Header>
            <Modal.Body>
                <Form.Control as={'textarea'} defaultValue={props.notes && props.notes} onChange={handleChange}/>
               <Row className={'justify-content-end'}>
                   <Col className={'text-end'}>
                       <Button className={'btn btn-sm bkg-second text-white border-0 mt-2'} onClick={props.actionModal}>
                           Aggiungi
                       </Button>
                   </Col>
               </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ConvocationsNoteModal