import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, InputGroup, Alert} from "react-bootstrap";
import '../../assets/css/Login/Login.css'
import {Link, useNavigate} from "react-router-dom";
import {retrieveLoginData} from "../../Api/Auth/LoginFetch";
import AuthContext from "../../Contexts/authContext";

import logo from '../../assets/img/logo.png'

import {FaEye, FaEyeSlash} from "react-icons/fa";
import {emptyError, handleErrors} from "../../Components/Errors";
import Loading from "../../Components/Layout/Elements/Loading";

const Login = (props) => {
    const navigate = useNavigate()
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const [errors, setErrors] = useState()
    const [generalErrors, setGeneralErrors] = useState('')
    const {login} = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    console.log()
    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    const submitHandler = (e) => {
        e.preventDefault();
        emptyError()
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const emptyfields = document.querySelector('span.error_empty_field')
        if (!emptyfields) {
            retrieveLoginData({
                login,
                enteredEmail,
                enteredPassword,
                setErrors,
                navigate,
                setIsLoading,
                setGeneralErrors
            })
        }

    }
    //toggle password visibility on click eye icon
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>

            <Row className={'w-100 m-0 bgLogin container-full mw-100'}>


                <Row className={' justify-content-center text-center me-auto ms-auto align-content-center '}>
                    {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}
                    {!errors && generalErrors && typeof generalErrors === 'string' &&
                        <Alert variant={'danger'} onClick={() => {
                            setGeneralErrors(null)
                        }} dismissible> {generalErrors} </Alert>}
                    <Col></Col>
                    <Col className={'loginContainer  text-white py-5 px-3 rounded-3 shadow'}>

                        <Row>

                            <Col md={12}> <img src={logo} className={'img-fluid d-inline w-50'}/></Col>

                            <Col md={12}>
                                <hr style={{color: 'var(--secondary)', margin: '20px auto', borderWidth: '2px'}}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col className={'col-md-12 '}>
                                <Form onSubmit={submitHandler}>
                                    <Form.Group className={'position-relative'}>
                                        <InputGroup>
                                            {/*<InputGroup.Text
                                            className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0'}>
                                            <LuUser2
                                                color={'var(--secondary)'}/>  Change this to the appropriate icon component
                                        </InputGroup.Text>*/}
                                            <span className={'form-label'}></span>
                                            <Form.Control ref={emailInputRef} type="text" data-error={'email'}
                                                          className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                          placeholder="Email" name={'email'}
                                                          required={'required'} onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    submitHandler(e);
                                                }
                                            }}/>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group controlId="password">
                                        <InputGroup>
                                            <InputGroup>
                                                {/*    <InputGroup.Text
                                                className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0'}>
                                                <IoLockClosedOutline
                                                    color={'var(--secondary)'}/>  Change this to the appropriate icon component
                                            </InputGroup.Text>*/}
                                                <Form.Control
                                                    className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                    ref={passwordInputRef}
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Password"
                                                    name={'password'}
                                                    required={'required'}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') {
                                                            submitHandler(e);
                                                        }
                                                    }}
                                                />
                                                <InputGroup.Text onClick={togglePasswordVisibility}
                                                                 className={'my-2 bg-transparent border-top-0 border-0  rounded-0 position-absolute'}
                                                                 style={{right: '0'}}>
                                                    {!showPassword ? <FaEyeSlash color={'var(--secondary)'}/> :
                                                        <FaEye color={'var(--secondary)'}/>}
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </InputGroup>
                                    </Form.Group>

                                    <Button variant="primary"
                                            className={'mt-3 rounded-2 px-5 text-uppercase text-white fw-bold'}
                                            onClick={submitHandler}>
                                        Accedi
                                    </Button>
                                </Form>

                                <Link to={'/reset-password'} className={'text-white mt-3 small  d-block'}>Recupera la
                                    password</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
                {isLoading && <Loading/>}
            </Row>
        </>

    )
}

export default Login