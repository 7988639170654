import {Button, Form, Modal} from "react-bootstrap";
import {formatDataIt, formatDataItWithTime, formatDataTime, formatDataWithTime} from "../../../Utils/DateFormat";
import {useLocation} from "react-router-dom";
import {deleteTraining} from "../../../Api/Teams/Trainings/TrainingsFetch";

const ModalCalendarTrainingCopy = (props) => {

    const handleCopyTraining = () => {
        props.setCopyTraining({
            pattern: document.querySelector('select[name="pattern"]').value,
            start_date: document.querySelector('input[name="start_date"]').value,
        team_id: !props.id ? document.querySelector('select[name="team_id"]').value : ''
        })

    }

    return (
        <Modal show={props.showModalCopy} onHide={props.handleCloseModal} aria-labelledby="modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="modal-title">Copia allenamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!props.id && (
                    <Form.Select name="team_id">
                        <option>Seleziona Team</option>
                        {props.team && props.team.map(i => {
                            return (<option value={i.id}>{i.name}</option>)
                        })
                        }
                    </Form.Select>
                )}
                <Form.Select name={'pattern'} className={'my-2'}>
                    <option value={''}>Scegli opzione</option>
                    <option value={'week'}>Settimana</option>
                    <option value={'month'}>Mese</option>
                </Form.Select>
                <Form.Group className={'my-2'}>
                    <Form.Label>Data di partenza</Form.Label>
                    <Form.Control name={'start_date'} type={'date'} className={'form-control-sm'}/>
                </Form.Group>


            </Modal.Body>

            <Modal.Footer>

                <Button variant="secondary" onClick={props.handleCloseModal}>
                    Chiudi
                </Button>
                <Button variant="primary"
                        onClick={handleCopyTraining}>Copia allenamento</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCalendarTrainingCopy