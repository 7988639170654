import {Form, Row, Table} from "react-bootstrap";
import {AiOutlineUser} from 'react-icons/ai'
import {Link} from "react-router-dom";
import {formatDataIt} from "../../../../Utils/DateFormat";

const TeamConvocationTable = (props) => {
    return (
        <>
            <Table className={'table-responsive'}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start'>
                        Data partita
                    </th>
                    <th className='py-2 px-2 text-start'>
                        Squadra avversaria
                    </th>

                    <th className='py-2 px-2 text-start'>
                        Luogo
                    </th>
                </tr>
                </thead>
                <tbody className={'text-start'}>

                {props.latest_games.map((i, index) => {
                    return (
                        <tr key={index} className={'border-bottom-1'}>
                            <td>{formatDataIt(new Date(i.date_time))}</td>
                            <td>{i.opponent}</td>
                            <td>{i.location}</td>
                        </tr>
                    )
                })}

                </tbody>
            </Table>
        </>
    )

}

export default TeamConvocationTable