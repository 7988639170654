import {Modal} from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import {Link} from "react-router-dom";

const MembersContact = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <p><MdEmail className={'fs-5 me-2'}/> <Link to={'mailto:'+props.memberContact.email}>{props.memberContact.email} </Link></p>
                <p><BsFillTelephoneFill  className={'fs-5 me-2'}/>{props.memberContact.telephone_number}</p>
                <p><FaAddressCard  className={'fs-5 me-2'}/> {props.memberContact.address_line1} <br />
                    {props.memberContact.address_line2 && props.memberContact.address_line2} <br /> {props.memberContact.city}</p>

            </Modal.Body>
        </Modal>
    )
}

export default MembersContact