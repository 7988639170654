import {Button, Form, InputGroup, Nav} from "react-bootstrap";

const PaymentsFilters = (props) => {

    const handleFilter = (e) => {
        props.setPageUrl('/payments/report?')
        e.preventDefault();
        props.setFilterType(e.currentTarget.getAttribute('data-value'))

    }
    return (
        <>


            <Nav variant="pills" defaultActiveKey="link-0">
                <Nav.Item>
                    <Nav.Link eventKey="link-0" data-value={' '} onClick={handleFilter}>Tutti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1" data-value={'1'} onClick={handleFilter}>Pagati</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2" data-value={'0'} onClick={handleFilter}>
                       Non pagati
                    </Nav.Link>
                </Nav.Item>

            </Nav>


        </>
    )
}

export default PaymentsFilters