import React, {useContext, useState, useCallback, useEffect} from "react";
import {json, useLocation, useNavigate} from "react-router-dom";
import {logoutFetch} from "../Api/Auth/LogoutFetch";

const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(localStorage.getItem('selectedProfile') && JSON.parse(localStorage.getItem('selectedProfile')));
    const [jwt, setJwt] = useState('');
    const [sanctum, setSanctum] = useState('');
    const [expiration, setExpiration] = useState();
    const [role, setRole] = useState(null)
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        // Funzione per gestire il cambio nel localStorage per l'associationID
        const handleAssociationChange = () => {
            const storedselectedProfile = localStorage.getItem('selectedProfile');
            console.log('Stored Association ID:', storedselectedProfile); // Aggiunto per il controllo
            setProfile(storedselectedProfile); // Imposta solo il valore dell'associazione
            setUserProfile(storedselectedProfile)
        };


        // Aggiungi un listener per l'evento di cambio nel localStorage per l'associationID
        window.addEventListener('storage', handleAssociationChange);

        // Pulisci il listener quando il componente si smonta
        return () => {
            window.removeEventListener('storage', handleAssociationChange);
        };
    }, []); // L'effetto viene eseguito solo una volta all'inizio

    const login = (userData, sanctum, jwt, expiration) => {
        setUser(JSON.parse(userData));
        setJwt(jwt);
        setSanctum(sanctum);
        setExpiration(expiration);

        if (!!sanctum) {
            localStorage.setItem('sanctum', sanctum);
            /*    localStorage.setItem('jwt', jwt);*/
            localStorage.setItem('userData', userData)
            localStorage.setItem('isAuthenticated', true);
            /*    localStorage.setItem('expiration', expiration)*/
            setIsAuthenticated(localStorage.getItem('isAuthenticated'));
            navigate('/home')
        }
    }

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
        setUserProfile(null)
        setJwt('');
        setSanctum('')
        setProfile()
        localStorage.clear();
        navigate('/')
    }

    //controlla lo stato del token, se è autenticato e se è un ruolo esistente per reindirizzare alla home
    useEffect(() => {
        /*  let expiration = localStorage.getItem('expiration');*/
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        let userData = localStorage.getItem('userData');
        /*  let role = userData ? JSON.parse(userData).role.code : null;*/
        /*  let rolePermission = ['data_entry', 'superuser', 'single_user', 'referente_aziendale', 'support', 'wematica'];*/

            if(location.pathname !== '/reset-password'){
                if(isAuthenticated !== 'true'  || expiration === null || new Date() > new Date(expiration)){
                    logout()
                }else{
                    if(location.pathname === '/') navigate('/teams/lista-teams')
                }
            } /*else{
                if(isAuthenticated === 'true'){
                    navigate('/soci/lista-soci')
                }
            }*/

    }, []);

    return (
        <AuthContext.Provider value={{
            login,
            logout,
            isAuthenticated,
            profile,
            setProfile,
            user,
            setUser,
            userProfile,
            setUserProfile,
            sanctum,
            jwt,
            expiration,
            role
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext