import {Col, Row} from "react-bootstrap";
import React from "react";

const OverviewButtons = ({overview}) => {
    return (
        <Row className={'gap-2 flex-wrap my-3 mx-0'}>
            <Col
                className={'flex-1 p-3 border-0 shadow h-100 justify-content-center bg-warning text-white rounded-3'}>
                <h4>Da pagare: </h4>
                <h2>{overview.unpaid} €</h2></Col>
            <Col
                className={'flex-1 p-3 border-0 shadow h-100 justify-content-center bg-danger text-white rounded-3'}>
                <h4>Scaduto: </h4>
                <h2>{overview.expired} €</h2></Col>
            <Col
                className={'flex-1 p-3 border-0 shadow h-100 justify-content-center bg-success rounded-3 text-white'}>
                <h4>Pagato</h4>
                <h2>{overview.paid} €</h2></Col>
            <Col
                className={'flex-1 p-3 border-0 shadow h-100 justify-content-center bkg-second rounded-3 text-white'}>
                <h4>Totale: </h4>
                <h2>{overview.total} €</h2></Col>
        </Row>
    )
}
export default OverviewButtons