import {Form, Row, Table, Alert, Badge} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import TablePagination from "../Layout/Elements/TablePagination";
import {CiEdit} from "react-icons/ci";
import {RiContactsLine, RiDeleteBinLine, RiUserSettingsLine} from "react-icons/ri";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {deleteTeam} from "../../Api/Teams/TeamsFetch";
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import {deleteAssociation} from "../../Api/Admin/AdminFetch";


const AssociationsTable = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const navigate = useNavigate()
    const setMemberContact = props.setMembersContact
    const [actionMessage, setActionMessage] = useState()
    const [id, setId] = useState();

    const handleClose = () => {
        setShowConfirm(false)
    }
    const handleConfirm = (e) => {
        deleteAssociation({
            setIsLoading: props.setIsLoading,
            setErrors: props.setErrors,
            setMessage: props.setMessage,
            id,
            setUpdateRetrieve : props.setUpdateRetrieve
        })
        setShowConfirm(false)
    }
    const handleOnClick = (e) => {
        const contactID = e.currentTarget.getAttribute('id')
        console.log(contactID)
        const foundContact = props.associationsList.find(item => item.id == contactID);
        setMemberContact({
            email: foundContact.contact_email,
            telephone_number: foundContact.contact_number,
            address_line1: foundContact.address_line1,
            address_line2: foundContact.address_line2,
            city: foundContact.city
        })
        console.log(foundContact)
        props.setShow(true)

    }
    const handleEdit = (e) => {
        const contactID = e.currentTarget.getAttribute('id')

        navigate('/admin/modifica-associazione/' + contactID)


    }

    return (
        <>

            <Table className={'table-responsive'}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Nome
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Descrizione
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Contatti
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Codice Invito
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Azioni
                    </th>
                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.associationsList && props.associationsList.length > 0 ? props.associationsList.map((i) => {
                    // let date = new Date(i.birthday).toISOString()
                    return (
                        <tr className={'border-bottom-1'} key={i.id}>
                            <td>
                                <b> {i.name}</b>
                            </td>

                            <td>{i.description}</td>
                            <td> {i.contact_email} <br/>
                                {i.contact_number}
                            < /td>
                            <td> {i.invite_code}</td>
                            <td>

                                {/* <Link
                                    to={`/soci/dettaglio-socio/${i.id}`}><BiShow className={'fs-5 mx-2'}
                                                                                 title={'Mostra'}/>
                                </Link>*/}
                                <CiEdit className={'fs-5 mx-2'} title={'Modifica associazione'} onClick={handleEdit}
                                        id={i.id}/>
                                <RiContactsLine className={'fs-5 mx-2'} onClick={handleOnClick} id={i.id}
                                                title={'Contatti'}/>
                                <Link to={''} data-id={i.id} onClick={(e) => {
                                    setShowConfirm(true)
                                    setId(i.id)
                                    setActionMessage(`Sei sicuro di voler eliminare l\'associazione ${i.name} ?`)
                                }}><RiDeleteBinLine className={'fs-5 mx-2 text-danger'}/></Link>



                            </td>
                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessuna associazione trovata</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl} records={props.records}
                             handleExportTable={props.handleExportTable}
                             perPage={props.perPage}></TablePagination>

            <ConfirmationModal show={showConfirm} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>


        </>
    )

}

export default AssociationsTable