import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const BarsChart = ({ data }) => {
    const playerNames = Object.keys(data);
    const acceptanceRatios = playerNames.map(player => data[player].acceptance_ratio);

    const chartData = {
        labels: playerNames,
        datasets: [
            {
                label: 'Partecipazione',
                data: acceptanceRatios,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMin: 0, // Set the minimum value for the y-axis
                suggestedMax:100,
                ticks: {
                    stepSize: 10, // Set the interval between ticks

                },
            },

        },
    };

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarsChart