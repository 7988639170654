import {Col, Form, Nav, NavDropdown, Row} from "react-bootstrap";
import {AiOutlineUser} from "react-icons/ai";
import {BiChevronDown} from "react-icons/bi";
import React, {useContext, useEffect, useState} from "react";
import {retrieveProfileAssociations} from "../../../Api/Members/MembersFetch";
import {useLocation} from "react-router-dom";
import {logoutFetch} from "../../../Api/Auth/LogoutFetch";
import authContext from "../../../Contexts/authContext";
import {IoIosLogOut} from "react-icons/io";
import {GoArrowSwitch} from "react-icons/go";

const UserSubmenuHolder = (props) => {

    const {logout, setProfile} = useContext(authContext);

    function replaceAssociationName(profileId, associationId, newName) {
        // Trova il profilo corrispondente
        const profile = props.user && props.user.profiles && props.user.profiles.find(profile => profile.profile_id === profileId);
        if (profile) {
            // Trova l'associazione all'interno del profilo
            const association = profile.associations.find(assoc => assoc.association_id === associationId);
            if (association) {
                // Salva il vecchio nome dell'associazione
                const oldName = association.association_name;
                // Sostituisci il nome dell'associazione con quello desiderato
                association.association_name = newName;
                // Verifica se il nome è stato cambiato
                if (oldName !== newName) {
                    console.log("Nome dell'associazione sostituito con successo.");
                }
            } else {
                console.log("Associazione non trovata.");
            }
        } else {
            console.log("Profilo non trovato.");
        }
    }

    // Esempio di utilizzo della funzione per sostituire il nome dell'associazione con id 4 nel profilo con id 40
    replaceAssociationName(props.userProfile.profile_id, props.userProfile.association, props.userProfile.association_name);
// Visualizza il JSON aggiornato

    const handleLogout = () => {
        logoutFetch({setSelectedUser: props.setSelectedUser, setUser: props.setUser});
        setTimeout(() => {
            logout()
        }, 500);
    }

    const [pageTitle, setPageTitle] = useState('');
    const locationPart = window.location.pathname;

    useEffect(() => {
        // Definisci un oggetto mappato per associare i percorsi ai titoli delle pagine
        const titoliPagine = {
            '/lista-soci': 'Lista Soci',
            '/dettaglio-socio': 'Dettaglio Socio',
            '/aggiungi-socio': 'Aggiungi Socio',
            '/lista-teams': 'Lista Teams',
            '/dettaglio-team': 'Dettaglio Team',
            '/lista-comunicazioni': 'Lista Comunicazioni',
            '/nuova-comunicazione': 'Nuova comunicazione',
            '/nuovo-team': 'Nuovo Team',
            '/modifica-team': 'Modifica Team',
            '/nuova-partita': 'Nuova gara',
            '/modifica-partita': 'Modifica gara',
            '/dettaglio-partita': 'Dettaglio gara',
            '/dettaglio-comunicazione': 'Dettaglio Comunicazione',
            '/allenamenti': 'Allenamenti',
            '/nuovo-piano-allenamento': 'Nuovo Piano allenamenti',
            '/impostazioni': 'Impostazioni',
            '/nuova-associazione': 'Nuova Associazione',
            '/lista-associazioni': 'Lista associazioni',
            '/modifica-associazione': 'Modifica associazione',
            '/gare': 'Lista Gare',
            '/lista-pagamenti': 'Lista Pagamenti',
            '/overview': 'Overview Pagamenti',
            '/report': 'Report pagamenti',
            '/home': 'Dashboard',

        };
        const titoloCorrispondente = Object.keys(titoliPagine).find(
            key => locationPart.includes(key)
        );

        // Utilizza l'operatore ternario e l'oggetto mappato per impostare il titolo
        setPageTitle(titoliPagine[titoloCorrispondente] || 'Pagina Generica');
    })

    const handleOnClick = () => {
        document.querySelector('.userSubmenu').classList.toggle('d-none')
    }

    const handleProfileClick = (associationId) => {
        // Trova il profilo associato all'associazioneId
        const selectedProfile = props.user.profiles.find(profile =>
            profile.associations.some(association => association.association_id === parseInt(associationId))
        );

        if (selectedProfile) {
            // Trova l'associazione corrispondente all'associazioneId
            const selectedAssociation = selectedProfile.associations.find(association =>
                association.association_id === parseInt(associationId)
            );

            if (selectedAssociation) {
                // Esegui le azioni necessarie con il profilo selezionato
                const selectedData = {
                    profile_name: selectedProfile.first_name,
                    profile_id: selectedProfile.profile_id,
                    association: selectedAssociation.association_id,
                    association_name: selectedAssociation.association_name
                };

                // Salva i dati nel local storage
                localStorage.setItem('selectedProfile', JSON.stringify(selectedData));
                localStorage.setItem('associationID', associationId)
                setProfile(selectedData)
            } else {
                console.error(`Association with ID ${associationId} not found in the selected profile.`);
            }
        } else {
            console.error(`Profile with association ID ${associationId} not found.`);
        }
    };
    return (
        <>
            <Row className={' px-2 px-sm-5 mx-0 d-flex mb-3 align-items-center flex-row'}>

                <Col md={7} className={'text-start text-secondary'}><h2
                    className={'text-start text-second text-uppercase fw-bold mb-0'}> {pageTitle}</h2>{/*<Breadcrumb />*/}
                </Col>
                <Col className={' text-center justify-content-center mx-0  card shadow border-0 bkg-second text-white'}>
                    <div
                        className={'text-black position-relative my-3 align-items-center justify-content-between text-end  d-flex d-inline-block iconSubmenuHolder'}
                    >
                        <p className={'text-start text-white mb-0'}>
                            <b>{props.userProfile && props.userProfile.profile_name} - {props.userProfile && props.userProfile.association_name}</b>
                            <br/>
                            <span className={'small text-white'}>{props.userProfile && props.userProfile.email}</span></p>
                            <Nav className="ms-auto">
                                <NavDropdown title={<p className={'bgGradient rounded-5 mb-0 p-1'}><AiOutlineUser
                                    className={'text-white fs-2'}/></p>} id="basic-nav-dropdown" className={'align-items-center d-inline-flex'}>
                                    <NavDropdown.Item  onClick={() => {
                                        localStorage.removeItem('selectedProfile');
                                        props.setSelectedUser()
                                    }}> <a><GoArrowSwitch className={'fs-4 me-2'}/>Cambia profilo/associazione</a></NavDropdown.Item>
                                    <NavDropdown.Item  onClick={handleLogout}>
                                        <a><IoIosLogOut className={'fs-4 me-2'}/>Esci</a>
                                    </NavDropdown.Item>

                                </NavDropdown>
                            </Nav>
                        {/*<Form.Select onChange={(e) => handleProfileClick(e.target.value)}>
                            {props.user && props.user.profiles && props.user.profiles.map(profile => (
                                profile.associations.map(association => (
                                    <option key={association.association_id} value={association.association_id}
                                            selected={profile.profile_id === props.userProfile.profile_id
                                            && association.association_id === props.userProfile.association
                                                ? 'selected' : ''}>
                                        {profile.first_name} - {association.association_name}
                                    </option>
                                ))
                            ))}
                        </Form.Select>*/}
                      {/*  <p className={'bgGradient rounded-5 mb-0 p-1'}><AiOutlineUser
                            className={'text-white fs-2'} onClick={handleOnClick}/></p>*/}
                        {/*<div className={'userSubmenu position-absolute bg-white d-none text-start'}>
                            <ul className={''}>
                                <li className={'small'} onClick={() => {
                                    localStorage.removeItem('selectedProfile');
                                    props.setSelectedUser()
                                }}>
                                    <a><GoArrowSwitch className={'fs-4 me-2'}/>Cambia profilo/associazione</a>
                                </li>
                                <li className={'small'} onClick={handleLogout}>
                                    <a><IoIosLogOut className={'fs-4 me-2'}/>Esci</a>
                                </li>

                            </ul>
                        </div>*/}
                    </div>
                </Col>

            </Row></>
    )
}

export default UserSubmenuHolder