import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {downloadConvocations, retrieveGameDetail} from "../../Api/Teams/Games/TeamGamesFetch";
import {Alert, Badge, Button, Card, Col, Row, Table} from "react-bootstrap";
import {retrieveTrainingDetail} from "../../Api/Teams/Trainings/TrainingsFetch";

const TrainingDetailPage = () => {
    const {id} = useParams()
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [trainingDetail, setTrainingDetail] = useState()
    const {gameId} = useParams()
    useEffect(() => {
        retrieveTrainingDetail({idDetail: id, setTrainingDetail, setIsLoading, setErrors, setMessage});
    }, [])
    const date_time_str = trainingDetail && trainingDetail.start_time
    const date_time_str_end = trainingDetail && trainingDetail.end_time

// Format string to desired format
    const formatted_date_time = new Date(date_time_str).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
    const formatted_date_time_end = new Date(date_time_str_end).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });


    return (<>
        <Row>
            <Col>
                <Card className={'shadow border-0 py-3 px-3 text-start'}>
                    <Row>
                        {message && <Alert variant={'success'} onClick={() => {
                            setMessage(null)
                        }} dismissible> {message} </Alert>}
                        {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                            setErrors(null)
                        }} dismissible> {errors} </Alert>}
                        <Col md={6}><h2 className={'mb-3 text-start'}>{trainingDetail && trainingDetail.team}</h2></Col>

                        <Col md={6}>

                            <p><b>Inizio allenamento</b>: {trainingDetail && formatted_date_time} </p>
                            <p><b>Fine allenamento</b>: {trainingDetail && formatted_date_time_end} </p>
                            <p><b>Luogo</b>: {trainingDetail && trainingDetail.location}</p>
                        </Col>

                        <Col md={12}>
                            <Table>
                                <thead>
                                <tr>
                                    <th className={'bkg-primary text-white'}>Nome</th>
                                    <th className={'bkg-primary text-white'}>Ruolo</th>
                                    <th className={'bkg-primary text-white'}>Partecipa</th>
                                </tr>
                                </thead>
                                <tbody>
                                {trainingDetail && trainingDetail.invitations.length > 0 ? trainingDetail.invitations.map(conv => {
                                    return (
                                        <tr key={conv.id}>
                                            <td>{conv.profile_name}</td>
                                            <td>{conv.sport_role_name}</td>
                                            <td><Badge
                                                bg={conv.has_accepted === null ? 'dark' : (conv.has_accepted === false ? 'danger' : 'success')}>{conv.has_accepted === null ? 'Non confermato' : (conv.has_accepted === false ? 'No' : 'Si')}</Badge>
                                            </td>
                                        </tr>

                                    )
                                }) : (
                                    <tr>
                                        <td colSpan={7}><h4 className={'text-center'}>Nessun partecipante trovato</h4></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

    </>)
}

export default TrainingDetailPage