import React, { useEffect, useState } from "react";
import { DocumentsType } from "../../Api/DocumentsFetch";
import { Form } from "react-bootstrap";

const DocumentTypeSelect = ({ setErrors, setIsLoading, setSelectedAttribute, selectedAttribute}) => {
    const [documentType, setDocumentType] = useState([]);


    useEffect(() => {
        DocumentsType({ setDocumentType, setErrors, setIsLoading });
    }, []);

    useEffect(() => {
        console.log(documentType);
    }, [documentType]);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.selectedOptions[0];
        const enableValidityDates = selectedOption.getAttribute("data-attribute");
        setSelectedAttribute(enableValidityDates);
        console.log("data-attribute:", enableValidityDates);
    };

    return (
        <>
            <Form.Label className="text-start">Seleziona tipologia documento</Form.Label>
            <Form.Select name="document_type_id" onChange={handleSelectChange}>
                <option>Seleziona</option>
                {documentType && documentType.length > 0 && documentType.map((doc) => {
                    return (
                        <option key={doc.id} value={doc.id} data-attribute={doc.enable_validity_dates}>
                            {doc.name}
                        </option>
                    );
                })}
            </Form.Select>


        </>
    );
};

export default DocumentTypeSelect;
