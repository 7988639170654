import axiosInstance from "../AxiosInstance";

export const retrieveSportsList = (props) => {


    let url = '/associations/sports'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)

        console.log('Sports', response.data.data)
        props.setSportsList(response.data.data)
        // props.setIsLoading(false)
    }).catch((error) => {
        // console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveCoachesList = (props) => {


    let url = '/associations/coaches/'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)

        console.log('Coaches', response.data.data)
        props.setCoachesList(response.data.data)
        // props.setIsLoading(false)
    }).catch((error) => {
        // console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveSportsRoles = (props) => {


    let url = '/associations/sports/'+props.sportID+'/roles'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)

        console.log('Sports', response.data.data)
        props.setSportRoles(response.data.data)
        // props.setIsLoading(false)
    }).catch((error) => {
        // console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveAllTrainings = (props) => {
    props.setIsLoading && props.setIsLoading(true)
    let pageUrl= '/team/training-schedule/all?'
    const symbol = pageUrl.includes('?') ? '&' : '?';
    let url = pageUrl + symbol

    const query = props.search ? Object.keys(props.search)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
        .join('&') : null;

    console.log(query)
    if (query !== null) url += '&' + query;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)

        console.log('Trainings', response.data.data)
        props.setTrainings(response.data.data)
        props.setIsLoading && props.setIsLoading(false)
    }).catch((error) => {
        // console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}