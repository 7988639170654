import {Button, Form, InputGroup, Nav} from "react-bootstrap";

const MembersFilters = (props) => {


    const handleFilter = (e) => {
        e.preventDefault();
        const name = e.currentTarget.getAttribute('data-value')
        props.setFilterType(name)
        props.setPageUrl('/profile?')
    }
    return (
        <>


            <Nav variant="pills" defaultActiveKey="link-0">
                <Nav.Item>
                    <Nav.Link eventKey="link-0" data-value={'filter_type=0'} onClick={handleFilter}>Tutti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1" data-value={'filter_type=1'} onClick={handleFilter}>Soci</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2" data-value={'filter_type=2'} onClick={handleFilter}>
                        In Attesa
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-3" data-value={'filter_type=3'} onClick={handleFilter}>
                        Rimossi
                    </Nav.Link>
                </Nav.Item>
            </Nav>


        </>
    )
}

export default MembersFilters