import Calendar from "../../Components/Calendar/Calendar";
import React, {useContext, useEffect, useState} from "react";
import {retrieveAllTrainings} from "../../Api/Associations/AssociationsFetch";
import {addTraining, copyTrainingFetch, deleteTraining, editTraining} from "../../Api/Teams/Trainings/TrainingsFetch";
import {useParams} from "react-router-dom";
import TrainingsFilters from "../../Components/Trainings/TrainingsFilters";
import {Card} from "react-bootstrap";
import {retrieveTeamsNames} from "../../Api/Communications/CommunicationsFetch";
import authContext from "../../Contexts/authContext";
import chroma from 'chroma-js';
import Loading from "../../Components/Layout/Elements/Loading";

const AllTrainings = () => {
    const {profile} = useContext(authContext)
    // const [associationID, setAssociationID] = useState('association_id='+localStorage.getItem('associationID'))
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [trainings, setTrainings] = useState([])
    const [events, setEvents] = useState()

    const [isLoading, setIsLoading] = useState(false)
    const [isAll, setIsAll] = useState(true)
    const [search, setSearch] = useState([])
    const [teamOption, setTeamOptions] = useState([])
    const [team, setTeam] = useState()
    const [teamList, setTeamsList] = useState([])
    const [pageUrl, setPageUrl] = useState('/team?')

    useEffect(() => {

    })
    useEffect(() => {
        retrieveAllTrainings({setErrors, setMessage, setTrainings, search, setIsLoading})

    }, [profile, search, profile])

    useEffect(() => {
        if (trainings && trainings.length > 0) {

            const allEvents = trainings.flatMap(training => {
                const computedStyle = getComputedStyle(document.documentElement);
                const teamColor = training.team_color ? training.team_color : computedStyle.getPropertyValue('--secondary').trim();
                const adjustedColor = teamColor ? chroma(teamColor).darken(0.3).luminance(0.5).hex() : '#000000'; // Esempio di utilizzo di chroma.js per scurire il colore
                const teamName = training.team_name

                return training.training_schedules.map(schedule => ({
                    team_id: training.team_id,
                    team_name: teamName,
                    start: schedule.start_time,
                    end: schedule.end_time,
                    title: schedule.location,
                    id: schedule.schedule_id,
                    display: 'block',
                    type: schedule.type,
                    borderColor: schedule.type == 1 ? adjustedColor : teamColor,
                    backgroundColor: schedule.type == 1 ? adjustedColor : teamColor,
                    plan: schedule.plan

                }));
            });

            setEvents(allEvents);


        }
    }, [trainings]);


    useEffect(() => {
        /*Chiamata per prendere i dati del team per popolare la select nei filtri e nella modal*/
        retrieveTeamsNames({
            setTeamsList,
            pageUrl,
            setIsLoading,
            setErrors,
            setTeamOptions
        })
    }, [profile])


    useEffect(() => {
        /*Imposto i dati per la select */
        if (teamList) {
            setTeam(teamOption.map(i => {
                return {
                    id: i.value,
                    name: i.label
                }
            }))
        }
    }, [teamList])


    return (
        <>
            <Card className={'border-0 py-2 shadow mb-4'}>
                <TrainingsFilters setSearch={setSearch} teamOptions={teamOption} team={team}></TrainingsFilters>
            </Card>
            <Card id={'calendar'} className={'border-0 shadow p-2'}>
                <Calendar setEvents={setEvents} events={events}
                          teamOption={teamOption}
                          teamList={teamList} team={team} profile={profile} setTrainings={setTrainings}
                          isAll={isAll}></Calendar>
            </Card>
            {isLoading && <Loading/>}
        </>

    )
}

export default AllTrainings