import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {editAssociationsSettings, retrieveAssociationInfos} from "../../Api/Settings/SettingsFetch";
import AuthContext from "../../Contexts/authContext";


const AssociationSettingsForm = (props) => {
    /*    const [associationInfo, setAssociationInfo] = useState()
        const [updatedAssociationInfo, setUpdatedAssociationInfo] = useState()
        const [message, setMessage]= useState()
        const [errors, setErrors]= useState()
        const {profile, setUserProfile} = useContext(AuthContext)*/

    /*    useEffect(() => {
            retrieveAssociationInfos({setAssociationInfo})
        }, [profile])

        useEffect(() => {
            console.log('info', associationInfo)
            setAssociationInfo(associationInfo)
        }, [associationInfo, profile])*/

    const handleEdit = (e) => {
        e.preventDefault()


        for (let i of document.querySelectorAll('form input, form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')

                const value = i.value;
                console.log(i.value)
                const updatedValue = {[name]: value}
                props.setUpdatedAssociationInfo(info => ({
                        ...info, ...updatedValue
                    })
                )
            }
            console.log(props.updatedAssociationInfo)
        }

    }

    useEffect(() => {
        if (props.updatedAssociationInfo !== undefined) {
            editAssociationsSettings({
                updatedAssociationInfo: props.updatedAssociationInfo,
                setMessage: props.setMessage,
                setErrors: props.setErrors,
                setUpdatedAssociationInfo: props.setUpdatedAssociationInfo,
                setUserProfile: props.setUserProfile
            })
        }
    }, [props.updatedAssociationInfo])
    return (<>
        <Row className={'card py-3 border-0 shadow'}>
            <h2 className={'text-start'}>Cambia dati associazione</h2>
            <Form>

                <Col className={'text-start'}>
                    <Form.Label className={'text-start'}>Nome associazione</Form.Label>
                    <Form.Control type={'text'} name={'association_name'}
                                  defaultValue={props.associationInfo && props.associationInfo.name}></Form.Control> </Col>
                <Col>
                    <Button type={"submit"} onClick={() => {

                    }}
                            className={'col-md-2 mt-3 btn btn-sm d-block bkg-second no-border border-0'}
                            onClick={handleEdit}>Modifica</Button>
                </Col>

            </Form>

        </Row>

    </>)
}

export default AssociationSettingsForm