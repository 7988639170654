import axiosInstance from "../AxiosInstance";

export const retrieveRoles = (props) => {
    let url = '/roles/'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log(response.data.data)
        props.setRoles(response.data.data)

         // setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

