import React, {useEffect, useState} from 'react'
import {FaEye} from "react-icons/fa";
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import itLocale from '@fullcalendar/core/locales/it';
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction';
import {v4 as uuid} from "uuid";
import '../../assets/css/Calendar/Calendar.css'
import ModalCalendarTraining from "../Modals/Calendar/ModalCalendarTraining";
import {Link, useLocation} from "react-router-dom";
import {formatDataIt, formatDataItWithTime, formatDataTime} from "../../Utils/DateFormat";
import ModalCalendarTrainingCopy from "../Modals/Calendar/ModalCalendarTrainingCopy";
import ModalCalendarTrainingDetail from "../Modals/Calendar/ModalCalendarTrainingDetail";
import {
    addTraining, copyTrainingFetch,
    deleteTraining,
    editTraining
} from "../../Api/Teams/Trainings/TrainingsFetch";
import CustomEvent from "./CustomEvent";


const Calendar = (props) => {
    const [isDetail, setIsDetail] = useState(false)
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [eventDetail, setEventDetail] = useState()
    const [trainingDetail, setTrainingDetail] = useState()
    const [selectedInfoDay, setSelectedInfoDay] = useState({start: "", end: ""});
    const [editMode, setEditMode] = useState(false)
    const [newEvent, setNewEvent] = useState()
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const [message, setMessage] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [copyTraining, setCopyTraining] = useState();
    const [showModalCopy, setShowModalCopy] = useState(false);
    const [showModal, setShowModal] = useState()
    const [editedEvent, setEditedEvent] = useState()

    useEffect(() => {
    }, [props.events])

    console.log(errors, generalError)

    useEffect(() => {
        /*Chiamata aggiunta nuovo evento*/
        if (newEvent) {
            console.log(newEvent)
            addTraining({
                newEvent,
                setIsLoading,
                setErrors,
                isAll: props.isAll,
                setNewEvent,
                setTeamDetail: props.setTeamDetail && props.setTeamDetail,
                setShowModal,
                setTrainings: props.setTrainings && props.setTrainings,
                team_id: props.team_id && props.team_id,
                setGeneralError
            })
        }

    }, [newEvent])
    useEffect(() => {
        /*Chiamata per modifica evento */
        if (editedEvent) {
            editTraining({
                editedEvent,
                setIsLoading,
                setErrors,
                setEditedEvent,
                setEventDetail,
                setEditMode,
                setTeamDetail: props.setTeamDetail,
                setShowModal,
                isAll: props.isAll,
                team_id: editedEvent && editedEvent.team_id ? editedEvent.team_id : props.id,
                setTrainings: props.setTrainings,
                setGeneralError
            })


        }
    }, [editedEvent])

    useEffect(() => {
        /*Chiamata per copia evento */
        if (copyTraining) {
            console.log()
            copyTrainingFetch({
                copyTraining,
                setIsLoading,
                setErrors,
                setTrainings: props.setTrainings,
                setTeamDetail: props.setTeamDetail && props.setTeamDetail,
                setShowModalCopy,
                team_id: props.team_id ? props.team_id : copyTraining.team_id !== '' && copyTraining.team_id,
                setCopyTraining
            })

        }

    }, [copyTraining])
    useEffect(() => {
        /*Se entro nel dettaglio non editabile dell'evento, imposto il dettaglio evento con le invitations*/
        if (trainingDetail) {
            console.log('trainingDetail', trainingDetail.id)
            setEventDetail({
                title: trainingDetail.title,
                start: formatDataItWithTime(new Date(trainingDetail.start_time)),
                end: formatDataItWithTime(new Date(trainingDetail.end_time)),
                id: trainingDetail.id,
                invitations: [trainingDetail.invitations],
                team_name:trainingDetail.team_name,
                plan: trainingDetail.plan
            })
        }
    }, [trainingDetail])

    const handleCloseModal = () => {
        if (showModal) {
            setShowModal(false);

            setSelectedInfoDay()
            setEditedEvent();
            setEventDetail()
            setNewEvent()
            setEditMode(false)
        } else if (showModalCopy) {
            setShowModalCopy(false)
        } else if (showModalDetail) {
            setShowModalDetail(false)
            setIsDetail(false)
            setTrainingDetail()
            setEventDetail()
        }
        setErrors()
        setGeneralError()
    };
    /*Al click sulla cella vuota del calendario, apro la modal per aggiungere un nuovo evento*/
    const handleDayClick = (info) => {
        setEditedEvent()
        setSelectedInfoDay(info)
        setShowModal(true);

    };

    function handleDeleteEvent(e) {
        deleteTraining({
            id: e.currentTarget.getAttribute('data-id'),
            setIsLoading,
            setErrors,
            setEditedEvent,
            setTeamDetail: props.setTeamDetail,
            setShowModal,
            team_id: eventDetail && eventDetail.team_id,
            isAll: props.isAll,
            setTrainings: props.setTrainings
        })
    }

    /*Setto il dettaglio evento per la modifica nel form */
    const editEvent = (info) => {
        if (!isDetail) {
            setShowModal(true);
            setEditMode(true)
            setEventDetail({
                title: info.event.title,
                start: formatDataTime(info.event.start),
                end: formatDataTime(info.event.end),
                id: info.event.id,
                team_id: info.event.extendedProps.team_id && info.event.extendedProps.team_id
            })

        }
    }




    return (
        <>
            <FullCalendar
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
                }}
                businessHours={{
                    startTime: '8:00', // a start time (10am in this example)
                    endTime: '23:00', // an end time (6pm in this example)
                }}
                dayMaxEventRows={true}
                eventMaxStack={2}
                height={props.height ? props.height : 'auto'}
                initialView={props.initalview ? props.initalview : "dayGridMonth"}
                slotDuration={'00:15:00'}
                nowIndicator={true}
                allDaySlot={false}
                slotMinTime={'8:00'}
                slotMaxTime={'21:00'}
                events={props.events ? props.events : []}
                weekends={true}
                eventClick={editEvent}
                selectable={true}
                locale={itLocale}
                select={handleDayClick}
                eventContent={({event}) => {
                    return (<CustomEvent event={event} setEventDetail={setEventDetail}
                                         setShowModalDetail={setShowModalDetail} setIsDetail={setIsDetail}
                                         setTrainingDetail={setTrainingDetail}/>)
                }}

            />
            {showModal && <ModalCalendarTraining showModal={showModal} id={props.id} handleCloseModal={handleCloseModal}
                                                 events={props.events}
                                                 setEvents={props.setEvents} selectedInfoDay={selectedInfoDay}
                                                 setNewEvent={setNewEvent} uuid={uuid} eventDetail={eventDetail}
                                                 editMode={editMode} setEditedEvent={setEditedEvent}
                                                 handleEditEvent={handleDeleteEvent} teamOption={props.teamOption}
                                                 teamList={props.teamList} team={props.team} setTeam={props.setTeam} generalError={generalError} setGeneralError={setGeneralError} errors={errors} setErrors={setErrors}/>
            }


            <ModalCalendarTrainingCopy showModalCopy={showModalCopy} id={props.id}
                                       handleCloseModal={handleCloseModal}
                                       handleEditEvent={handleDeleteEvent} setCopyTraining={setCopyTraining}
                                       team={props.team}/>
            <ModalCalendarTrainingDetail showModalDetail={showModalDetail} id={props.id}
                                         handleCloseModal={handleCloseModal} eventDetail={eventDetail} />
        </>
    )

}

export default Calendar