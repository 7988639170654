import axiosInstance from "../AxiosInstance";


export const retrieveCommunications= (props) => {
    props.setIsLoading(true)
    const symbol = props.pageUrl.includes('?') ? '&' : '?';
    let url = props.pageUrl + symbol + props.perPage;

    const query= props.search ? Object.keys(props.search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
        .join('&') : null;

    /* const filter = filterSort ? Object.keys(filterSort)
         .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
         .join('&') : null;*/

    if(query  != null) url += '&' + query;
    // if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Comunicazioni', response)

        props.setCommunicationsList(response.data.data.data)
        props.setPagination(response.data.data.links)
        console.log(response.data.data.links)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveTeamsNames = (props) => {

    let url = '/team?full_collection=true';

    props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setTeamsList(response.data.data)
        props.setTeamOptions && props.setTeamOptions(response.data.data.map(i => {
            return{label: i.name, value: i.id }
        }) )
        console.log('RetrieveTeamsNames', response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveMembersNames = (props) => {

    let url = '/profile?full_collection=true&filter_type=1';


    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setMembersList(response.data.data)
        props.setMembersOptions(response.data.data.map(i => {
            return{label: i.first_name+' '+i.last_name, value: i.id }
        }) )

        console.log('RetrieveMembersName', response.data.data)

        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addCommunicationFetch = (props) => {
    let url = 'communication'
    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.post(url, props.formData, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            },
            'params': props.addCommunication,
        },
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('add communication', response)
        props.setMessage(response.data.message)
        props.setIsLoading &&  props.setIsLoading(false)
        setTimeout(function () {
            props.navigate('/comunicazioni')
        },1000)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)

            props.setErrors(error.response.data.errors);
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveCommunicationDetail = (props) => {
    let url = 'communication/'+props.id

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('add comm', response)

        props.setCommunicationDetail(response.data.data)
        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const downloadAttachment = (props) => {
    let url = 'communication/'+props.newsId+'/attachment/'+props.id

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('downloadFile', response)
        console.log('downloadFile', response.data.data.link)
        // Apri il link in una nuova finestra
        if (response.data && response.data.data && response.data.data.link) {
            window.open(response.data.data.link, '_blank');
        }

        // props.setCommunicationDetail(response.data.data)
        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteCommunications =(props) => {
    let url = 'communication/'+props.newsId

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.delete(url
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('deleteCommunication', response)
        props.setMessage && props.setMessage(response.data.message)
        props.setIsLoading &&  props.setIsLoading(false)
        /*Se elimino dalla tabella cambio lo stato che triggera la chiamata per fare l'update della lista */
        props.setUpdateComm && props.setUpdateComm(!props.updateComm)
        /*Se sono nel dettaglio comunicazione, faccio redirect alla tabella */
        props.navigate && props.navigate('/comunicazioni/lista-comunicazioni')
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}