import { Pagination } from 'react-bootstrap';

const CustomPagination = ({ totalItems, currentPage, itemsPerPage, onPageChange, maxPagesToShow }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const renderPaginationItems = () => {
        const paginationItems = [];

        // Calculate start and end pages
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        // Add "Go to first page" link instead of ellipsis at the beginning
        if (currentPage > Math.floor(maxPagesToShow / 2) + 1) {
            paginationItems.push(
                <Pagination.First key="first-page" onClick={() => onPageChange(1)} />
            );
        }

        // Add page numbers
        for (let page = startPage; page <= endPage; page++) {
            paginationItems.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        // Add ellipsis at the end if needed
        if (endPage < totalPages) {
            paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
        }

        return paginationItems;
    };

    return (
        <Pagination className="mt-3">
            {renderPaginationItems()}
        </Pagination>
    );
};



export default CustomPagination;