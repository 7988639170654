import axiosInstance from "../AxiosInstance";

export const retrieveAssociationInfos = (props) => {

    let url = '/settings'


    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setAssociationInfo(response.data.data)
        console.log('Association Infos', response)

        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const editAssociationsSettings = (props) => {
    let url = '/settings'
    axiosInstance.post(url, props.updatedAssociationInfo).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Modifica Impostazioni', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setUpdatedAssociationInfo()
        const test= JSON.parse(localStorage.getItem('selectedProfile'))
        console.log(test)

        test.association_name = props.updatedAssociationInfo.association_name
        localStorage.setItem('selectedProfile', JSON.stringify(test))
        props.setUserProfile(JSON.parse(localStorage.getItem('selectedProfile')))

        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}