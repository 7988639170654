import axiosInstance from "../../AxiosInstance";

export const retrieveTeamAvailablePlayers = (props) => {
    props.setIsLoading(true)

    let url = '/team/'+props.id+'/availablePlayers'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setAvailablePlayers(response.data.data)
        props.setPagination && props.setPagination(response.data.data.links)

        console.log('retrieve Team Players', response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveTeamPlayers = (props) => {
    props.setIsLoading(true)

    let url = '/team/'+props.id+'/players'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setPlayers(response.data.data)
        props.setPagination && props.setPagination(response.data.data.links)

        console.log('retrieve Team Players', response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addTeamPlayers = (props) => {
    props.setIsLoading(true)

    let url = '/team/'+props.id+'/players'

    axiosInstance.post(url,{players:  props.addPlayers}).then((response) => {

        console.log('Add Players', response)

        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdate(!props.update)
        props.setAddPlayers([])

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deletePlayer =(props) => {
    props.setIsLoading(true)

    let url = '/team/'+props.id+'/players/'+props.playerId

    axiosInstance.delete(url).then((response) => {

        console.log('delete Convocation', response)
        props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdate(!props.update)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}