import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the Quill styles


const MyEditor = (props) => {



    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image'],
            [{'color': []}, {'background': []}],
            ['clean'],
        ],

    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link', 'image',
        'color', 'background',
    ];


    const handleEditorChange = (html) => {
        props.setEditorHtml(html);
    };

    return (
        <div>
            <ReactQuill
                theme="snow"
                value={props.editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                man
            />
        </div>
    );
};

export default MyEditor;