import TeamAddForm from "../../Components/Teams/Addteam/TeamAddForm";
import React, {useContext, useEffect, useState} from "react";
import {addNewTeam, reatrieveSeasons, retrieveSeasons} from "../../Api/Teams/TeamsFetch";
import {retrieveSportsList, retrieveCoachesList} from "../../Api/Associations/AssociationsFetch";
import {Alert, Card} from "react-bootstrap";
import members from "../Members/Members";
import {useNavigate} from "react-router-dom";
import authContext from "../../Contexts/authContext";

const NewTeam = () => {
    const [newTeam, setNewTeam] = useState([]);
    const [add, setAdd] = useState()
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/team/store')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])

    const [teamDetail, setTeamDetail] = useState()
    const navigate = useNavigate()

    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()

    useEffect(() => {
        const members = selectedMembers.map(i => {
            return {
                'profile_id': i.value
            }
        })
        setAdd((prevValue) => ({
            ...prevValue, ...newTeam
        }))
        if (selectedMembers && selectedMembers.length > 0) {
            setAdd(prevState => ({
                ...prevState, 'members': members
            }))
        }
        if (Object.values(newTeam).length > 0 && Object.values(newTeam) !== null) {
            setAdd(prevState => {
                let teamDatas = {...prevState, ...newTeam};

               addNewTeam({pageUrl, teamDatas, setIsLoading, newTeam, navigate, setErrors, setMessage, setGeneralError});
                return prevState;  // Restituisci lo stato aggiornato
            });
            console.log(newTeam)
        }

    }, [newTeam])



    return (
        <Card className={'p-4 shadow borde-0'}>
            {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                setMessage(null)
            }} dismissible> {message} </Alert>}
            <TeamAddForm setAdd={setAdd} newTeam={newTeam}
                         selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers}
                         setNewTeam={setNewTeam} teamDetail={teamDetail} setErrors={setErrors} errors={errors} generalError={generalError} setGeneralError={setGeneralError} />
        </Card>

    )
}
export default NewTeam