import axiosInstance from "../../AxiosInstance";
import {retrieveTeamDetail} from "../TeamsFetch";
import {retrieveAllTrainings} from "../../Associations/AssociationsFetch";

export const addTraining = (props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/new'
    axiosInstance.post(url, props.newEvent).then((response) => {

        console.log('add training', response)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setShowModal(false)
        if (!props.isAll) {
            retrieveTeamDetail({
                id: props.team_id,
                setTeamDetail: props.setTeamDetail,
                setIsLoading: props.setIsLoading, setErrors: props.setErrors
            })
        } else {
            retrieveAllTrainings({
                setErrors: props.setErrors,
                setMessage: props.setMessage,
                setTrainings: props.setTrainings
            })
        }

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const editTraining = (props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/' + props.editedEvent.id

    axiosInstance.put(url, props.editedEvent).then((response) => {

        console.log('edit training', response)
        props.setIsLoading(false)
        props.setEditedEvent()
        props.setEventDetail()
        props.setEditMode(false)
        props.setShowModal(false)

        if (!props.isAll) {
            retrieveTeamDetail({
                id: props.team_id,
                setTeamDetail: props.setTeamDetail,
                setIsLoading: props.setIsLoading,
                setErrors: props.setErrors
            })
        } else {
            retrieveAllTrainings({
                setErrors: props.setErrors,
                setMessage: props.setMessage,
                setTrainings: props.setTrainings
            })
        }


    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log(error.response.data.message, error.response.data.errors)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const deleteTraining = (props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/' + props.id

    axiosInstance.delete(url).then((response) => {

        console.log('delete training', response)

        props.setIsLoading(false)
        props.setShowModal(false)
        if (!props.isAll) {
            retrieveTeamDetail({
                id: props.team_id,
                setTeamDetail: props.setTeamDetail,
                setIsLoading: props.setIsLoading,
                setErrors: props.setErrors
            })
        } else {
            retrieveAllTrainings({
                setErrors: props.setErrors,
                setMessage: props.setMessage,
                setTrainings: props.setTrainings
            })
        }


    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const copyTrainingFetch = (props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/duplicate/' + props.team_id

    axiosInstance.post(url, props.copyTraining).then((response) => {

            console.log('copy training', response)

            props.setIsLoading(false)
            props.setShowModalCopy(false)
            props.setCopyTraining()
            if (props.setTeamDetail) {
                retrieveTeamDetail({
                    id: props.team_id,
                    setTeamDetail: props.setTeamDetail,
                    setIsLoading: props.setIsLoading,
                    setErrors: props.setErrors
                })
            } else {
                retrieveAllTrainings({
                    setErrors: props.setErrors,
                    setMessage: props.setMessage,
                    setTrainings: props.setTrainings
                })
            }


        }
    ).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveTrainingDetail = (props) => {
    // props.setIsLoading(true)

    let url = '/team/training-schedule/' + props.idDetail

    axiosInstance.get(url).then((response) => {

        console.log('Training Detail', response.data.schedule)

        props.setTrainingDetail(response.data.schedule)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})

        // props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveWeekDays = (props) => {
    // props.setIsLoading(true)

    let url = '/weekdays'

    axiosInstance.get(url).then((response) => {

        console.log('WeekDays', response.data.data)

        props.setWeekDays(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})

        // props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveTrainingPlans = (props) => {
    // props.setIsLoading(true)

    let url = '/team/training-schedule/' + props.id + '/plans'

    axiosInstance.get(url).then((response) => {

        console.log('Training Plans', response.data.data)

        props.setTrainingPlans(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})

        // props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const addTrainingPlans = (props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/plan/' + props.id

    axiosInstance.post(url, props.newTrainingPlan).then((response) => {
        props.setIsLoading(false)
        console.log('New Training Plan', response)

        props.setMessage(response.data.message)

        setTimeout(function () {
            props.setMessage()
            props.navigate('/teams/dettaglio-team/'+props.id)
        }, 1000)



    }).catch((error) => {
        console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            console.log(error.response.data.errors)
            props.setErrors(error.response.data.errors)
            props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteTrainingPlan =(props) => {
    props.setIsLoading(true)

    let url = '/team/training-schedule/plan/' + props.trainingPlanId

    axiosInstance.delete(url).then((response) => {

        console.log('delete Training Plan', response)
        retrieveTrainingPlans({setTrainingPlans:props.setTrainingPlans,id: props.id})
        props.setMessage(response.data.message)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

