import axiosInstance from "../AxiosInstance"


export const retrieveMembersList = (props) => {
    console.log(props.filterType)
    let url = props.pageUrl
    console.log(url);
    const symbol = props.pageUrl.includes('?') ? '&' : '?';

    url = props.pageUrl + symbol + props.perPage + symbol + props.filterType

    if (props.search) {
        const query = props.search ? Object.keys(props.search)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
            .join('&') : null;

        if (query !== null) url += '&' + query;
    }
    if (props.filterSort) {
        const filter = props.filterSort ? Object.keys(props.filterSort)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.filterSort[key]))
            .join('&') : null;
        if (filter !== null) url += '&' + filter;
    }


    props.setIsLoading && props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setMembersList(response.data.data.data)
        props.setPagination && props.setPagination(response.data.data.links)

        console.log('RetrieveMembersList', response)

        props.setIsLoading && props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveMemberContacts = (props) => {
    // props.setIsLoading(true)
    axiosInstance.get(props.pageUrl).then((response) => {
        props.setMemberContact(response.data.data)
        console.log('retrieve Contacts', response)
        // props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveMemberDetail = (props) => {
    let url = '/profile/' + props.id
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log(response.data.data)
        props.setMemberDetail(response.data.data)
        props.setMemberTeamsDetail(response.data.data.teams)
        /* setMembersList(response.data.data.data)
        /!* setPagination(response.data.data.links)
         setIsLoading(false)*!/*/
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const associateMember = (props) => {
    let url = '/profile/associate/' + props.contactID
    axiosInstance.post(url,).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Associa socio', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setShowConfirm(false)
        props.setMessage(response.data.message)
        props.setUpdateRetrieve && props.setUpdateRetrieve(prevState => !prevState)

        setTimeout(function () {
            props.setMessage()
        }, 2000)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            //props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const dissociateMember = (props) => {
    let url = '/profile/dissociate/' + props.contactID
    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta dissocia socio', response.data)
        props.setShowConfirm(false)
        props.setMessage(response.data.message)
        props.setUpdateRetrieve && props.setUpdateRetrieve(prevState => !prevState)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)

            props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addOrDeleteMemberRole = (props) => {
    let url = '/profile/' + props.action + '/' + props.userId
    props.setIsLoading && props.setIsLoading(true)
    axiosInstance.post(url, props.roleIds).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta aggiungi ruolo socio', response.data)
        props.setShow(false)
        props.setUpdateMembersList(prevState => !prevState)
        // props.setMessage(response.data.message)

        props.setIsLoading && props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addNewMember = (props) => {
    let url = '/profile/associate/add'
    console.log('newMember', props.newMember)
    axiosInstance.post(url, props.newMember).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta aggiungi socio', response.data)
        // props.setShow(false)
        // props.setUpdateMembersList(prevState=> !prevState)
        props.setMessage(response.data.message)
        setTimeout(function () {
            props.navigate('/soci/lista-soci')
        }, 1000)
        props.setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
        } else {
            props.setErrors(error.response.data.message)
        }
        props.setIsLoading(false)
    })
}

/*DOCUMENTI*/
export const retrieveMemberDocuments = (props) => {
    let url = '/profile/' + props.id + '/documents'
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log(response.data.data)
        props.setMemberDocuments(response.data.data)


    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}