import { useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {Alert, Tab, Tabs} from "react-bootstrap";

import InfoTab from "../../Components/Members/MemberDetails/InfoTab";
import PaymentList from "../../Components/Members/MemberDetails/PaymentList";
import Documents from "../../Components/Members/MemberDetails/Documents/Documents";
import Loading from "../../Components/Layout/Elements/Loading";

const MemberDetail = () => {
    const {id} = useParams()
    const [message, setMessage] = useState(false)
    const [errors, setErrors] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('')
    useEffect(() => {
        console.log(activeTab)
    }, [activeTab])

    return (

            <>
                {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

                <Tabs
                    defaultActiveKey="info"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="info" title="Informazioni Socio" onSelect={()=> {setActiveTab('info')}}>

                        <InfoTab id={id} setMessage={setMessage} setIsLoading={setIsLoading} setErros={setErrors} isLoading={isLoading}/>
                    </Tab>
                    <Tab eventKey="pagamenti" title="Pagamenti" onEnter={()=> {setActiveTab('pagamenti')}}>

                        {activeTab === 'pagamenti' &&   <PaymentList id={id}  setMessage={setMessage} setIsLoading={setIsLoading} setErros={setErrors} isLoading={isLoading}/>}

                    </Tab>
                    <Tab eventKey="documenti" title="Documenti" onEnter={()=> {setActiveTab('documenti')}}>
                        {activeTab === 'documenti' &&   <Documents id={id} setMessage={setMessage} setIsLoading={setIsLoading} setErros={setErrors} isLoading={isLoading}/>}


                    </Tab>

                </Tabs>
                {isLoading && <Loading/>}
            </>

    )
}

export default MemberDetail