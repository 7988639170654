import {Outlet} from "react-router-dom";
import AssociationSettingsForm from "../../Components/Settings/AssociationSettingsForm";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../Contexts/authContext";
import {retrieveAssociationInfos} from "../../Api/Settings/SettingsFetch";
import {Alert, Col, Row} from "react-bootstrap";
import AssociationQrCode from "../../Components/Settings/AssociationQrCode";
import AssociationsCode from "../../Components/Settings/AssociationQrCode";

const AssociationSettings = () => {
    const [associationInfo, setAssociationInfo] = useState()
    const [updatedAssociationInfo, setUpdatedAssociationInfo] = useState()
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const {profile, setUserProfile} = useContext(AuthContext)

    useEffect(() => {
        retrieveAssociationInfos({setAssociationInfo})
    }, [profile])

    useEffect(() => {
        console.log('info', associationInfo)
        setAssociationInfo(associationInfo)
    }, [associationInfo, profile])

    return (
        <>
           <Row>
               {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                   setMessage(null)
               }} dismissible> {message} </Alert>}
               {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                   setErrors(null)
               }} dismissible> {errors} </Alert>}
               <Col md={12}>
                   <AssociationSettingsForm setUpdatedAssociationInfo={setUpdatedAssociationInfo}
                                            updatedAssociationInfo={updatedAssociationInfo} setMessage={setMessage}
                                            setErrors={setErrors} setUserProfile={setUserProfile} profile={profile}
                                            associationInfo={associationInfo} setAssociationInfo={setAssociationInfo}/>
               </Col>
               <Col md={4}>
                   <AssociationsCode associationInfo={associationInfo}/>
               </Col>
           </Row>
        </>
    )
}

export default AssociationSettings