import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {MdEmail} from "react-icons/md";
import {FaAddressCard} from "react-icons/fa";
import {BsFillTelephoneFill} from "react-icons/bs";
import {Link} from "react-router-dom";
import React from "react";

const ImportPlayersModal = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }
    const handleChoose = (e) => {
        let team_id = e.target.value
        props.setTeamToImport(team_id)
    }

    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton><h5>Importa atleti</h5></Modal.Header>
            <Modal.Body>


                <Col md={12} key={''}>
                    <Row>
                        <p>Scegli il team dal quale importare gli atleti</p>
                        <Form.Select onChange={handleChoose}>
                            <option>
                                Seleziona team
                            </option>
                            {props.teamList && props.teamList.map(team => {
                                return (
                                    <option key={team.id} value={team.id}>{team.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Row>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button className={'bg-danger border-0'} onClick={handleClose}>
                    Annulla
                </Button>
                <Button className={'bkg-second border-0'} onClick={props.handleConfirm}>
                    Importa
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImportPlayersModal