import {Alert, Button, Form, Modal} from "react-bootstrap";
import {formatDataIt, formatDataItWithTime, formatDataTime, formatDataWithTime} from "../../../Utils/DateFormat";
import React, {forwardRef, useEffect, useState} from "react";
import {emptyError, handleErrors} from "../../Errors";
import DatePicker from "react-datepicker";
import {it} from "date-fns/locale/it";
import {setHours, setMinutes} from "date-fns";


const ModalCalendarTraining = (props) => {
    const [teamId, setTeamId] = useState();
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    useEffect(() => {
        console.log(props.errors)
        if (props.errors) {
            handleErrors(props.errors)
        }
        console.log('errors', props.errors, 'general', props.errors)
    }, [props.errors])


    useEffect(() => {
        if (props.eventDetail) {
            const utcDateStart = new Date(props.eventDetail.start); // Crea una nuova data utilizzando la data UTC fornita
            const utcDateEnd = new Date(props.eventDetail.end); // Crea una nuova data utilizzando la data UTC fornita
            console.log('date:',utcDateStart)
            setStartDate(utcDateStart);
            setEndDate(utcDateEnd);

        } else {
            if (props.selectedInfoDay && props.selectedInfoDay) {
                console.log(props.selectedInfoDay.start)
                const utcDate = new Date(props.selectedInfoDay.start); // Crea una nuova data utilizzando la data UTC fornita
                const localDate = new Date(utcDate.getTime()); // Aggiungi l'offset del fuso orario UTC
                setStartDate(setHours(setMinutes(new Date(localDate), 0), 9),);
                setEndDate(setHours(setMinutes(new Date(utcDate), 0), 10));
            }
        }

    }, [props.selectedInfoDay]);


    useEffect(() => {
        console.log('startDate', startDate)
    }, [startDate, props.selectedInfoDay])
    const handleAddTraining = (e) => {
        e.preventDefault();
        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')

        const obj = {
            team_id: props.id ? props.id : teamId,
            location: document.querySelector("input[name=title]").value

        }
        let start = formatDataTime(new Date(startDate))
        let end = formatDataTime(new Date(endDate))
        obj.start_time = start.slice(0, 19).replace('T', ' ') + ':00'
        obj.end_time = end.slice(0, 19).replace('T', ' ') + ':00'
        console.log(obj)
        if (!emptyfields) {
            props.setNewEvent(obj)
        }
    }

    const handleEditTraining = (e) => {
        e.preventDefault();
        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')

        const obj = {
            team_id: props.eventDetail.team_id && teamId,
            location: document.querySelector("input[name=title]").value,
            id: document.querySelector("input[name=id]").value
        }
        let start = formatDataTime(new Date(startDate))
        let end = formatDataTime(new Date(endDate))
        obj.start_time = start.slice(0, 19).replace('T', ' ') + ':00'
        obj.end_time = end.slice(0, 19).replace('T', ' ') + ':00'
        console.log(obj)
        if (!emptyfields) {
            props.setEditedEvent(obj)
        }

    }


    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} aria-labelledby="modal-title">
            {props.errors && typeof props.errors === 'string' && <Alert variant={'danger'} onClick={() => {
                props.setErrors(null)
            }} dismissible> {props.errors} </Alert>}
            {!props.errors && props.generalError && typeof props.generalError === 'string' &&
                <Alert variant={'danger'} onClick={() => {
                    props.setGeneralError(null)
                }} dismissible> {props.generalError} </Alert>}

            <Modal.Header closeButton>
                <Modal.Title id="modal-title">Aggiungi allenamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className={'position-relative'}>
                    {!props.id && !props.editMode && (
                        <Form.Select required={true} name="team_id" onChange={(e) => {
                            setTeamId(e.currentTarget.value)
                            console.log(e.target.value)
                        }}>
                            <option value={''}>Seleziona Team</option>
                            {props.team && props.team.map(i => {
                                return (<option key={i.id} value={i.id}>{i.name}</option>)
                            })
                            }
                        </Form.Select>
                    )}
                </Form.Group>
                <Form.Control type={'hidden'} name="team_id"
                              defaultValue={props.eventDetail && props.eventDetail.team_id ? props.eventDetail.team_id : ''}></Form.Control>
                <Form.Control type={'hidden'} name="id"
                              defaultValue={props.eventDetail ? props.eventDetail.id : ''}></Form.Control>
                <Form.Group className={'position-relative'}>
                    <Form.Label className={'mt-2 small'}>Location</Form.Label>
                    <Form.Control name={'title'} type={'text'} required={true}
                                  defaultValue={props.eventDetail && props.eventDetail.title ? props.eventDetail.title : ''}
                                  className={'form-control-sm'} placeholder={'Location'}/>
                </Form.Group>
                <Form.Group className={'position-relative w-100'}>
                    <Form.Label className={'my-2 small d-block'}>Data inizio</Form.Label>
                    <DatePicker

                        wrapperClassName={'w-100'}
                        className={'form-control-sm form-control mb-2 d-block datePicker'}
                        showTimeSelect
                        dateFormat="d/MM/yyyy HH:mm"
                        name={'start_time'}
                        timeCaption="Orario"
                        locale={it}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        excludeTimes={[
                            setHours(setMinutes(new Date(), 0), 0),
                            setHours(setMinutes(new Date(), 0), 1),
                            setHours(setMinutes(new Date(), 30), 19),
                            setHours(setMinutes(new Date(), 30), 17),
                        ]}
                    />
                    {/*  <Form.Control className={'mt-2'} type={'datetime-local'} name="start_time" required={true}
                                  defaultValue={dateString ? dateString : props.eventDetail && props.eventDetail.start}></Form.Control>
                */}
                </Form.Group>
                <Form.Group className={'position-relative'}>
                    <Form.Label className={'mb-2 small d-block'}>Data fine</Form.Label>
                    <DatePicker
                        wrapperClassName={'w-100'}
                        className={'form-control-sm form-control d-block datePicker'}
                        showTimeSelect
                        dateFormat="d/MM/yyyy HH:mm"
                        timeCaption="Orario"
                        name={'end_time'}
                        locale={it}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                    {/* <Form.Control className={'mt-2'} type={'datetime-local'} name={"end_time"} required={true}
                              defaultValue={dateString2 ? dateString2 : props.eventDetail && props.eventDetail.end}></Form.Control>*/}
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'mb-2 small d-block'}>Descrizione</Form.Label>
                </Form.Group>
                {/*<Form.Control className={'mb-2'} name={'description'} as="textarea"
                              placeholder={'Descrizione'}
                              defaultValue={props.eventDetail && props.eventDetail.plan ? props.eventDetail.plan : ''}></Form.Control>*/}
            </Modal.Body>

            <Modal.Footer>
                {props.editMode &&
                    <Button variant="danger" onClick={props.handleEditEvent}
                            data-id={props.eventDetail && props.eventDetail.id}>
                        Elimina Evento
                    </Button>}
                <Button variant="secondary" onClick={props.handleCloseModal}>
                    Chiudi
                </Button>
                <Button variant="primary"
                        onClick={props.editMode ? handleEditTraining : handleAddTraining}>{props.editMode ? 'Modifica' : 'Aggiungi'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCalendarTraining