import {Badge, Form, Row, Table} from "react-bootstrap";
import {AiOutlineSortAscending, AiOutlineSortDescending, AiOutlineUser} from 'react-icons/ai'
import React, {useEffect, useState} from "react";
import chroma from 'chroma-js';
import {Link, useNavigate} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {BiShow} from "react-icons/bi";
import {CiEdit} from "react-icons/ci";
import TablePagination from "../Layout/Elements/TablePagination";

import {generateRandomColors} from "../../Utils/GenerateRandomColors";
import {deleteGame} from "../../Api/Teams/Games/TeamGamesFetch";
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import {cloneTeam, deleteTeam} from "../../Api/Teams/TeamsFetch";
import {HiOutlineDuplicate} from "react-icons/hi";
import CloneTeamModal from "../Modals/Team/CloneTeamModal";

const TeamsTable = (props) => {
    const [id, setId] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const [actionMessage, setActionMessage] = useState()
    const [show, setShow] = useState(false)
    const [withPlayers, setWithPlayers] = useState(0)
    const navigate = useNavigate()
    const [teamName, setTeamName] = useState()
    const [teamId, setTeamId] = useState()

    const [sort, setSort] = useState('desc');

    const handleSort = (e) => {


        /*creo una variabile per evitare problemi nell'aggiornamento dello stato */
        const singleSort = sort
        const key = e.currentTarget.getAttribute('data-name');

        if (sort === 'asc') {
            setSort('desc')
        } else {
            setSort('asc')
        }
        console.log(sort)
        props.setFilterSort({[key]: singleSort})

    }
    useEffect(() => {
        console.log(withPlayers)
    }, [withPlayers])
    const handleConfirmClone = () => {
        console.log(withPlayers)
        cloneTeam({
            id: teamId,
            withPlayers,
            setMessage: props.setMessage,
            setErrors: props.setErrors,
            setIsLoading: props.setIsLoading,
            navigate
        })
        setShow(false)
    }

    const handleCloneModal = (e) => {
        let name = e.currentTarget.getAttribute('data-name')
        let id = e.currentTarget.getAttribute('data-id')
        setShow(true)
        setTeamName(name)
        setTeamId(id)
    }
    const handleConfirm = (e) => {
        deleteTeam({

            setIsLoading: props.setIsLoading,
            setErrors: props.setErrors,
            setMessage: props.setMessage,
            id
        })
        setShowConfirm(false)
    }
    const handleClose = () => {
        setShowConfirm(false)
    }

    return (
        <>
            <Table className={'table-responsive teams'}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[name]'}>
                        Nome del team {
                        props.filterSort && props.filterSort['sorts[name]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20} className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' >
                      Coach
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Stagione
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Sport praticato
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Azioni
                    </th>
                </tr>
                </thead>
                <tbody className={'text-start'}>

                {props.teamsList && props.teamsList.length > 0 ? props.teamsList.map((i) => {
                    /*   let scale = chroma.scale(['red','blue','green'])
                           .domain([0,100])
                           .classes([0,33,65,100])
                       scale(i.color).hue()
                       console.log(scale)*/
                    // let date = new Date(i.birthday).toISOString()
                    return (
                        <tr className={'border-bottom-1'} key={i.id}>

                            <td>
                                <h2 style={{
                                    background: i.color ? `linear-gradient(35deg, ${i.color} 0% ,${chroma(i.color).alpha(0.8).hex()} 30%,${chroma(i.color).alpha(0.7).hex()} 50%, ${chroma(i.color).luminance(0.4).alpha(0.7).hex()} 70%)` : 'var(--gradient)',
                                    width: '40px',
                                    height: '40px'
                                }}
                                    className={'rounded-5 fs-4  align-items-center d-inline-flex text-white justify-content-center mb-0 text-uppercase'}>{i.name.charAt(0)}</h2>
                                &nbsp;&nbsp; <Link to={`/teams/dettaglio-team/${i.id}`}
                                                   className={'text-decoration-none fw-bold me-2'}>{i.name}</Link>
                                <span className={'bkg-second d-inline-block text-white px-1 rounded-5 text-smaller'}>{i.player_count}</span>

                            </td>

                            <td>{i.coach_full_name}</td>
                            <td>{i.season_name}</td>
                            <td>{i.sport_name}
                            < /td>

                            <td>


                                <Link
                                    to={`/teams/dettaglio-team/${i.id}`}><BiShow className={'fs-5 mx-2'}
                                                                                 title={'Mostra'}/>
                                </Link>
                                <Link
                                    to={`/teams/modifica-team/${i.id}`}><CiEdit className={'fs-5 mx-2'} id={i.id}
                                                                                title={'Modifica'}/>
                                </Link>

                                <Link
                                    onClick={handleCloneModal} data-name={i.name} data-id={i.id}><HiOutlineDuplicate
                                    className={'fs-5 mx-2'} id={i.id}
                                    title={'Clona'}/>
                                </Link>
                                <Link to={''} data-id={i.id} onClick={(e) => {
                                    setShowConfirm(true)
                                    setId(e.currentTarget.getAttribute('data-id'))
                                    setActionMessage('Sei sicuro di voler eliminare il Team?')
                                }}><RiDeleteBinLine className={'fs-4 mx-2 text-danger'}/></Link>


                            </td>
                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun team trovato</h5>

                        </td>
                    </tr>
                )
                }

                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl}
                             perPage={props.perPage}></TablePagination>

            <ConfirmationModal show={showConfirm} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>
            <CloneTeamModal team_name={teamName} setShow={setShow} show={show} handleConfirm={handleConfirmClone}
                            setWithPlayers={setWithPlayers}/>
        </>
    )

}

export default TeamsTable