import {Link} from "react-router-dom";
import {FaEye} from "react-icons/fa";
import {retrieveTrainingDetail} from "../../Api/Teams/Trainings/TrainingsFetch";
import React from "react";
import { GrSchedules } from "react-icons/gr";
const CustomEvent = (props) => {

    return (

        <>

            <p className={`mb-0  ${props.event.extendedProps.type == 1 ? 'justify-content-between d-flex' : 'text-end'}` }>
                {props.event.extendedProps.type == 1 &&
                    <span className={'text-white fs-6'}> <GrSchedules title={'Piano allenamento'}/></span>}
                <Link to={''} className={''} title={'Visualizza Dettaglio'}>

                    <FaEye className={'fs-6 text-white'}
                           onClick={(e) => {
                               e.preventDefault();
                               props.setIsDetail(true)
                               props.setShowModalDetail(true)
                               const idDetail = props.event.id
                               console.log(idDetail)
                               props.setTrainingDetail({
                                   title: props.event.title,
                                   team_name:props.event.extendedProps.team_name,
                                   start_time: props.event.start,
                                   end_time: props.event.end,
                                   description: props.event.extendedProps.plan,
                                   id:props.event.id
                               })
                               // retrieveTrainingDetail({idDetail, setTrainingDetail: props.setTrainingDetail})
                           }

                           } onTouchStart={(e) => {
                        e.preventDefault();
                        props.setIsDetail(true)
                        props.setShowModalDetail(true)
                        const idDetail = props.event.id
                        console.log(idDetail)
                        props.setTrainingDetail({
                            title: props.event.title,
                            team_name:props.event.extendedProps.team_name,
                            start_time: props.event.start,
                            end_time: props.event.end,
                            description: props.event.extendedProps.plan,
                            id:props.event.id
                        })
                        // retrieveTrainingDetail({idDetail, setTrainingDetail: props.setTrainingDetail})
                    }}/>
                </Link>
            </p>
            {props.event.extendedProps.team_name && <p className={'small'}>Team: {props.event.extendedProps.team_name}</p>}
            <p  className={'smaller mt-1 pb-0 mb-0'} style={{whiteSpace: 'break-spaces'}}>{props.event.title}</p>


        </>
    );
}

export default CustomEvent