import {Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {MultiSelect} from "react-multi-select-component";
import {translations} from "../../Lang/MultiselectTranslations";


const CommunicationSendOptions = (props) => {
    const [disabled, setDisabled] = useState(true);


    const handleChooseSend = (e) => {

        if (e.currentTarget.value === 'Team') {
            document.querySelector('select[name="team_id"]').removeAttribute('disabled')
            setDisabled(true)
            props.setSelectedMemberOption([])
        } else if (e.currentTarget.value === 'Profile') {
            document.querySelector('select[name="team_id"]').setAttribute('disabled', 'disabled')
            setDisabled(false)
            document.querySelector('select[name="team_id"]').value = ''
        } else if (e.currentTarget.value === 'All') {
            document.querySelector('select[name="team_id"]').setAttribute('disabled', 'disabled');
            document.querySelector('select[name="team_id"]').value = ''
            setDisabled(true)
            props.setSelectedTeamOption()
            props.setSelectedMemberOption([])
        }
        props.setSelectedSender({[e.currentTarget.getAttribute('name')]: e.currentTarget.value})

    }

    return (
        <>
            <Card className={'col-md-12 p-3 border-0 shadow h-100 justify-content-start'}>
                <Row>
                    <h3 className={'py-3 fw-bold'}>Opzioni invio</h3>
                    <Col md={12} className={'pt-2 position-relative'}>
                        <Form.Check onClick={handleChooseSend} name={'send_option'} inline label={'Tutti'} value={'All'}
                                    type={'radio'} required={'required'}></Form.Check>
                        <Form.Check onClick={handleChooseSend} name={'send_option'} inline label="Profilo"
                                    value={'Profile'}
                                    type={'radio'} defaultChecked={props.id && true} required={'required'}></Form.Check>
                        <Form.Check onClick={handleChooseSend} name={'send_option'} inline label="Team" value={'Team'}
                                    type={'radio'} required={'required'}></Form.Check></Col>
                    <Col md={12} className={'mt-4'}>

                        <Form.Group className={'position-relative errorField_up'}>

                            <Form.Label>Seleziona Soci</Form.Label>
                            <Form.Control type={'hidden'} name={'profile_ids'}></Form.Control>
                            <MultiSelect className={`${disabled && !props.id &&  'disabled'} `} disabled={!props.id && disabled}
                                         options={props.membersOptions}
                                         value={props.selectedMembersOption}
                                         name={'profile_ids'}
                                         overrideStrings={translations} onChange={props.setSelectedMemberOption}
                                         labelledBy="Select"/>

                        </Form.Group>

                    </Col>
                    <Col md={12} className={'mt-4 '}>
                       <Form.Group className={'position-relative errorField_up'}>
                           <Form.Label>Seleziona Team</Form.Label>
                           <Form.Select name="team_id" onChange={(e) => {
                               props.setSelectedTeamOption({[e.target.name]: e.target.value})
                           }} disabled={'disabled'} className={'my-2 '}>
                               <option value={''}>
                                   Seleziona
                               </option>
                               {props.teamsList && props.teamsList.length > 0 && props.teamsList.map(team => {
                                   return (<option key={team.id} value={team.id}>{team.name}</option>)
                               })}
                           </Form.Select>
                       </Form.Group>


                    </Col>
                </Row>
            </Card>

        </>
    )
}

export default CommunicationSendOptions