//format data for send to api
export const formatData = (date) => {
    if(date instanceof Date){
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
}

//format data on it language
export const formatDataIt = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month

        return day + '/' + month + '/' + date.getFullYear();
    }
}

export const formatDataItWithTime = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
        const hours = ('0' + (date.getHours())).slice(-2); // Ensure two digits for hours
        const minutes = ('0' + (date.getMinutes())).slice(-2); // Ensure two digits for minutes
        const seconds = ('0' + (date.getSeconds())).slice(-2); // Ensure two digits for seconds

        return day + '/' + month + '/' + date.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds;
    }
}

export const formatDataWithTime = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
        const hours = ('0' + (date.getHours())).slice(-2); // Ensure two digits for hours
        const minutes = ('0' + (date.getMinutes())).slice(-2); // Ensure two digits for minutes
        const seconds = ('0' + (date.getSeconds())).slice(-2); // Ensure two digits for seconds

        return date.getFullYear() + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
}
export function formatDataTime(date) {
    const formattedDate = new Date(date);

    // Extract the components of the date
    const year = formattedDate.getFullYear().toString();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const hour = formattedDate.getHours().toString().padStart(2, '0');
    const minute = formattedDate.getMinutes().toString().padStart(2, '0');

    // Format the date as "YYYY-MM-DDTHH:mm" (ISO 8601 format)
    const formattedDateString = `${year}-${month}-${day}T${hour}:${minute}`;

    return formattedDateString;
}
