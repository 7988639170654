import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {MdEmail} from "react-icons/md";
import {Link} from "react-router-dom";
import {BsFillTelephoneFill} from "react-icons/bs";
import {FaAddressCard} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import {MultiSelect} from "react-multi-select-component";
import {translations} from "../../../Lang/MultiselectTranslations";
import {retrieveRoles} from "../../../Api/Roles/RolesFetch";
import SelectedRoles from "../../Members/SelectedRoles";
import {addOrDeleteMemberRole} from "../../../Api/Members/MembersFetch";
import {emptyError, handleErrors} from "../../Errors";

const MembersRolesModal = (props) => {
    const [roles, setRoles] = useState([]);
    const [roleIds, setRolesIds] = useState([])
    const [rolesToRemove, setRolesToRemove] = useState([])
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const handleClose = () => {
        props.setShow(false)
        props.setSelectedRoles([])
        setRolesIds([])
        props.setAction()
        props.setUserId()
    }
    useEffect(() => {
        if (props.show) {
            retrieveRoles({setRoles})
        }

        if (props.show === false) {
            props.setSelectedRoles([])
            setRolesToRemove([])
            setRolesIds([])
            props.setAction()
            setErrors()
            setGeneralError()
            props.setUserId()
        }

        // addMemberRoles({})
    }, [props.show])

    useEffect(() => {

    }, [ roleIds])
    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])
    useEffect(() => {

        if (roleIds && roleIds.role_ids && roleIds.role_ids.length > 0) {
            emptyError()
            console.log('1')
            const emptyfields = document.querySelector('span.error_empty_field')
            if (!emptyfields) {
                console.log('test')

                addOrDeleteMemberRole({
                    userId: props.userId,
                    action: props.action,
                    roleIds,
                    setShow: props.setShow,
                    setUpdateMembersList: props.setUpdateMembersList,
                    setErrors,
                    setGeneralError,
                    setIsLoading:props.setIsLoading
                })
            }

        }


    }, [roleIds])
    //  Ciclo la lista dei ruoli, per strutturare la multiselect
    const options = roles && roles.length > 0
        ? roles.map((role, index) => {
            return {
                label: role.name,
                value: role.id,
                index: index
            }
        }) : []

    useEffect(() => {

    }, [props.selectedRoles, rolesToRemove])
    const handleClick = () => {
        setRolesIds((prevValue) => {
            /*Se è aggiungi ruolo, filtro gli id selezionati e verifico se c'è già nell'array l'id, altrimenti l'aggiungo*/
           if (props.action === 'add-roles') {
               const newValues = props.selectedRoles.map(i => i.value);
               let updatedValues = [];
               if (prevValue && prevValue.role_ids) {
                   updatedValues = prevValue.role_ids.filter(value => newValues.includes(value));
                   newValues.forEach(value => {
                       if (!updatedValues.includes(value)) {
                           updatedValues.push(value);
                       }
                   });
               } else {
                   updatedValues = newValues;
               }

               return {role_ids: updatedValues};
           }
           /*Altrimenti aggiungo gli id da rimuovere*/
           else  {
               const newValues = rolesToRemove

               return {role_ids: newValues};

           }
        });

    }

    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true} backdrop="static">
            <Modal.Header
                closeButton> {props.action === 'add-roles' ? 'Aggiungi ruoli' : 'Rimuovi ruoli'}</Modal.Header>
            <Modal.Body>
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
                {generalError && typeof generalError === 'string' && <Alert variant={'danger'} onClick={() => {setGeneralError(null) }} dismissible> {generalError} </Alert>}

                <SelectedRoles selectedRoles={props.selectedRoles} setSelectedRoles={props.setSelectedRoles} setRolesToRemove={setRolesToRemove}/>
                {props.action === 'add-roles' ?
                    <MultiSelect options={options} value={props.selectedRoles}
                                 overrideStrings={translations}
                                 onChange={props.setSelectedRoles}
                                 labelledBy="Select"/> : ''
                }


                <Row>
                    <Col className={'text-end'}>
                        <Button className={'btn btn-sm bkg-second text-center border-0 mt-2'}
                                onClick={handleClick}>{props.action === 'add-roles' ? 'Aggiungi Ruoli' : 'Rimuovi Ruoli'}</Button>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )
}

export default MembersRolesModal