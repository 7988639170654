import axiosInstance from "../AxiosInstance";

export const retrieveDashboard = (props) => {


    let url = '/dashboard'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)

        console.log('Sports', response.data.data)
        props.setDashboardDatas(response.data.data)
        // props.setIsLoading(false)
    }).catch((error) => {
        // console.log(error)
        // props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            // props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            // props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}