import {Outlet} from "react-router-dom";
import {Row} from "react-bootstrap";

const Payments =() => {
    return (
        <>
            <Row>
                <Outlet />
            </Row>

        </>
    )
}
export default Payments