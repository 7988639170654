import { Modal, Table, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ModalShowProfiles = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calcola i membri attuali da visualizzare
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMembers = props.planMembers ? props.planMembers.slice(indexOfFirstItem, indexOfLastItem) : [];

    // Funzioni di paginazione
    const totalPages = Math.ceil((props.planMembers?.length || 0) / itemsPerPage);
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

    const handleClose = () => {
        props.setShow(false);
        props.setSelectedPlanProfiles();
        props.setPlanMembers();
        props.setSelectedPlan();
    };

    useEffect(() => {
        console.log(props.planMembers);
        setCurrentPage(1); // Reset della pagina corrente quando si aprono nuovi dati
    }, [props.planMembers]);

    return (
        <Modal show={props.show} onHide={handleClose}
               className={`modal-lg`}
               centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className={'d-flex flex-column'}>
                <Table className={'table-responsive'}>
                    <thead>
                    <tr className={'shadow-none'}>
                        <th className={'bkg-primary text-white text-start'}>Nome socio</th>
                        <th className={'bkg-primary text-white text-start'}>Telefono</th>
                        <th className={'bkg-primary text-white text-start'}>Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentMembers && currentMembers.length > 0 ? currentMembers.map((member, index) => {
                        return (
                            <tr key={index} className={'me-2'}>
                                <td>
                                    <img className={'img-fluid rounded-5 me-3'} style={{width: '30px'}}
                                         src={`${member?.profile_picture}`} alt="profile"/>
                                    <Link className={'text-decoration-none'} to={'/soci/dettaglio-socio/' + member.id}>
                                        <span>{member.first_name} {member.last_name}</span>
                                    </Link>
                                </td>
                                <td>{member.telephone_number}</td>
                                <td>
                                    <Link to={`mailto:${member.email}`}>{member.email}</Link>
                                </td>
                            </tr>
                        );
                    }) : (
                        <tr>
                            <td colSpan="3">Nessun associato</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {/* Controlli di paginazione */}
                {props.planMembers && props.planMembers.length > itemsPerPage && (
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <Button className={'bkg-second border-0'} onClick={handlePrevPage} disabled={currentPage === 1}>
                            Pagina precedente
                        </Button>
                        <span>Pagina {currentPage} di {totalPages}</span>
                        <Button className={'bkg-second border-0'} onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Pagina successiva
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ModalShowProfiles;
