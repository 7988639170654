import {Card, Col, Nav, Row} from "react-bootstrap";
import {AiOutlineUser} from "react-icons/ai";
import React from "react";

const TeamsInfos = (props) => {
    return (

        <>
            <Col md={12} className={'teamCard'}>
                <Card className={'d-flex px-4 py-3 shadow border-0'}>
                    <Col md={12}>
                        <h2 className={''}>Team e convocazioni</h2>
                    </Col>
                    <Row className={'justify-content-center align-items-end'}>
                        <Col md={4} className={'py-2'}>
                            <span className={'small'}>Convocazioni</span>
                            <h2 className={'fw-800'}>
                                {props.memberDetail && props.memberDetail.global_stats && props.memberDetail.global_stats.total_games}
                            </h2>
                        </Col>
                        <Col md={4} className={'py-2'}>

                            <span className={'small'}>Gare giocate</span>
                            <h2 className={'fw-800'}>
                                {props.memberDetail && props.memberDetail.global_stats && props.memberDetail.global_stats.total_games_played}
                            </h2>

                        </Col>
                        <Col md={4} className={'py-2'}>
                            <span className={'small'}>  Allenamenti</span>
                            <h2 className={'fw-800'}>
                                {props.memberDetail && props.memberDetail.global_stats && props.memberDetail.global_stats.total_trainings}
                            </h2>

                        </Col>

                        <Col md={12}>
                            <h2>Teams</h2>
                            <Nav onSelect={(selectedKey) => {
                                props.setSelectedTeam(selectedKey)
                            }} className="flex-column text-start" variant={"pills"}>
                                {props.memberDetail && props.memberDetail.teams && props.memberDetail.teams.length > 0 && props.memberDetail.teams.map((i, index) => {
                                    return (
                                        <Nav.Item key={i.id}>
                                            <Nav.Link key={i.name} active={props.selectedTeam === i.name + index}
                                                      eventKey={i.name + index}>{i.name}</Nav.Link>
                                        </Nav.Item>


                                    )
                                })}
                            </Nav>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}

export default TeamsInfos