import { Button, Col, Form, Modal } from "react-bootstrap";
import React from "react";

const AddStaffModal = ({ show, setShow, handleConfirm }) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <Modal show={show} onHide={handleClose} className={'modal-md staffModal'} centered={true}>
            <Modal.Header closeButton><h5>Aggiungi membro dello staff</h5></Modal.Header>
            <Modal.Body>
                <Col md={12} key={''}>
                    <Form.Group>
                        <Form.Label>
                            Nome
                        </Form.Label>
                        <Form.Control type="text" name="first_name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Cognome
                        </Form.Label>
                        <Form.Control type="text" name="last_name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Ruolo
                        </Form.Label>
                        <Form.Control type="text" name="description" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control type="email" name="email" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Numero di telefono
                        </Form.Label>
                        <Form.Control type="tel" name="phone_number" />
                    </Form.Group>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button className={'bg-danger border-0'} onClick={handleClose}>
                    Annulla
                </Button>
                <Button className={'bkg-second border-0'} onClick={handleConfirm}>
                    Aggiungi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddStaffModal;
