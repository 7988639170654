import axios from 'axios';

import {router} from "../Routes/router"

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;

// Create an instance of Axios with default headers
const axiosInstance = axios.create({
    baseURL: API_ENDPOINT,
});

// Add default headers, including the JWT token
axiosInstance.interceptors.request.use(
    async (config) => {
        const jwtToken = localStorage.getItem('jwt'); // Implement the getJwtToken function
        const bearerToken  = localStorage.getItem('sanctum')
        const userDatas  = JSON.parse(localStorage.getItem('selectedProfile'))


        if (bearerToken) {
            config.headers['Authorization'] = `Bearer ${bearerToken}`;
        }

        if(jwtToken) {
            config.headers['X-JWT-Token'] = `${jwtToken}`;
        }
        if(userDatas) {
            config.headers['X-Profile-Id'] = `${userDatas.profile_id}`;
            config.headers['X-Association-Id'] = `${userDatas.association}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//check default errors
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Handle 401 errors here, e.g., logout or redirect
            localStorage.clear();
            router.navigate('/').then()
            window.location.reload()
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;