import React, {useEffect, useState} from "react";
import {retrieveMemberDocuments} from "../../../../Api/Members/MembersFetch";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {emptyError} from "../../../Errors";
import useDoubleClickPrevention from "../../../../Hooks/useDoubleClickPrevention";
import {addCommunicationFetch, downloadAttachment} from "../../../../Api/Communications/CommunicationsFetch";
import DocumentTypeSelect from "../../../Documents/DocumentsSelect";
import {useParams} from "react-router-dom";
import {addDocumentFetch, deleteDocument, downloadDocument} from "../../../../Api/DocumentsFetch";
import {formatDataIt} from "../../../../Utils/DateFormat";
import {RiDeleteBinLine} from "react-icons/ri";
import {GoDownload} from "react-icons/go";
import {ConfirmationModal} from "../../../Modals/ConfirmationModal";

const Documents = ({id, setErrors, setMessage, isLoading, setIsLoading}) => {

    const [memberDocuments, setMemberDocuments] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
    const [addDocument, setAddDocument] = useState()
    const [documentToDelete, setDocumentToDelete] = useState()
    const [update, setUpdate] = useState(false)
    const [showConfirm, setShowConfirm] = useState()
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    console.log(memberDocuments)
    useEffect(() => {
        if (id) {
            retrieveMemberDocuments({id, setMemberDocuments, setIsLoading, setErrors, setMessage})
        }
    }, [id, update])

    useEffect(() => {

    }, [documentToDelete])

    const handleSend = (e) => {
        e.preventDefault();
        emptyError();
        const emptyfields = document.querySelector('span.error_empty_field')
        let documentType = document.querySelector('select[name=document_type_id]').value

        if (!emptyfields) {
            const newDocument = {
                document_type_id: documentType,
            };

            // Aggiungi i campi da input di testo e data
            document.querySelectorAll('input[type=date],input[type=text], textarea').forEach((input) => {
                console.log(input)
                const name = input.getAttribute('name');
                const value = input.value;
                newDocument[name] = value;
            });

            // Aggiorna lo stato di addDocument
            setAddDocument((prevState) => ({
                ...prevState,
                ...newDocument,
            }));
            console.log(newDocument)
        }
    }

    const handleConfirm = () => {
        deleteDocument({
            id,
            documentToDelete,
            setIsLoading,
            setErrors,
            setMessage,
            setUpdate,
            update,
            setDocumentToDelete, setShowConfirm
        })
    }
    const handleDowloadFile = (e) => {
        console.log(e.currentTarget.getAttribute('data-id'))
        downloadDocument({
            id,
            documentToDownload: e.currentTarget.getAttribute('data-id')
        })
    }
    useEffect(() => {
        const formData = new FormData();
        if (selectedFile) {
            formData.append('file', selectedFile); // Aggiungi il singolo file

            console.log(selectedFile.name); // Stampa il nome del file
        }


        if (addDocument) {
            preventDoubleClick()
            addDocumentFetch({id, addDocument, formData, setMessage, setIsLoading, update, setUpdate, setErrors})
        }
    }, [addDocument])
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]); // Imposta il singolo file
        console.log(event.target.files[0]);
    };

    useEffect(() => {
        console.log(selectedAttribute)
    }, [selectedAttribute])
    return (
        <>

            <Row>
                <Col lg={5} className={'px-4 '}>
                    <Row className={'align-items-baseline text-start mx-0'}>
                        <Col className={'w-100'}>
                            <Form.Group controlId="formFile" className="mb-3 mt-3">
                                <Form.Label className={'text-start'}>Seleziona un file</Form.Label>
                                <Form.Control type="file" multiple={true} onChange={handleFileChange}/>
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mb-3 mt-3">
                                <Form.Label className={'text-start'}>Titolo documento</Form.Label>
                                <Form.Control type="text" name={'document_title'}/>
                            </Form.Group>
                            <DocumentTypeSelect setErrors={setErrors} setIsLoading={setIsLoading}
                                                setSelectedAttribute={setSelectedAttribute}
                                                selectedAttribute={selectedAttribute}/>
                            {selectedAttribute && selectedAttribute === '1' &&
                                <>
                                    <Form.Group className="mb-3 mt-3">
                                        <Form.Label className={'text-start'}>Valido dal</Form.Label>
                                        <Form.Control type="date" name={'valid_from'}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 mt-3">
                                        <Form.Label className={'text-start'}>Fino al </Form.Label>
                                        <Form.Control type="date" name={'valid_to'}
                                        />
                                    </Form.Group></>
                            }
                            <Row>
                                <Col className={'mt-2'}> <Form.Label className={'text-start '}>Note </Form.Label>
                                    <Form.Control type="text" as={'textarea'} className={'input'} name={'description'}
                                                  multiple={true}
                                    /></Col>
                            </Row>
                        </Col>

                        <Button onClick={handleSend} disabled={isButtonDisabled} className={'mt-3 mx-0'}>Carica
                            documento</Button>

                    </Row>

                </Col>
                <Col className={'card shadow border-0 p-3 mt-sm-5 mt-lg-0 '}>
                    {memberDocuments && memberDocuments.length > 0 ? memberDocuments.map(i => {
                        return (

                            <div key={i.id}
                                 className={'d-flex flex-row justify-content-between align-content-end gap-2 align-items-end border-bottom border-1 py-2'}>
                                <p className={'mb-0 small text-start'}>
                                    {i.document_type_id === '1' && <Badge bg={'secondary'}
                                                                          className={'bg-second text-white'}> Certificato </Badge>}
                                    <br/>{i?.document_title || i.original_filename}</p>

                                {i.document_type_id === 1 && <p className={'text-smaller text-start  mb-0 '}>
                                    Validità<br/> dal: {formatDataIt(new Date(i.valid_from))}
                                    <br/> al {formatDataIt(new Date(i.valid_to))} <br/>

                                </p>}
                                <p className={'mb-0 col-1'}>
                                    {i.document_type_id === 1 && (
                                        i.is_valid === true ?
                                            <Badge bg={'success'} className={'text-white'}> Valido </Badge> :
                                            <Badge bg={'danger'} className={'text-white'}>Scaduto</Badge>
                                    )}
                                </p>
                                <p className={'text-smaller col-2 text-start'}>{i.description}</p>
                                <p className={'align-self-end text-end flex-1'}>

                                    <GoDownload className={'fs-4'} data-id={i.id} onClick={(e) => {
                                        handleDowloadFile(e)
                                    }}/>

                                    <RiDeleteBinLine className={'fs-4 text-danger'} onClick={() => {
                                        setDocumentToDelete(i.id)
                                        setShowConfirm(true)
                                    }}/>
                                </p>

                            </div>

                        )
                    }) : 'Nessun documento presente'
                    }
                </Col>
            </Row>
            <ConfirmationModal show={showConfirm} handleClose={() => {
                setShowConfirm(false)
            }}
                               handleConfirm={handleConfirm}
                               actionMessage={'Sei sicuro di voler eliminare il documento?'}></ConfirmationModal>


        </>
    )
}
export default Documents