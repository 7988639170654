import {Alert, Badge, Button, Col, Form, Row, Table} from "react-bootstrap";

import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

import TablePagination from "../../Layout/Elements/TablePagination";
import ImgPlaceholder from "../../../assets/img/placeholder.png";
import AddStaffModal from "../../Modals/Team/AddStaffModal";
import {addMemberstoStaff} from "../../../Api/Teams/TeamsFetch";


const TeamStaff = (props) => {
    const [teamStaff, setTeamStaff] = useState();
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState(null);
    const [message, setMessage] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);

    useEffect(() => {
        console.log(typeof perPage === 'number')
        setPerPage(typeof perPage === 'number' ? perPage : perPage.split('=')[1])
    }, [perPage])
    const handleConfirmImport = () => {

        const formData = {};
        for (let i of document.querySelectorAll('.staffModal input')) {
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
        }
       setTeamStaff(formData);
        setShow(false);
    };
    useEffect(() => {
        if (teamStaff) {
            console.log(teamStaff)
            addMemberstoStaff({
                id: props.id,
                teamStaff,
                setIsLoading: props.setIsLoading,
                setMessage,
                setErrors,
                setTeamDetail: props.setTeamDetail,
                setUpdate: props.setUpdate,
                update: props.setUpdate
            });
        }
    }, [teamStaff])
    const handleExpandClick = () => {
        setExpanded(!expanded);
        setPerPage(5)
        setCurrentPage(1); // Reset to the first page when expanding or collapsing
    };

    const handlePageChange = (url) => {
        const pageNumber = parseInt(url.replace('#', ''), 10);
        setCurrentPage(pageNumber);
    };
    const handlePerPageChange = (event) => {
        console.log(perPage)
        console.log(event)
        if (event && event) {
            const parsedValue = parseInt(event.split('=')[1], 10);
            setPerPage(parsedValue);
            console.log(perPage)
            // setCurrentPage(1); // Reset to the first page when the number of items per page changes
        }
    };


    const totalMembers = props.teamDetail && props.teamDetail.staff ? props.teamDetail.staff.length : 0;

    const membersToShow = props.teamDetail && props.teamDetail.staff
        ? expanded
            ? props.teamDetail.staff.slice((currentPage - 1) * perPage, currentPage * perPage)
            : props.teamDetail.staff.slice(0, 3)
        : [];

    const totalPages = Math.ceil(totalMembers / perPage);

    return (
        <>
            <Col md={12} className={'text-end'}>
                <a className={'btn btn-sm bkg-primary text-white border-0 me-2'}
                      onClick={() => setShow(true)}>
                    Aggiungi Staff
                </a>

            </Col>
            <h5 className={'fw-bold text-start'}>Soci dello staff</h5>
            <Row className={'mx-0'}>
                {message && (
                    <Alert variant={'success'} className={'mt-2'} onClick={() => setMessage(null)} dismissible>
                        {message}
                    </Alert>
                )}
                {errors && (
                    <Alert variant={'danger'} className={'mt-2'} onClick={() => setErrors(null)} dismissible>
                        {errors}
                    </Alert>
                )}
            </Row>
            <Table className={'table-responsive teamMembers'}>
                <thead className={'py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>Nome</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'> Ruolo</th>


                </tr>
                </thead>
                <tbody className={'text-start'}>
                {membersToShow.length > 0 ? (
                    membersToShow.map((i) => (
                        <tr className={'border-bottom-1'} key={i.id}>
                            <td data-label='Nome' className={'teamMemberName'}>
                                <img src={ImgPlaceholder} className={'img-fluid'} style={{width: '40px'}}/>
                                &nbsp;&nbsp;
                                <Link to={`/soci/dettaglio-socio/${i.id}`} className={'text-decoration-none fw-bold'}>
                                    {i.fullname}
                                </Link>
                            </td>

                            <td data-label='Convocazioni'>{i.role}</td>


                        </tr>
                    ))
                ) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>
                            <h5>Nessun socio trovato</h5>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            {totalMembers > 3 && (
                <Col>
                    <Button className="mt-2 bkg-second border-0" onClick={handleExpandClick}>
                        {expanded ? 'Mostra meno' : 'Mostra più'}
                    </Button>
                </Col>
            )}
            {expanded && totalMembers > perPage && (
                <TablePagination
                    pagination={Array.from({length: totalPages}, (_, i) => ({
                        label: i + 1,
                        url: `#${i + 1}`,
                        active: i + 1 === currentPage,
                    }))}
                    perPage={`per_page=${perPage}`}
                    setPageUrl={handlePageChange}
                    setPerPage={handlePerPageChange}
                />
            )}
            <AddStaffModal setShow={setShow} show={show} handleConfirm={handleConfirmImport}  />
        </>
    );
};

export default TeamStaff;