import Calendar from "../../Calendar/Calendar";
import {useEffect, useState} from "react";


const Trainings = (props) => {
    const [events, setEvents] = useState()
    const [trainings, setTrainings] = useState()
    const [color, setColor] = useState()
    const [isAll, setIsAll] = useState(false);


    useEffect(() => {
        if (props.teamDetails) {
            const test = props.teamDetails.training_schedules
            setTrainings(test)
            setColor(props.teamDetails.color)
        }

    }, [props.teamDetails])

    useEffect(() => {

        if (trainings) {
            const allEvents = trainings && trainings.map(i => {
                return {
                    start: i.start_time,
                    end: i.end_time,
                    title: i.location,
                    id: i.id,
                    team_id: props.id,
                    color: color ? color : 'var(--secondary)',
                    borderColor: 'transparent',
                    display: 'block',
                }
            });
            setEvents(allEvents);


        }

    }, [color, trainings])


    return (
        <>
            <Calendar events={events} height={600} setEvents={setEvents} id={props.teamDetails && props.teamDetails.id}
                      team_id={props.teamDetails && props.teamDetails.id}
                      isAll={isAll}
                      setTeamDetail={props.setTeamDetail} initalview={'dayGridMonth'}></Calendar>
        </>

    )
}

export default Trainings