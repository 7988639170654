import React, {useEffect, useState} from "react";
import {deleteTrainingPlan, retrieveTrainingPlans, retrieveWeekDays} from "../../../Api/Teams/Trainings/TrainingsFetch";
import {Alert, Badge, Col, Row, Table} from "react-bootstrap";
import {formatData, formatDataIt, formatDataItWithTime, formatDataWithTime} from "../../../Utils/DateFormat";
import {RiDeleteBinLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import ModalTrainingPlanDetail from "../../Modals/Trainings/TrainingsPlanDetail";
import {BiShow} from "react-icons/bi";
import {ConfirmationModal} from "../../Modals/ConfirmationModal";
import {deleteGame} from "../../../Api/Teams/Games/TeamGamesFetch";
import parse from "html-react-parser";

const TrainingPlans = (props) => {
    const [trainingPlans, setTrainingPlans] = useState([])
    const [show, setShow] = useState()
    const [weekDays, setWeekDays] = useState()
    const [indexElement, setIndexElement] = useState()
    const [trainingPlanId, setTrainingPlanId] = useState()
    const [showConfirm, setShowConfirm] = useState(false);
    const [actionMessage, setActionMessage] = useState()
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()

    useEffect(() => {
        retrieveTrainingPlans({setTrainingPlans, id: props.id})
    }, [])
    useEffect(() => {
        retrieveWeekDays({setWeekDays})
    }, [])

    useEffect(() => {
        console.log(trainingPlans)
    }, [trainingPlans])


    const handleClose = () => {
        setShowConfirm(false)
    }
    const handleConfirm = (e) => {
        deleteTrainingPlan({
            trainingPlanId,
            setIsLoading: props.setIsLoading,
            setErrors,
            setMessage,
            id: props.id,
            setTrainingPlans
        })
        setShowConfirm(false)
    }

    const handleCloseModal = () => {
        setShow(false)
    }
    return (
        <>

            <Row>
                {/**/}
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}
                <Table className={'table-responsive teams'}>
                    <thead className={'bkg-primary text-white py-2'}>
                    <tr>
                        <th className='py-2 px-2 text-start bkg-primary text-white '>Descrizione</th>
                        <th className='py-2 px-2 text-start bkg-primary text-white '>Location</th>
                        <th className='py-2 px-2 text-start bkg-primary text-white '>Inizio</th>
                        <th className='py-2 px-2 text-start bkg-primary text-white '>Fine</th>
                        <th className='py-2 px-2 text-start bkg-primary text-white '>Azioni</th>
                    </tr>
                    </thead>
                    <tbody className={'text-start'}>
                    {trainingPlans && trainingPlans.length > 0 ? trainingPlans.map((i, index) => {

                            return (
                                <tr key={i.id}>
                                    <td data-label={"Descrizione"}>{i.description ? i.description : 'Nessuna descrizione'}</td>
                                    <td data-label={"Luogo"}>{i.location}</td>
                                    <td data-label={"Inizio"}>{formatDataIt(new Date(i.start_date))}</td>
                                    <td data-label={"Fine"}>{formatDataIt(new Date(i.end_date))}</td>
                                    <td><BiShow className={'fs-5 mx-2'}
                                                title={'Mostra'} id={i.id} onClick={(e) => {
                                        setIndexElement(i)
                                        setShow(true)
                                    }}/>
                                        <RiDeleteBinLine className={'fs-5 text-danger'} onClick={() => {
                                            setShowConfirm(true)
                                            setTrainingPlanId(i.id)
                                            setActionMessage(parse('Sei sicuro di voler eliminare il piano di allenamenti? <br/><br/> ' +
                                                '<span className=""><b>NB</b>: Verranno eliminati tutti gli allenamenti successivi alla data odierna</span>'))

                                        }}/>
                                    </td>

                                </tr>

                            )

                        })
                        : <tr>
                            <td colSpan={12} className={'text-center'}><h5>Nessun piano di allenamenti</h5></td>
                        </tr>}

                    </tbody>
                </Table>
                {/*{i.plan.map(element => {
                            return <Col md={12}>
                                <Row>
                                    <Col> {weekDays && weekDays.map(day => {
                                        return day.id == element.day && day.value
                                    })}</Col>
                                    <Col>{element.location}</Col>
                                    <Col>{element.start_time} - {element.end_time}</Col>
                                </Row>
                            </Col>


                        })}*/}

            </Row>
            <ConfirmationModal show={showConfirm} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>
            <ModalTrainingPlanDetail show={show} setShow={setShow} trainingPlans={trainingPlans}
                                     setIndexElement={setIndexElement} indexElement={indexElement} weekDays={weekDays}/>
        </>

    )
}
export default TrainingPlans