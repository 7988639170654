import {useParams} from "react-router-dom";
import TeamDetailsInfos from "../../Components/Teams/TeamDetails/TeamDetailsInfos";
import React, {useEffect, useState} from "react";
import {retrieveTeamDetail} from "../../Api/Teams/TeamsFetch";
import Loading from "../../Components/Layout/Elements/Loading";
import {Alert} from "react-bootstrap";

const TeamDetails = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {id} = useParams()
    const [teamDetail, setTeamDetail] = useState()
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [stats, setStats] = useState([])
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        retrieveTeamDetail({id, setTeamDetail, setIsLoading})

    }, [update])

    useEffect(() => {
        if (teamDetail) {
            setStats(teamDetail.stats)
        }


    }, [teamDetail])
    return (
        <>
            {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                setMessage(null)
            }} dismissible> {message} </Alert>}
            {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                setErrors(null)
            }} dismissible> {errors} </Alert>}
            <TeamDetailsInfos id={id} teamDetail={teamDetail} setTeamDetail={setTeamDetail} setIsLoading={setIsLoading}
                              setErrors={setErrors} setMessage={setMessage} stats={stats} update={update} setUpdate={setUpdate} />
            {isLoading && <Loading/>}
        </>

    )
}

export default TeamDetails