import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {MdEmail} from "react-icons/md";
import {FaAddressCard} from "react-icons/fa";
import {BsFillTelephoneFill} from "react-icons/bs";
import {Link} from "react-router-dom";
import React from "react";

const CloneTeamModal = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }
    const handleChange= (e) =>{
        props.setWithPlayers(e.target.value)
    }
    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton><h5>Clona team <b>{props.team_name}</b></h5></Modal.Header>
            <Modal.Body>
             <p className={'fs-5'}>Vuoi clonare anche gli atleti?</p>
                <Form.Check type={"radio"} inline={true} label={'Si'} value={1} name={'withPlayers'} onChange={handleChange}></Form.Check>
                <Form.Check type={"radio"} defaultChecked={true} inline={true} label={'No'} value={0} name={'withPlayers'} onChange={handleChange}></Form.Check>
            </Modal.Body>
            <Modal.Footer>
                <Button className={'bg-danger border-0'} onClick={handleClose}>
                    Annulla
                </Button>
                <Button className={'bkg-second border-0'} onClick={props.handleConfirm}>
                    Clona
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CloneTeamModal