import {Button, Col, Form, Modal} from "react-bootstrap";
import React from "react";


const ModalAddInstallment = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
            <form id={'addInstallment'}>
                <Form.Label className={'small mb-1'}>Causale</Form.Label>
                <Form.Control className={'mb-2'}
                              name={'causal'} type={'text'}
                             ></Form.Control>
                <Form.Label className={'small mb-1'}>Importo</Form.Label>
                <Form.Control className={'mb-2'}
                              name={'amount'} type={'number'}
                ></Form.Control>
                <Form.Label className={'small mb-1'}>Data</Form.Label>
                <Form.Control className={'mb-2'}
                              name={'payment_date'} type={'date'}
                ></Form.Control>

            </form>
                <Col className={'text-end'}>
                    <Button className={'btn btn-sm bkg-primary text-white border-0 mt-2'} onClick={props.action}>Aggiungi</Button>
                </Col>

            </Modal.Body>
        </Modal>
    )
}
export default ModalAddInstallment