import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {formatDataIt, formatDataItWithTime, formatDataTime, formatDataWithTime} from "../../../Utils/DateFormat";
import {Link, useLocation} from "react-router-dom";
import {deleteTraining} from "../../../Api/Teams/Trainings/TrainingsFetch";
import React from "react";

const ModalCalendarTrainingDetail = (props) => {
  console.log(props.eventDetail && props.eventDetail.id)
    const id = props.eventDetail  && props.eventDetail.id
    return (
        <Modal show={props.showModalDetail} onHide={props.handleCloseModal} aria-labelledby="modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="modal-title">Dettagli allenamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.eventDetail && props.eventDetail.team_name && <p>Team: <b> {props.eventDetail.team_name}</b></p>}

                <p>Titolo
                    allenamento: {props.eventDetail && props.eventDetail.title ? props.eventDetail.title : 'Nessun titolo'}</p>

                <p>Inizio: {props.eventDetail && props.eventDetail.start}</p>
                <p>Fine: {props.eventDetail && props.eventDetail.end}</p>
                {props.eventDetail && props.eventDetail.description &&
                    <p>Descrizione: {props.eventDetail.description}</p>}
                <Row>
                    <Col>
                        <Link to={'/allenamenti/dettaglio-allenamento/'+id}>
                            Vai al dettaglio completo
                        </Link>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseModal}>
                    Chiudi
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export default ModalCalendarTrainingDetail