import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Loading from "../../Components/Layout/Elements/Loading";
import {
    deleteCommunications,
    downloadAttachment,
    retrieveCommunicationDetail
} from "../../Api/Communications/CommunicationsFetch";
import {Alert, Badge, Button, Card, Col, Form, Row} from "react-bootstrap";
import parse from 'html-react-parser';

import {formatDataIt} from "../../Utils/DateFormat";
import {CiFileOn} from "react-icons/ci";
import {LuFileDown} from "react-icons/lu";
import {ConfirmationModal} from "../../Components/Modals/ConfirmationModal";
import {RiDeleteBinLine} from "react-icons/ri";

const CommunicationDetail = (props) => {
    const {id} = useParams();
    const [communicationDetail, setCommunicationDetail] = useState([])
    const [errors, setErrors] = useState();
    const [message, setMessage]=useState()
    const [isLoading, setIsLoading] = useState()
    const [show, setShow] = useState();
    const [actionMessage, setActionMessage] = useState()
    const navigate = useNavigate()
    // const test= communicationDetail.content.toString()
    useEffect(() => {
        retrieveCommunicationDetail({id, setCommunicationDetail, setErrors, setIsLoading})

    }, [])

    useEffect(() => {
        console.log(communicationDetail)
    }, [communicationDetail])

    const handleDowloadFile = (e) => {
        console.log(e.currentTarget.getAttribute('data-id'))
        downloadAttachment({
            newsId: e.currentTarget.getAttribute('data-news-id'),
            id: e.currentTarget.getAttribute('data-id')
        })
    }

    const handleClose = () => {
        setShow(false)
    }
    const handleConfirm = (e) => {
        deleteCommunications({
            setIsLoading: props.setIsLoading,
            setErrors:props.setErrors,
            setMessage:props.setMessage,
            newsId: id,
            navigate: navigate

        })
        setShow(false)
    }

    return (
        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

            <Row>
                <Col md={8} sm={12} className={'mt-2'}>
                    <Card className={'p-3 border-0 shadow h-100 text-start'}>
                        <h3 className={'py-3 fw-bold'}>{communicationDetail.title}</h3>
                        {communicationDetail.content && parse(communicationDetail.content)}


                    </Card>
                </Col>

                <Col md={4} sm={12} className={'text-start mt-2'}>
                    <Card className={'p-3 border-0 shadow h-100 '}>
                        <h4 className={'fw-bold text-second'}>Dettagli Comunicazione</h4>
                        <Row className={'mt-2'}><Col>
                            <Col md={12} className={'mb-2'}>
                                <p className={'d-inline fw-bold'}> Comunicazione inviata
                                    il: </p>
                                <b>{formatDataIt(new Date(communicationDetail.created_at))}</b>

                            </Col>
                            <Col md={12} className={'mb-2'}>
                                <p className={'d-inline fw-bold'}>Comunicazione inviata a:</p>&nbsp;&nbsp;
                                <Badge
                                    bg={communicationDetail.send_option === 'All' ? 'warning' : communicationDetail.send_option === 'Profile' ? 'primary' : 'info'}
                                    className={'col ms-1 d-inline text-end'}>{communicationDetail.send_option}
                                </Badge>
                            </Col>
                            <Col md={12} className={'mb-2'}>
                                <p className={'d-inline fw-bold'}>Percentuale
                                    accettati: {communicationDetail.acceptance_ratio}</p>
                            </Col>
                            {communicationDetail.attachments && (
                                <>
                                    <p className={'fw-bold mb-1 mt-2'}>Allegati: </p>
                                    {communicationDetail.attachments.map(i => {
                                        return (
                                            <span className={'d-block mb-1'}><Link className={'text-decoration-none text-second'} key={i.id} data-news-id={i.news_id} data-id={i.id} onClick={handleDowloadFile}>
                                                <LuFileDown className={'fs-4 '}/>  {i.original_filename}
                                            </Link>
                                            </span>
                                        )
                                    })}
                                </>

                            )}
                        </Col>
                        <Col md={12} className={'text-center mt-3'}>
                            <Button className={'btn bg-danger border-0 w-100'} onClick={() => {
                                setShow(true)
                                setActionMessage(parse('Sei sicuro di voler eliminare la comunicazione?'))

                            }}>
                                <RiDeleteBinLine className={'fs-5 text-white me-2'} />  Elimina comunicazione
                            </Button>
                        </Col>

                        </Row>


                    </Card>
                </Col>

            </Row>

            {isLoading && <Loading/>}
            <ConfirmationModal show={show} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>
        </>

    )
}

export default CommunicationDetail