import React, {useEffect, useState} from "react";
import {
    addNewPayment, cancelPayment,
    deletePayedInstallment, editPayment,
    payInstallment,
    retrieveMemberPaymentsList, retrieveMemberPaymentsOverview, showPayment
} from "../../../Api/Payments/PaymentsFetch";
import {CiEdit} from "react-icons/ci";
import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {Badge, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {formatData, formatDataIt} from "../../../Utils/DateFormat";
import {LiaCashRegisterSolid} from "react-icons/lia";
import IconTooltip from "../../Layout/Elements/IconsTooltip";
import {ConfirmationModal} from "../../Modals/ConfirmationModal";
import ModalAddNewPayment from "../../Modals/Payments/ModalAddNewPayment";
import OverviewButtons from "../../Payments/Elements/OverviewButtons";

const PaymentList = ({id, setIsLoading, setMessage, setErrors}) => {
    const [paymentsList, setPaymentsList] = useState()
    const [paymentsOverview, setPaymentsOverview] = useState()
    const [paymentDetail, setPaymentDetail] = useState()
    const [showConfirm, setShowConfirm] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [showConfirmCancel, setShowConfirmCancel] = useState(false)
    const [installmentId, setInstallmentId] = useState()

    const [update, setUpdate] = useState(false)
    const [addPayment, setAddPayment] = useState()
    const [editPaymentDetails, setEditPaymentDetails] = useState()
    const [isEditPayment, setIsEditPayment] = useState()
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)

    const installment = paymentsList && paymentsList.length > 0 && paymentsList.filter(i => {

        return (i.type === 'installment') || (i.type === 'payment' && i.payment_plan_installment_id !== null)
    })
    const payments = paymentsList && paymentsList.length > 0 && paymentsList.filter(i => i.type === 'payment' && i.payment_plan_installment_id === null)

    useEffect(() => {
        console.log('installment', installmentId)
        retrieveMemberPaymentsList({id, setPaymentsList, setIsLoading, setMessage, setErrors})
    }, [id, setErrors, setIsLoading, setMessage, update, installmentId])

    useEffect(() => {
        console.log('installment', installmentId)
        retrieveMemberPaymentsOverview({id, setPaymentsOverview, setIsLoading, setMessage, setErrors})
    }, [id, setErrors, setIsLoading, setMessage, update, installmentId])

    const handlePayInstallment = () => {
        console.log('installment2', installmentId)
        const paymentDate = document.querySelector('input[name=payment_date]').value
        payInstallment({
            id,
            setIsLoading,
            paymentDate,
            installmentId,
            setMessage,
            setErrors,
            setShowConfirm,
            setUpdate,
            update
        })
    }
    const handleDeletePayment = () => {
        deletePayedInstallment({
            id,
            setIsLoading,
            installmentId,
            setMessage,
            setErrors,
            setShowConfirmDelete,
            setUpdate,
            update
        })
    }
    const handleCancelPayment = () => {
        cancelPayment({id, setIsLoading, installmentId, setMessage, setErrors, setShowConfirmCancel, setUpdate, update})
    }
    useEffect(() => {
        if (addPayment) {
            addNewPayment({
                id,
                setIsLoading,
                addPayment,
                setMessage,
                setErrors,
                setShowAddPaymentModal,
                setUpdate,
                update
            })
        }
    }, [addPayment])

    useEffect(() => {
        if (editPaymentDetails) {
            editPayment({
                id,
                setIsLoading,
                editPaymentDetails,
                setPaymentDetail,
                installmentId,
                setInstallmentId,
                setMessage,
                setErrors,
                setIsEditPayment,
                setShowAddPaymentModal,
                setUpdate,
                update
            })
        }
        console.log(editPaymentDetails)
    }, [editPaymentDetails])
    /* Se clicco su modifica e inserisce correttamente il payment ID faccio la chiamata per lo show Payment detail*/
    useEffect(() => {

        if (isEditPayment && installmentId) {

            showPayment({
                id,
                setIsLoading,
                installmentId,
                setMessage,
                setErrors,
                setShowAddPaymentModal,
                setUpdate,
                update,
                setPaymentDetail
            })

            console.log(paymentDetail)
        }
    }, [isEditPayment, installmentId])
    /* Se ho correttamente acquisito i dati di dettaglio del pagamento, apro la modal*/
    useEffect(() => {
        if (paymentDetail) {
            setShowAddPaymentModal(true)
        }
        console.log(paymentDetail)
    }, [paymentDetail])


    const handleAddNewPayment = (e) => {
        e.preventDefault();
        const formData = {};
        for (let i of document.querySelectorAll('#addPayment input:not([type=radio]), #addPayment textarea')) {
            const name = i.getAttribute('name');
            const value = i.value;
            console.log(name, value)
            formData[name] = value;
        }
        const selectedRadio = document.querySelector('#addPayment input[type=radio]:checked');
        formData['paid'] = selectedRadio ? selectedRadio.value : null;
        if (!isEditPayment) {
            setAddPayment(formData);
        } else {
            setEditPaymentDetails(formData)
        }

    }

    return (
        <>
            {paymentsOverview && paymentsOverview[0] && (
                <OverviewButtons overview={paymentsOverview[0]} />
            )}
            <h2 className={'text-start'}>Quote</h2>
            <Table>
                <thead>
                <tr>
                    <th className={'bkg-primary text-white'}>Data pagamento</th>
                    <th className={'bkg-primary text-white'}>Causale</th>
                    <th className={'bkg-primary text-white'}>Importo</th>

                    <th className={'bkg-primary text-white'}></th>
                    <th className={'bkg-primary text-white'}>Piano di pagamento</th>
                    <th className={'bkg-primary text-white'}>Azioni</th>


                </tr>
                </thead>
                <tbody>
                {installment && installment.length > 0 ? installment.map((payment, index) => {
                    let today = new Date();
                    let yesterday = new Date()
                    let futureDate = new Date();
                    // Aggiungo 30 giorni alla data odierna
                    futureDate.setDate(today.getDate() + 10);
                    yesterday.setDate(today.getDate() - 1);
                    const paymentDate = new Date(payment.payment_date);
                    /*Per le rate in scadenza tra 20 giorni o più */
                    const withinNext30Days = paymentDate > futureDate;
                    /*Per le rate scadute*/
                    const isBeforeToday = paymentDate < yesterday;


                    return (
                        <tr key={index}
                            className={`${withinNext30Days ? 'text-gray' : 'text-black'} `}>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday && payment.paid === 0 ? 'text-danger' : 'text-black'} `}>{formatDataIt(new Date(payment.balance_date))}
                            </td>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday && payment.paid === 0 ? 'text-danger' : 'text-black'} `}>{payment.causal}</td>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday && payment.paid === 0 ? 'text-danger' : 'text-black'}} `}>{payment.amount} €</td>
                            <td className={''}>{payment.paid === 1 ?
                                <Badge bg={'success'}
                                       className={'col d-inline text-end me-1 text-white'}>Pagato</Badge> :
                                <Badge bg={`${withinNext30Days ? 'warning' : 'danger'}`}
                                       className={'col d-inline text-end me-1 text-white'}>Non pagato</Badge>}</td>
                            <td className={''}><Link id={payment.payment_plan_id}
                                                     to={`${'/pagamenti?payment_id=' + payment.payment_plan_id + '&name=' + payment.payment_plan_name}`}>{payment.payment_plan_name}</Link>
                            </td>
                            <td className={''}>

                                {payment.paid === 0 ?
                                    <IconTooltip title={'incassa rata'} action={() => {
                                        setShowConfirm(true)
                                        setInstallmentId(payment.payment_plan_installment_id)
                                    }}>
                                        <LiaCashRegisterSolid size={20} color={'black'}/>
                                    </IconTooltip>
                                    : <IconTooltip title={'Annulla incasso'} action={() => {
                                        setShowConfirmDelete(true)
                                        setInstallmentId(payment.payment_id)
                                    }}>
                                        <RiDeleteBinLine size={20} color={'black'}/>
                                    </IconTooltip>}
                            </td>
                        </tr>

                    )
                }) : (
                    <tr>
                        <td colSpan={7}><h4 className={'text-center'}>Nessun piano di pagamento trovato</h4></td>
                    </tr>
                )}
                </tbody>
            </Table>
            <Row>
                <Col>
                    <h2 className={'text-start'}>Pagamenti</h2></Col>
                <Col className={'text-end'}>
                    <Button
                        className={'btn bkg-second btn-sm border-0'}
                        onClick={() => {
                            setShowAddPaymentModal(true)
                        }}>Aggiungi Pagamento</Button></Col> </Row>
            <Table>
                <thead>
                <tr>
                    <th className={'bkg-primary text-white'}>Data pagamento</th>
                    <th className={'bkg-primary text-white'}>Causale</th>
                    <th className={'bkg-primary text-white'}>Importo</th>

                    <th className={'bkg-primary text-white'}></th>
                    <th className={'bkg-primary text-white'}>Piano di pagamento</th>
                    <th className={'bkg-primary text-white'}>Azioni</th>


                </tr>
                </thead>
                <tbody>
                {payments && payments.length > 0 ? payments.map(payment => {
                    let today = new Date();
                    let futureDate = new Date();
                    // Aggiungo 30 giorni alla data odierna
                    futureDate.setDate(today.getDate() + 30);
                    const paymentDate = new Date(payment.payment_date);
                    /*Per le rate in scadenza tra 20 giorni o più */
                    const withinNext30Days = paymentDate <= futureDate && paymentDate >= today;
                    /*Per le rate scadute*/
                    const isBeforeToday = paymentDate < today;


                    return (
                        <tr key={payment.payment_id}
                            className={`${withinNext30Days ? 'text-gray' : 'text-black'} mb-0 mt-0 shadow-0`}>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday ? 'text-danger' : 'text-black'} `}>{formatDataIt(new Date(payment.balance_date))}
                            </td>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday ? 'text-danger' : 'text-black'} `}>{payment.causal}</td>
                            <td className={`${withinNext30Days ? 'text-grey' : isBeforeToday ? 'text-danger' : 'text-black'}} `}>{payment.amount} €</td>
                            <td className={''}>{payment.paid === 1 ?
                                <Badge bg={'success'}
                                       className={'col d-inline text-end me-1 text-white'}>Pagato</Badge> :
                                <Badge bg={'danger'}
                                       className={'col d-inline text-end me-1 text-white'}>Non pagato</Badge>}</td>
                            <td className={''}><Link id={payment.payment_plan_id}>{payment.payment_plan_name}</Link>
                            </td>
                            <td className={''}>
                                <IconTooltip title={'Modifica'} action={() => {
                                    console.log('click', isEditPayment, installmentId)
                                    setIsEditPayment(true)
                                    setInstallmentId(payment.payment_id)
                                }}>
                                    <CiEdit size={20} color={'black'}/>
                                </IconTooltip>
                                <IconTooltip title={'Elimina'} action={() => {
                                    setShowConfirmDelete(true)
                                    setInstallmentId(payment.payment_id)
                                }}>
                                    <RiDeleteBinLine size={20} color={'black'}/>
                                </IconTooltip>
                            </td>

                        </tr>

                    )
                }) : (
                    <tr>
                        <td colSpan={7}><h4 className={'text-center'}>Nessun pagamento trovato</h4></td>
                    </tr>
                )}
                </tbody>
            </Table>

            <ModalAddNewPayment show={showAddPaymentModal} setShow={setShowAddPaymentModal} action={handleAddNewPayment}
                                isEditPayment={isEditPayment} paymentDetail={paymentDetail}
                                setPaymentDetail={setPaymentDetail} setInstallmentId={setInstallmentId}
                                setIsEditPayment={setIsEditPayment}/>
            {/*Modal conferma di pagamento*/}
            <ConfirmationModal show={showConfirm} handleClose={() => {
                setShowConfirm(false)
            }}
                               handleConfirm={handlePayInstallment}
                               actionMessage={'Sei sicuro di voler incassare la rata?'}
                               content={<><Form.Label>Data Incasso</Form.Label><Form.Control
                                   type={'date'} name={'payment_date'}/></>}></ConfirmationModal>
            {/*Modal conferma elimina pagamento*/}
            <ConfirmationModal show={showConfirmDelete} handleClose={() => {
                setShowConfirmDelete(false)
            }}
                               handleConfirm={handleDeletePayment}
                               actionMessage={'Sei sicuro di voler eliminare la rata?'}
                               c></ConfirmationModal>

            {/*Modal conferma cancella incasso pagamento*/}
            <ConfirmationModal show={showConfirmCancel} handleClose={() => {
                setShowConfirmDelete(false)
            }}
                               handleConfirm={handleCancelPayment}
                               actionMessage={'Sei sicuro di voler annullare l\'incasso del pagamento?'}
                               c></ConfirmationModal>
        </>
    )
}
export default PaymentList