import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import {AiOutlineUser} from "react-icons/ai";
import React from "react";
import ImgPlaceholder from '../../../assets/img/placeholder.png'
import {formatDataIt} from "../../../Utils/DateFormat";
import {Link} from "react-router-dom";
import {BsSendFill} from "react-icons/bs";

const GeneralInfos = (props) => {


    return (

        <>
            <Col md={12}>
                <Card className={'px-4  d-flex py-3 mb-3 shadow border-0 '}>

                    <Row className={'justify-items-center align-items-center'}>
                        <Col md={4}>
                            <img src={ImgPlaceholder} className={'img-fluid w-75'}/>

                        </Col>
                        <Col md={8} className={'text-start'}>
                            <h3 className={'text-start text-second fw-bold'}> {props.memberDetail.first_name} {props.memberDetail.last_name}</h3>
                            <span className={'fw-bold text-second'}>Socio:</span> SI <br/>
                            <span
                                className={'fw-bold text-second'}>Indirizzo:</span> {props.memberDetail.address_line1} - {props.memberDetail.address_line2}<br/>
                            <span
                                className={'fw-bold text-second'}>Telefono:</span> {props.memberDetail.telephone_number}
                            <br/>
                            <span className={'fw-bold text-second'}>Email:</span> <Link
                            to={'mailto:' + props.memberDetail.email}
                            className={'fw-bold'}> {props.memberDetail.email} </Link>


                        </Col>
                        <Col md={12} className={'text-start mt-3'}>
                            {props.memberDetail && props.memberDetail.roles && props.memberDetail.roles.length > 0 && props.memberDetail.roles.map(i => {

                                return  <Badge key={i.id} bg={'info'} className={'col d-inline text-end me-1 mt-'}>{i.name}</Badge>

                            })}
                        </Col>
                        <Col className={'my-3'}>
                            <Link to={'/comunicazioni/nuova-comunicazione/'+props.id }className={'btn w-100 text-white bkg-second border-0 btn-sm'} >
                            <BsSendFill/> Invia
                            comunicazione</Link></Col>
                        {/*  <Col md={12} className={'text-start'}>


                        </Col>*/}
                    </Row>
                </Card>
            </Col>

        </>
    )
}

export default GeneralInfos