import { useState } from 'react';

const useDoubleClickPrevention = (initialState = false, delay = 1000) => {
    const [isDisabled, setIsDisabled] = useState(initialState);

    const preventDoubleClick = () => {
        if (!isDisabled) {
            setIsDisabled(true);

            setTimeout(() => {
                setIsDisabled(false);
            }, delay);
        }
    };

    return [isDisabled, preventDoubleClick];
};

export default useDoubleClickPrevention;