import {Col, Row} from "react-bootstrap";
import GeneralInfos from "./GeneralInfos";
import TeamsInfos from "./Teams/TeamsInfos";
import {generateRandomColors} from "../../../Utils/GenerateRandomColors";
import TeamCard from "./Teams/TeamCard";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {retrieveMemberDetail} from "../../../Api/Members/MembersFetch";
import {updateTeamFetch} from "../../../Api/Teams/TeamsFetch";

const InfoTab = ({id, setErrors, setMessage, setIsLoading, isLoading}) => {
    const [memberDetail, setMemberDetail] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [memberTeamsDetail, setMemberTeamsDetail] = useState();

    const [show, setShow] = useState(false)

    const navigate= useNavigate()

    useEffect(() => {
        retrieveMemberDetail({setMemberDetail, id, setMemberTeamsDetail})
    }, [id, isLoading])
    useEffect(() => {
        if (memberDetail && memberDetail.teams && memberDetail.teams.length > 0) {
            setSelectedTeam(memberDetail.teams[0].name + '0')
        }

    }, [memberDetail])


    const handleRemovefromTeam = (e) => {
        e.preventDefault();
        const id = e.target.getAttribute('data-id')
        const teamDatas = {members_to_remove: memberDetail.id}
        updateTeamFetch({id, teamDatas, pageUrl: '/team/update', setMessage, setIsLoading, setErrors, setMemberDetail, setMemberTeamsDetail})
    }
    return (<Row className={'mx-0'}>
        <Col lg={4} sm={12} className={'mb-3 mb-sm-0'}>
            <Row style={{position: 'sticky', top: '0'}}>
                <GeneralInfos memberDetail={memberDetail} id={id}/>
                <br/>
                <br/>

                <TeamsInfos selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam}
                            memberDetail={memberDetail}/>




            </Row>

        </Col>
        <Col lg={8} sm={12} className={'mt-sm-5 mt-lg-0'}>
            {memberDetail && memberDetail.teams ? (

                memberDetail.teams.map((team, index) => {
                    const games = {
                        labels: ['Totali', 'Giocate'],
                        datasets: [
                            {
                                label: 'partita',
                                data: [team.total_games_since_sync, team.games_played_by_profile],
                                backgroundColor: generateRandomColors(2),
                                borderWidth: 0,
                            },
                        ],
                    };

                    const roles = {
                        labels: Object.keys(team.roles_played),
                        datasets: [
                            {
                                label: 'partita',
                                data: Object.values(team.roles_played),
                                backgroundColor: generateRandomColors(Object.keys(team.roles_played).length),
                                borderWidth: 0,
                            },
                        ],
                    };

                    const dataTraining = {
                        labels: ['Totali', 'Giocati'],
                        datasets: [
                            {
                                label: 'Allenamenti',
                                data: [team.number_of_trainings_since_sync, team.number_of_accepted_trainings],
                                backgroundColor: generateRandomColors(2),
                                borderWidth: 0,
                            },
                        ],
                    };

                    return (
                        selectedTeam && selectedTeam === team.name + index &&
                        <TeamCard key={team.name} show={show} setShow={setShow}
                                  handleRemovefromTeam={handleRemovefromTeam} rowid={team.name + index} id={team.id} data={roles}
                                  dataConv={games} dataTraining={dataTraining}
                                  name={team.name} {...team}  />

                    )
                })

            ) : (
                <p> Nessun dato </p>
            )}

        </Col>
    </Row>
    )
}
export default InfoTab