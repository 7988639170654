import React, {useEffect, useState} from "react";

import {Alert, Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import AddGameForm from "../../../Components/Teams/Games/AddGameForm";
import {retrieveTeamDetail} from "../../../Api/Teams/TeamsFetch";
import SelectedMembersGame from "../../../Components/Teams/Games/SelectedMembersGame";
import {retrieveSportsRoles} from "../../../Api/Associations/AssociationsFetch";
import {
    addGame,
    retrieveGameField,
    retrieveGameStatus,
    retrieveGameType
} from "../../../Api/Teams/Games/TeamGamesFetch";


const NewTeamGame = () => {
    const [newTeam, setNewTeam] = useState([]);
    const [updateTeam, setUpdateTeam] = useState([]);
    const [addNewGame, setAddNewGame] = useState()
    const [perPage, setPerPage] = useState('per_page=10');
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])
    const [sportsRoles, setSportRoles] = useState([])
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const [teamDetail, setTeamDetail] = useState()
    const [sportID, setSportID] = useState()
    const {id} = useParams()
    const [convocations, setConvocations] = useState();
    const [gameType, setGameType] = useState([])
    const [gameStatus, setGameStatus] = useState([])
    const [gameField, setGameField] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        retrieveTeamDetail({id, setTeamDetail, setIsLoading, setErrors, setMessage});
        retrieveGameType({setGameType,setIsLoading, setErrors, setMessage})
        retrieveGameStatus({setGameStatus,setIsLoading, setErrors, setMessage})
        retrieveGameField({setGameField,setIsLoading, setErrors, setMessage})
        console.log(gameType)

    }, [])

    useEffect(() => {
        if (teamDetail) {
            setSportID((prevSportID) => {
                // Use the previous state to ensure correct updates
                const newSportID = teamDetail.sport_id;
                retrieveSportsRoles({id, setSportRoles, sportID: newSportID, setErrors});
                return newSportID;
            });
        }

    }, [teamDetail])


    useEffect(() => {
        if (addNewGame) {
            console.log(addNewGame)
             addGame({addNewGame, setIsLoading, setMessage, setErrors, setGeneralError, navigate, id})
        }
    }, [addNewGame])

    return (

        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
            {generalError && typeof generalError === 'string' && <Alert variant={'danger'} onClick={() => {setGeneralError(null) }} dismissible> {generalError} </Alert>}

            <Card className={'p-4 shadow border-0'}>

                <AddGameForm id={id} teamDetail={teamDetail} selectedMembers={selectedMembers}
                             setSelectedMembers={setSelectedMembers} sportsRoles={sportsRoles} errors={errors} setErrors={setErrors}
                             setConvocations={setConvocations} convocations={convocations} setAddNewGame={setAddNewGame}
                             addNewGame={addNewGame} gameStatus={gameStatus} gameType={gameType} gameField={gameField}/>


            </Card>

        </>
    )
}
export default NewTeamGame