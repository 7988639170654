import {Button, Col, Form, InputGroup, Nav, Row} from "react-bootstrap";
import {GrClearOption} from "react-icons/gr";

const TrainingsFilters = (props) => {
    const handleSearch = (e) => {
        e.preventDefault()

        props.setSearch({})
        // props.setPageUrl('/api/payers/v1?page=1')

        for (let i of document.querySelectorAll('form input, form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')

                const value = i.value;

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }


    }

    const handleClear = (e) => {
        e.preventDefault();
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }
    }
    return (
        <>
            <Row className={'mb-3 mx-0'}>
                <div className={'border-1 border-solid border-light'}>
                    <Row className={'align-content-center'}>
                        <Col md={8}><h5 className={'text-start'}>Filtra Calendario </h5></Col>
                        <Col md={4} className={'text-end'}>
                            <GrClearOption className={'me-2 fs-4'} title={'Pulisci Filtri'} onClick={handleClear}/>
                        </Col>
                    </Row>
                    <Form className={'row align-items-end'}>

                        <Form.Group className={'col-md-4 text-start d-inline-block'}>
                            <Form.Label className={'small text-start'}>Team</Form.Label>
                            <Form.Select name="team_id" className={'form-select-sm'}>
                                <option>Seleziona Team</option>
                                {props.team && props.team.map(i => {
                                    return (<option key={i.id} value={i.id}>{i.name}</option>)
                                })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={'col-md-4 text-start d-inline-block'}>
                            <Form.Label className={'small text-start'}>Location</Form.Label>
                            <Form.Control className={'form-control-sm'} name={'location'} placeholder={'Nome'}></Form.Control>
                        </Form.Group>
                      {/*  <Form.Group className={'col-md-3 d-inline-block'}>
                            <Form.Control className={'form-control-sm'} name={'content'} placeholder={'Descrizione'}></Form.Control>
                        </Form.Group>*/}

                      <Col md={2}>  <Button type={"submit"} onClick={handleSearch}
                                     className={'btn btn-sm d-block bkg-second no-border border-0 mx-0 mt-sm-0 mt-4'}>Cerca</Button></Col>



                    </Form>

                </div>
            </Row>
        </>
    )
}

export default TrainingsFilters