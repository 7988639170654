import {Button, Col, Row} from "react-bootstrap";
import PieChart from "../../../Charts/PieChart";
import TeamConvocationTable from "./TeamConvocationTable";


const TeamCard = (props) => {

    return (
        <Col md={12} className={'card border-0 shadow pb-2'} id={props.rowid} >
            <Row className={'mx-0 '}>

                <Col md={12} style={{backgroundColor: props.color ?  props.color : 'var(--secondary)'}} className={'text-white rounded-3 rounded-bottom-0 mb-2 mt-0'}>
                    <h3
                    className={'fw-bold pt-2'}>{props.name}</h3>
                    <h6 className={'fw-bold py-2'}>Coach: {props.coach_full_name}</h6></Col>
                <Col md={12} className={'px-2 pt-3 '}>
                    <h5 className={'fw-bold'}> Ultime convocazioni</h5>
                    {props.latest_games && props.latest_games.length > 0 ? (
                        <TeamConvocationTable latest_games={props.latest_games}/>) : (
                        <h5 className={'pb-3'}>Nessuna convocazione per il team </h5>)}
                </Col>

                <hr/>
                <Row className={'justify-content-around mb-4'}>
                    <Col md={3}>
                        <h5 className={'fw-bold'}>Ruoli giocati</h5>
                        {props.data && props.data.labels.length > 0 ? <PieChart data={props.data}/> : 'Nessun dato'}
                    </Col>
                    <Col md={3}>
                        <h5 className={'fw-bold'}>Convocazioni / Gare </h5>
                        {props.dataConv ? <PieChart data={props.dataConv}/> : 'Nessun dato'}

                    </Col>
                    <Col md={3}>
                        <h5 className={'fw-bold'}>Report </h5>
                        {props.dataTraining && props.dataTraining.labels.length > '0' ?
                            <PieChart data={props.dataTraining}/> : 'Nessun dato'}

                    </Col>
                </Row>
                <Col md={12}>
                    <Button className={'my-2  btn-danger btn-sm'} onClick={props.handleRemovefromTeam} data-id={props.id}>Rimuovi dal team</Button>
                </Col>
            </Row>


        </Col>
    )

}

export default TeamCard