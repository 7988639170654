import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Alert, Badge, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {GoDownload} from "react-icons/go";
import {CiStickyNote} from "react-icons/ci";
import {RiDeleteBinLine} from "react-icons/ri";
import ConvocationsNoteModal from "../../../Components/Modals/Games/ConvocationsNoteModal";
import Loading from "../../../Components/Layout/Elements/Loading";
import {
    addGameConvocations, addOrEditNote, deleteConvocation, downloadConvocations,
    retrieveGameConvocablePlayers,
    retrieveGameDetail
} from "../../../Api/Teams/Games/TeamGamesFetch";
import {
    addTeamPlayers, deletePlayer,
    retrieveTeamAvailablePlayers,
    retrieveTeamPlayers
} from "../../../Api/Teams/Players/TeamPlayersFetch";

const AddTeamPlayers = () => {
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [availablePlayers, setAvailablePlayers] = useState([])
    const [players, setPlayers] = useState([])

    const [showConvocables, setShowConvocables] = useState(true)
    const [addPlayers, setAddPlayers] = useState([])
    const [convocations, setConvocations] = useState()
    const [update, setUpdate] = useState(false)
    const {id} = useParams()
    const [show, setShow] = useState()
    const [notes, setNotes] = useState([])
    const [convocationId, setConvocationId] = useState()
    const [selectAll, setSelectAll] = useState(false); // Stato per il testo del link seleziona deseleziona
    useEffect(() => {
        retrieveTeamAvailablePlayers({id, setAvailablePlayers, setIsLoading, setErrors, setMessage});
        retrieveTeamPlayers({id, setPlayers, setIsLoading, setErrors, setMessage});

    }, [update])


    useEffect(() => {
        console.log(addPlayers)
        if (addPlayers && addPlayers.length > 0) {
            addTeamPlayers({id, addPlayers, setIsLoading, setErrors, setMessage, update, setUpdate, setAddPlayers})
        }
    }, [addPlayers])

    const handelAddConvocations = () => {
        const input = document.querySelectorAll('form.convocables input:checked')
        console.log(document.querySelectorAll('form.convocables input:checked'), document.querySelectorAll('form.convocables input'))

        input.forEach((i) => {
            setAddPlayers(prevState => {
                return [...prevState, {profile_id: i.value}]
            })

            console.log(i.value)
            return i.value
        })

        console.log(addPlayers)
    }
    const handleOpenModal = (e) => {
        e.preventDefault()
        setShow(true)
        setNotes(e.currentTarget.getAttribute('data-note'))
        console.log(notes)
        setConvocationId(e.currentTarget.getAttribute('data-id'))
        console.log(convocationId)

    }
    const handleNotes = () => {
        console.log(notes)
        if (notes.length > 0) {
            console.log(notes)
            addOrEditNote({convocationId, notes, setIsLoading, setErrors, setMessage, update, setUpdate, setShow})
        }
    }
    const handleDeletePlayer = (e) => {
        const playerId = e.currentTarget.getAttribute('data-id')
        console.log(playerId)
        deletePlayer({id, playerId, setIsLoading, setErrors, setMessage, update, setUpdate})
    }




    const handleSelectAll = () => {
        const inputs = document.querySelectorAll('form.convocables input[type="checkbox"]');
        const newSelectAllState = !selectAll;
        inputs.forEach(input => {
            input.checked = newSelectAllState;
        });
        setSelectAll(newSelectAllState);
    };

    return <>
        <Row>
            <Col>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <Card className={'shadow border-0 py-3 px-3 text-start'}>
                    <Row className={'mx-0'}>
                        <Col>
                            {showConvocables && <Col md={12} className={'mt-3'}>

                                <Row className={'mx-0 align-items-center'}>
                                    <Col>
                                        <h5 className={''}>Lista Soci</h5>
                                    </Col>

                                    <Form className={'convocables mt-3'}>
                                        {availablePlayers && availablePlayers.length > 0 &&
                                            <>
                                                <Link to={''} onClick={handleSelectAll} className={'mb-4 d-inline-block rounded-2 smaller '}>
                                                    {selectAll ? 'Deseleziona tutti' : 'Seleziona tutti'}</Link>
                                                {
                                                    availablePlayers.map(i => {
                                                        return (
                                                            <Form.Check key={i.id}
                                                                        label={i.first_name + ' ' + i.last_name}
                                                                        value={i.id}/>
                                                        )
                                                    })
                                                }

                                            </>
                                        }
                                    </Form>
                                </Row>
                                <Row className={'mx-0 justify-content-center mt-3'}>
                                    { availablePlayers && availablePlayers.length > 0 &&
                                        <Col>
                                        <Button
                                            className={'btn bkg-second btn-sm border-0'}
                                            onClick={handelAddConvocations}>Aggiungi Soci</Button>
                                    </Col>  }
                                </Row>

                            </Col>}
                        </Col>

                        <Col>

                            <Table>
                                <thead>
                                <tr>
                                    <th className={'bkg-primary text-white'}>Nome e Cognome</th>
                                    <th className={'bkg-primary text-white'}>Azioni</th>
                                </tr>
                                </thead>
                                <tbody>
                                {players && players.length > 0 ? players.map(player => {
                                    return <tr key={player.id}>
                                        <td>{player.full_name}</td>

                                        <td>
                                            <Link data-id={player.id} className={'text-decoration-none'}
                                                  title={'Elimina Socio dal team'}
                                                  onClick={handleDeletePlayer}> <RiDeleteBinLine
                                                className={'fs-5 mx-2 text-danger'}/></Link>

                                        </td>
                                    </tr>
                                }) : <tr>
                                    <td colSpan={7}>
                                        <h5 className={'text-center fst-italic'}>Nessun atleta</h5>
                                    </td>
                                </tr>}
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </Card>
            </Col>


        </Row>
        <ConvocationsNoteModal show={show} setShow={setShow} actionModal={handleNotes} notes={notes}
                               setNotes={setNotes}/>
        {isLoading && <Loading/>}
    </>
}

export default AddTeamPlayers