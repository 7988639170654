import React, {Fragment, useEffect, useState} from "react";
import {listProfiles, syncPlanProfiles} from "../../Api/Payments/PaymentsFetch";
import {Badge, Button, Card, Col, Form, Row} from "react-bootstrap";
import {RiDeleteBinLine} from "react-icons/ri";
import {Link} from "react-router-dom";

const PlanMembers = ({planMembers, id, setPlanMembers,paymentDetail, setErrors, setMessage, setIsLoading, update, setUpdate }) => {

    const [selectAll, setSelectAll] = useState()
    const [selectedProfile, setSelectedProfile] = useState()

    useEffect(() => {
        if (id) {
         setTimeout(function () {
             listProfiles({planId: id, setErrors, setMessage, setIsLoading, setPlanMembers})
         }, 500)
        }
        console.log(id)
    }, [id])
    console.log(id)
    useEffect(() => {
        if (selectedProfile) {
            syncPlanProfiles({
                planId: id,
                selectedProfile,
                setErrors,
                setMessage,
                setIsLoading,
                setPlanMembers,
                setSelectedProfile,
                update,
                setUpdate
            })
        }
        console.log(selectedProfile)

    }, [selectedProfile])
    const handleSelectAll = () => {
        const inputs = document.querySelectorAll('form.members input[type="checkbox"]');
        const newSelectAllState = !selectAll;
        inputs.forEach(input => {
            input.checked = newSelectAllState;
        });
        setSelectAll(newSelectAllState);
    };

    const handleSyncProfiles = () => {
        const formData = {
            profiles: {} // Crea un oggetto annidato "profiles"
        };
        const inputs = document.querySelectorAll('form.members input[type="checkbox"]');
        let selectedIndex = 0;
        inputs.forEach(function (i, index) {

            if (i.checked) {
                formData.profiles[selectedIndex] = i.value;
                selectedIndex++;
            }

        })
        console.log(formData)
        setSelectedProfile(formData)
    }
    return (
        <Row className={'mt-5  justify-content-between bg-white'}>

            <Col>
                <h5 className={''}>Lista Soci</h5>
            </Col>
            <Col md={4} className={'text-end'}>
                {/*   <Link to={''} onClick={()=>{setShowConvocables(false)}}>Nascondi</Link>*/}
            </Col>

            <Form className={'members mt-3'}>
                {planMembers && planMembers.length > 0 &&
                    <>      <Link to={''} onClick={handleSelectAll}
                                  className={'mb-4 d-inline-block rounded-2 smaller '}>
                        {selectAll ? 'Deseleziona tutti' : 'Seleziona tutti'}</Link>
                        {
                            planMembers.map(i => {
                                return (
                                    <Form.Check key={i.id} label={i.first_name + ' ' + i.last_name}
                                                value={i.id} defaultChecked={i.selected === true}/>
                                )
                            })
                        }
                    </>
                }
            </Form>

            <Row className={'mx-0 justify-content-center mt-3'}>
                <Col>
                    <Button
                        className={'btn bkg-second btn-sm border-0'} onClick={handleSyncProfiles}> Invia </Button>
                </Col>
            </Row>

        </Row>
    )
}
export default PlanMembers
