import {Form, Row, Table, Alert, Badge} from "react-bootstrap";

import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";


import TablePagination from "../../Layout/Elements/TablePagination";
import {formatDataIt} from "../../../Utils/DateFormat";


const ReportsOverviewTable = (props) => {


    return (
        <>

            <Table className={'table-responsive '}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Nome
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Da pagare
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Scaduto
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Pagato
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Totale
                    </th>


                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.payments && props.payments.length > 0 ? props.payments.map((i, index) => {
                    // let date = new Date(i.birthday).toISOString()
                    return (
                        <tr className={'border-bottom-1'} key={index}>
                            <td>
                                <img className={'img-fluid rounded-5 me-3'} style={{width: '30px'}}
                                     src={`${i?.profile_picture}`}/>
                                {i.full_name}
                            </td>
                            <td><span className={'bg-warning rounded-5 px-2 py-1 text-white'}>{i?.unpaid}</span></td>
                            <td><span
                                className={`${i.expired !== 0 && 'bg-danger'} rounded-5 px-2 py-1 text-white`}>{i?.expired}</span>
                            </td>
                            <td><span
                                className={`${i.paid !== 0 && 'bg-success text-white'} rounded-5 px-2 py-1 `}>{i?.paid}</span>
                            </td>
                            <td>{i?.total}</td>
                            {/*    <td><Badge bg={`${i?.paid === 1 ? 'success' : 'danger'}`}>{i?.paid === 1 ? 'Si' : 'No'}</Badge></td>*/}

                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun socio trovato</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl}
                             perPage={props.perPage}></TablePagination>


        </>
    )

}

export default ReportsOverviewTable