import axiosInstance from "../AxiosInstance";

//api call for forgot data
export const forgotPassword = ({email, setErrors, setSendMail, navigate, setMessage}) => {
    axiosInstance.post('/forgot', {
        email: email,
    }).then((response) => {
        // Handle successful response (status code 200)
        console.log(response)
        setMessage(response.data.message)
        setSendMail(response.status)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}

//api for send password confirmation
export const resetPassword = ({password, passwordConfirmation, token, email, setErrors, navigate}) => {
    axiosInstance.post('/reset_password', {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        token: token
    }).then((response) => {
        console.log(response)
        // Handle successful response (status code 200)
        if(response.data.status){
            navigate('/login')
        }else{
            setErrors({
                'password': response.data.message
            })
        }
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}

