import {Table} from "react-bootstrap";
import {CiEdit} from "react-icons/ci";
import {RiContactsLine, RiDeleteBinLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import React from "react";
import {formatDataIt} from "../../Utils/DateFormat";
import IconTooltip from "../Layout/Elements/IconsTooltip";

const NextPayments = ({nextPayments}) => {
    return (
        <Table className={'table-responsive noRounded'}>
            <thead className={'text-start text-bg- py-2'}>
            <tr>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Data
                </th>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Causale
                </th>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Importo
                </th>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Pagamenti effettuati
                </th>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Soci
                </th>
                <th className='px-2 text-smaller text-start text-bg-light'>
                    Abbonamento
                </th>
            </tr>
            </thead>
            <tbody className={'text-start'}>
            {nextPayments && nextPayments.length > 0 ? nextPayments.map((i) => {
                // let date = new Date(i.birthday).toISOString()
                return (
                    <tr className={'border-bottom-1 bg-light'} key={i.id}>
                        <td className={'bg-light'}>
                            <span className={'text-smaller'}> {formatDataIt(new Date(i.payment_date))}</span>
                        </td>

                        <td className={'bg-light'}>{i.causal}</td>
                        <td className={'bg-light'}> {i.amount} €<br/>

                        < /td>
                        <td className={'bg-light'}><span
                            className={'bg-success rounded-5 px-2 py-1 text-white'}>{i.total_profiles_payed}</span></td>
                        <td className={'bg-light'}>  {i.total_profiles}</td>
                        <td className={'bg-light'}><IconTooltip title={i.plan_name} className={'m-0 p-0'}>
                                <span
                                    key={'span-' + i.id} style={{fontSize: '11px'}}
                                    className={'text-black '}>{i.plan_name.slice(0, 12)}</span>
                        </IconTooltip></td>

                    </tr>)
            }) : (
                <tr className={'border-bottom-1 text-center'}>
                    <td colSpan={12}>

                        <h5>Nessun pagamento trovato</h5>

                    </td>
                </tr>
            )
            }


            </tbody>
        </Table>
    )
}
export default NextPayments