import {Table} from "react-bootstrap";
import {CiCalendar, CiEdit} from "react-icons/ci";
import {RiContactsLine, RiDeleteBinLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import React from "react";
import {formatDataIt} from "../../Utils/DateFormat";
import IconTooltip from "../Layout/Elements/IconsTooltip";

const LastPayments = ({lastPayments}) => {
    return (
        <Table className={'table-responsive noRounded'}>

            <tbody className={'text-start'}>
            {lastPayments && lastPayments.length > 0 ? lastPayments.map((i) => {

                return (
                    <tr className={'border-bottom-1'} key={i.id}>
                      <td className={'p-0 text-center'} style={{maxWidth: '30px', width: '30px'}}>
                          <img src={i.profile_pic} style={{width: '40px'}}/>
                      </td>  <td>
                            <span className={'flex flex-row align-items-center'} style={{fontSize: '12px'}}><CiCalendar
                                size={18}/> {formatDataIt(new Date(i.payment_date))}</span> <br/>
                            <span className={'fw-bold'}>{i.profile}</span><br/>
                            <span
                               style={{fontSize: '14px'}}
                                className={'text-white bkg-second px-2 rounded-2 '}>{i.causal}</span>
                            <br />
                           <span className={'text-smaller'}> {i.plan_name}</span>
                        </td>




                        <td colSpan={7} className={'text-end'}><span
                            className={'mb-0 text-success fs-5 fw-bold me-4'}> + {i.amount} €</span> <br/>

                        < /td>

                    </tr>)
            }) : (
                <tr className={'border-bottom-1 text-center'}>
                    <td colSpan={12}>

                        <h5>Nessun pagamento trovato</h5>

                    </td>
                </tr>
            )
            }


            </tbody>
        </Table>
    )
}
export default LastPayments