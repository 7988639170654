import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const ConfirmationModal = ({ show, handleClose, handleConfirm, actionMessage, content }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Conferma Azione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{content}</p>
                <p>{actionMessage}</p>

            </Modal.Body>
            <Modal.Footer>
                <Button className={'bg-danger border-0'} onClick={handleClose}>
                    Annulla
                </Button>
                <Button className={'bkg-second border-0'} onClick={handleConfirm}>
                    Conferma
                </Button>
            </Modal.Footer>
        </Modal>
    );
};