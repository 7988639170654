import {Button, Col, Form, Row} from "react-bootstrap";
import {emptyError} from "../Errors";
import {useOutletContext} from "react-router-dom";
import {useState} from "react";

const CreateAssociationForm = (props) => {
    const associationDetail = props.associationDetail;

    const handleCreate = (e) => {
        e.preventDefault();
        emptyError()
        const formData = {};
        for (let i of document.querySelectorAll('#associationAdd input,#associationAdd textarea, #associationAdd select')) {
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
            console.log(i.value)
        }

       props.setNewAssociation(formData);
        // props.setNewMember()
    }

    return (

            <Form id={'associationAdd'} className={'row align-items-end text-start'}>

                <Form.Group className={`${!props.associationDetail ? 'col-md-6' : 'col-md-12'} d-inline-block position-relative`}>
                    <Form.Label className={'small mb-0'}>Nome associazione</Form.Label>
                    <Form.Control defaultValue={associationDetail && associationDetail.name} className={'form-control-sm'} name={'name'} required={'required'}
                                  placeholder={'Nome'}></Form.Control>
                </Form.Group>
                {!associationDetail && (
                    <>
                        <Form.Group className={'col-md-6 d-inline-block  position-relative'}>
                            <Form.Label className={'small mb-0'}>Email</Form.Label>
                            <Form.Control  defaultValue={associationDetail && associationDetail.contact_email} type={'email'} className={'form-control-sm'} name={'contact_email'} required={'required'}
                                           placeholder={'Email'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-6 d-inline-block  position-relative'}>
                            <Form.Label className={'small mb-0'}>Nome</Form.Label>
                            <Form.Control  defaultValue={associationDetail && associationDetail.first_name} className={'form-control-sm'} name={'first_name'} required={'required'}
                                           placeholder={'Nome'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-6 d-inline-block  position-relative'}>
                            <Form.Label className={'small mb-0'}>Cognome</Form.Label>
                            <Form.Control  defaultValue={associationDetail && associationDetail.last_name} className={'form-control-sm'} name={'last_name'}  required={'required'}
                                           placeholder={'Cognome'}></Form.Control>
                        </Form.Group>
                    </>
                )}

                <Form.Group className={'col-md-12 d-inline-block  position-relative'}>
                    <Form.Label className={'small mb-0'}>Descrizione</Form.Label>
                    <Form.Control defaultValue={associationDetail && associationDetail.description} as="textarea" className={'form-control-sm'} name={'description'}
                                  placeholder={'Descrizione'}></Form.Control>
                </Form.Group>
                <Col md={12} className={'mt-3'}>
                    <Button type={"submit"} onClick={handleCreate}
                            className={'w-100 btn btn-sm bkg-second no-border border-0'}>{!props.associationDetail ? 'Crea' : 'Modifica'} </Button>
                </Col>


            </Form>

    )
}

export default CreateAssociationForm