import {Form, Row, Table, Alert, Badge} from "react-bootstrap";

import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";


import TablePagination from "../../Layout/Elements/TablePagination";
import {formatDataIt} from "../../../Utils/DateFormat";


const ReportsTable = (props) => {


    return (
        <>

            <Table className={'table-responsive '}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Nome
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Causale
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Data pagamento
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Data Incasso
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Pagato
                    </th>

                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Note
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Tipo
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Piano abbonamento
                    </th>

                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.payments && props.payments.length > 0 ? props.payments.map((i, index) => {

                    return (
                        <tr className={'border-bottom-1'} key={index}>
                            <td>
                                {i.first_name} {i.last_name}
                            </td>
                            <td>{i?.causal}</td>
                            <td>{formatDataIt(new Date(i.payment_date))}</td>
                            <td>{i.collection_date ? formatDataIt(new Date(i.collection_date)) : 'N/A'}</td>
                            <td><Badge bg={`${i?.paid === 1 ? 'success' : 'danger'}`}>{i?.paid === 1 ? 'Si' : 'No'}</Badge></td>
                            <td>{i?.notes}
                            < /td>
                            <td>{i?.type === 'installment' ? 'Pagamento' : 'Abbonamento'  }</td>
                            <td>{i?.plan_name}</td>
                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun socio trovato</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl} records={props.records}
                             perPage={props.perPage}></TablePagination>


        </>
    )

}

export default ReportsTable