import axiosInstance from "../../AxiosInstance";
import {retrieveTeamDetail} from "../TeamsFetch";


export const deleteGame = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/delete/' + props.gameId

    axiosInstance.delete(url).then((response) => {

        console.log('delete Game', response)
        retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        props.setMessage(response.data.message)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addGame = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/store/'

    axiosInstance.post(url, props.addNewGame).then((response) => {
        props.setIsLoading(false)
        console.log('add Game', response)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        props.setMessage(response.data.message)
        setTimeout((function () {
            props.navigate('/teams/dettaglio-team/' + props.id)
        }), 1000)


    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)

            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveGameDetail = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/show/' + props.gameId

    axiosInstance.get(url).then((response) => {

        console.log('Game Detail', response)

        props.setGameDetail(response.data.game)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})

        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const editGame = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/update/' + props.gameId

    axiosInstance.put(url, props.editedGame).then((response) => {

        console.log('edit Game', response)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveGameType = (props) => {
    props.setIsLoading(true)

    let url = '/games/type'

    axiosInstance.get(url).then((response) => {

        console.log('Game Type', response)
        props.setGameType(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveGameStatus = (props) => {
    props.setIsLoading(true)

    let url = '/games/status'

    axiosInstance.get(url).then((response) => {

        console.log('Game Status', response)
        props.setGameStatus(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrieveGameField = (props) => {
    props.setIsLoading(true)

    let url = '/games/field'

    axiosInstance.get(url).then((response) => {

        console.log('Game Field', response)
        props.setGameField(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}


export const downloadConvocations = (gameId, {setIsLoading, setErrors}) => {
    setIsLoading(true)

    let url = '/game/' + gameId + '/document/new'

    axiosInstance.get(url).then((response) => {

        console.log('Download', response)

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('href', response.data.link);
        link.setAttribute('target','_blank');
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link);
        }, 100);

        setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}


export const retrieveGameConvocablePlayers = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/'+props.gameId+'/convocables'

    axiosInstance.get(url).then((response) => {

        console.log('Convocable List', response)
        props.setConvocables(response.data.data)
        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const addGameConvocations = (props) => {
    props.setIsLoading(true)

    let url = '/team/game/'+props.gameId+'/convocations'

    axiosInstance.post(url,{convocations:  props.addConvocations}).then((response) => {

        console.log('Add Convocations', response)

        // retrieveTeamDetail({id: props.id, setTeamDetail: props.setTeamDetail, setIsLoading: props.setIsLoading})
        // props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdate(!props.update)
        props.setAddConvocations([])

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const deleteConvocation =(props) => {
    props.setIsLoading(true)

    let url = '/convocations/' + props.convId

    axiosInstance.delete(url).then((response) => {

        console.log('delete Convocation', response)
        props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdate(!props.update)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const addOrEditNote =(props) => {
    props.setIsLoading(true)

    let url = '/convocations/' + props.convocationId

    axiosInstance.put(url,
        {notes: props.notes}
        ).then((response) => {

        console.log(' Convocation Notes', response)
        props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setShow(false)
        props.setUpdate(!props.update)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}