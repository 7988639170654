import MyEditor from "../../Components/Communications/MyEditor";
import {Button, Card, Col, Row} from "react-bootstrap";
import '../../assets/css/Communications/Communications.css'
import {Outlet} from "react-router-dom";

const Communications = () => {
    return (
        <>
            <Outlet />

        </>

    )
}

export default Communications