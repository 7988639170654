import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import '../../assets/css/Login/Login.css'
import {Link, useNavigate} from "react-router-dom";
import {emptyError, handleErrors} from "../../Components/Errors";
import {forgotPassword, resetPassword} from "../../Api/Auth/ResetFetch";
import logo from '../../assets/img/logo.png'
const ResetPassword = () => {
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const [sendMail, setSendMail] = useState(false);
    const [message, setMessage]= useState()
    let token = params.get('token')

    let email = params.get('email')
    console.log(token)
    const [errors, setErrors] = useState()

    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    //handle submit for forgot and reset password
    const submitHandler = (e) => {
        e.preventDefault();
        emptyError();
        const emptyfields = document.querySelector('span.error_empty_field')

        if (!emptyfields) {
            const email = document.getElementById('email').value;

            if(token){
                console.log('1')
                const password = document.getElementById('password').value;
                const passwordConfirmation = document.getElementById('password_confirmation').value;

                resetPassword({password, passwordConfirmation, token, email, setErrors, navigate})
            }else{
                console.log('2')

                forgotPassword({email, setErrors, setSendMail, setMessage})
            }
        }
    }

    return (
        <Row className={'w-100 m-0 bgLogin container-full mw-100'}>
            <Row className={' justify-content-center text-center me-auto ms-auto align-content-center '}>
                <Col></Col>
                <Col className={'loginContainer text-white py-5 px-3 rounded-3 shadow'}>
                    <Row>
                        <Col md={6} className={' offset-3'}> <img src={logo} className={'img-fluid text-center w-100'}/></Col>
                    {/*    <Col md={6}><img src={logoAbbott} className={'img-fluid d-inline w-100'}/></Col>*/}
                        <Col md={12}> <hr style={{color: 'var(--bs-primary)', margin: '20px auto', borderWidth: '2px'}}/></Col>
                    </Row>

                    {!sendMail && (
                        <Row>
                            <Col className={'col-md-12 '}>
                                <p>{token ? 'Inserisci la nuova password' : 'Inserisci la tua email per ripristinare la password'}</p>
                            </Col>

                            <Col className={'col-md-12 '}>
                                <Form>
                                    {
                                        token ? (
                                            <>
                                                <Form.Group className={'position-relative'}>
                                                    <Form.Control type="password" id={'password'}
                                                                  className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                                  placeholder="Password" name={'password'} required={'required'}/>
                                                </Form.Group>

                                                <Form.Group className={'position-relative'}>
                                                    <Form.Control type="password" id={'password_confirmation'}
                                                                  className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                                  placeholder="Conferma Password" name={'password_confirmation'} required={'required'}/>
                                                </Form.Group>

                                                <Form.Control type={'hidden'} name={'token'} value={token} />
                                                <Form.Control type={'hidden'} id={'email'} name={'token'} value={email} />
                                                <br></br>
                                                <Row>
                                                    <Col></Col>
                                                    <Col><p className={'text-white text-smaller text-start'} style={{textJustify: 'distribute-all-lines'}}>
                                                        *la password deve avere: almeno 8 caratteri, un carattere maiuscolo, uno minuscolo e un numero</p></Col>
                                                    <Col></Col>
                                                </Row>

                                            </>
                                        ) : (
                                            <Form.Group className={'position-relative'}>
                                                <Form.Control type="email" id={'email'}
                                                              className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                              placeholder="Email" name={'reset_email'} required={'required'}/>
                                            </Form.Group>
                                        )
                                    }

                                    <Button variant="primary" type="submit"
                                            className={'mt-3 rounded-2 px-5 text-uppercase text-black fw-bold'}
                                            onClick={submitHandler}>
                                        Invia
                                    </Button>

                                </Form>
                                <Col className={'mt-4'}> <Link to={'/'} className={'small text-white'}>Annulla</Link></Col>
                            </Col>
                        </Row>
                    ) || (
                        <Col className={'col-md-12 '}>
                            <br></br>
                            <p>{message}
                            </p>
                        </Col>
                    )}
                </Col>
                <Col></Col>
            </Row>

        </Row>

    )
}

export default ResetPassword