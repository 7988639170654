import {Alert, Badge, Button, Col, Form, Row, Table} from "react-bootstrap";
import {AiOutlineUser} from 'react-icons/ai'
import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {MdOutlineMerge, MdOutlineSafetyDivider} from "react-icons/md";
import {BiShow} from "react-icons/bi";
import {RiContactsLine} from "react-icons/ri";
import TablePagination from "../../Layout/Elements/TablePagination";
import ImgPlaceholder from "../../../assets/img/placeholder.png";
import {formatDataIt} from "../../../Utils/DateFormat";
import teamDetails from "../../../Pages/Teams/TeamDetails";
import {isNumber, log10} from "chart.js/helpers";
import ImportPlayersModal from "../../Modals/Team/ImportPlayersModal";
import {importPlayers} from "../../../Api/Teams/TeamsFetch";
import authContext from "../../../Contexts/authContext";
import {retrieveTeamsNames} from "../../../Api/Communications/CommunicationsFetch";

const TeamMembers = (props) => {
    const { profile } = useContext(authContext);
    const [teamToImport, setTeamToImport] = useState();
    const [teamList, setTeamsList] = useState([]);
    const [pageUrl, setPageUrl] = useState('/team?');
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState(null);
    const [message, setMessage] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);

    useEffect(() => {
        retrieveTeamsNames({
            setTeamsList,
            pageUrl,
            setIsLoading: props.setIsLoading,
            setErrors,
        });
    }, [profile]);
    useEffect(() => {
        console.log(typeof perPage === 'number')
       setPerPage(  typeof perPage === 'number' ? perPage : perPage.split('=')[1])
    }, [perPage])
    const handleConfirmImport = () => {
        importPlayers({ id: props.id, teamToImport, setIsLoading: props.setIsLoading, setMessage, setErrors, setTeamDetail: props.setTeamDetail });
        setShow(false);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
        setPerPage(5)
        setCurrentPage(1); // Reset to the first page when expanding or collapsing
    };

    const handlePageChange = (url) => {
        const pageNumber = parseInt(url.replace('#', ''), 10);
        setCurrentPage(pageNumber);
    };
    const handlePerPageChange = (event) => {
        console.log(perPage)
        console.log(event)
        if (event  && event) {
            const parsedValue = parseInt(event.split('=')[1], 10);
            setPerPage(parsedValue);
            console.log(perPage)
            // setCurrentPage(1); // Reset to the first page when the number of items per page changes
        }
    };


    const totalMembers = props.teamDetail && props.teamDetail.members ? props.teamDetail.members.length : 0;

    const membersToShow = props.teamDetail && props.teamDetail.members
        ? expanded
            ? props.teamDetail.members.slice((currentPage - 1) * perPage, currentPage * perPage)
            : props.teamDetail.members.slice(0, 3)
        : [];

    const totalPages = Math.ceil(totalMembers / perPage );

    return (
        <>
            <Col md={12} className={'text-end'}>
                <Link to={'aggiungi-giocatori'} className={'btn btn-sm bkg-primary text-white border-0 me-2'} onClick={() => setShow(true)}>
                    Aggiungi atleti
                </Link>
                <Button className={'btn btn-sm bkg-second border-0'} onClick={() => setShow(true)}>
                    Importa atleti
                </Button>
            </Col>
            <h5 className={'fw-bold text-start'}>Soci del team</h5>
            <Row className={'mx-0'}>
                {message && (
                    <Alert variant={'success'} className={'mt-2'} onClick={() => setMessage(null)} dismissible>
                        {message}
                    </Alert>
                )}
                {errors && (
                    <Alert variant={'danger'} className={'mt-2'} onClick={() => setErrors(null)} dismissible>
                        {errors}
                    </Alert>
                )}
            </Row>
            <Table className={'table-responsive teamMembers'}>
                <thead className={'py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>Nome</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'></th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>Convocazioni</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>Partecipazioni</th>

                </tr>
                </thead>
                <tbody className={'text-start'}>
                {membersToShow.length > 0 ? (
                    membersToShow.map((i) => (
                        <tr className={'border-bottom-1'} key={i.id}>
                            <td data-label='Nome' className={'teamMemberName'}>
                                <img src={ImgPlaceholder} className={'img-fluid'} style={{ width: '40px' }} />
                                &nbsp;&nbsp;
                                <Link to={`/soci/dettaglio-socio/${i.id}`} className={'text-decoration-none fw-bold'}>
                                    {i.full_name}
                                </Link>
                            </td>
                            <td >{i.associated === 0 && (
                                <Badge key={i.id} bg={'danger'} className={'col d-inline text-end me-1 mt-'}>Non associato</Badge>
                            )}</td>
                            <td  data-label='Convocazioni'>{i.number_of_convocations}</td>
                            <td  data-label='Partecipazioni'>{i.number_of_accepted_convocations}</td>

                        </tr>
                    ))
                ) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>
                            <h5>Nessun socio trovato</h5>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            {totalMembers > 3 && (
                <Col>
                    <Button className="mt-2 bkg-second border-0" onClick={handleExpandClick}>
                        {expanded ? 'Mostra meno' : 'Mostra più'}
                    </Button>
                </Col>
            )}
            {expanded && totalMembers > perPage && (
                <TablePagination
                    pagination={Array.from({ length: totalPages }, (_, i) => ({
                        label: i + 1,
                        url: `#${i + 1}`,
                        active: i + 1 === currentPage,
                    }))}
                    perPage={`per_page=${perPage}`}
                    setPageUrl={handlePageChange}
                    setPerPage={handlePerPageChange}
                />
            )}
            <ImportPlayersModal setShow={setShow} show={show} handleConfirm={handleConfirmImport} teamList={teamList} setTeamToImport={setTeamToImport} />
        </>
    );
};

export default TeamMembers;