import axiosInstance from "../AxiosInstance";

export const retrieveAssociationsList = (props) => {
    const symbol = props.pageUrl.includes('?') ? '&' : '?';
    let url = props.pageUrl + symbol + props.filterType + symbol +   props.perPage ;
    const query= props.search ? Object.keys(props.search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
        .join('&') : null;

    /* const filter = filterSort ? Object.keys(filterSort)
         .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
         .join('&') : null;*/

    if(query  !== null) url += '&' + query;
    // if(filter !== null) url += '&' + filter;

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setAssociationsList(response.data.data.data)
        props.setPagination && props.setPagination(response.data.data.links)

        console.log('RetrieveAssociationsList', response)

        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addNewAssociation = (props) => {
    props.setIsLoading(true)
    let url = '/admin/associations'
    console.log('newAssociation', props.newAssociation)
    axiosInstance.post(url, props.newAssociation).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta aggiungi Associazione', response.data)
        props.setUpdateRetrieve && props.setUpdateRetrieve(prevState => !prevState)
        props.setMessage(response.data.message)
        setTimeout(function (){
             props.navigate('/admin/lista-associazioni')
        }, 1000)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors);
            props.setGeneralError && props.setGeneralError(error.response.data.message)

            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const showAssociation = (props) => {
    props.setIsLoading(true)
    let url = '/admin/associations/'+props.id

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta show Associazione', response.data)
        props.setAssociationDetail(response.data.data)
        props.setMessage && props.setMessage(response.data.message)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors);
            props.setGeneralError && props.setGeneralError(error.response.data.message)

            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const editAssociationFetch = (props) => {
    props.setIsLoading(true)
    let url = '/admin/associations/'+props.id

    axiosInstance.put(url, props.editAssociation).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta modifica Associazione', response.data)

        props.setMessage(response.data.message)
        setTimeout(function (){
             props.navigate('/admin/lista-associazioni')
        }, 1000)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors);
            props.setGeneralError && props.setGeneralError(error.response.data.message)

            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteAssociation = (props) => {
    props.setIsLoading(true)

    let url = '/admin/associations/' + props.id

    axiosInstance.delete(url).then((response) => {

        console.log('delete association', response)
        props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdateRetrieve && props.setUpdateRetrieve(prevState => !prevState)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}


