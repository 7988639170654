import {Bars, CirclesWithBar} from "react-loader-spinner";

const Loading = () => {

    return (
        // <CirclesWithBar
        //     height="100"
        //     width="100"
        //     color="#48ADE2"
        //     outerCircleColor="#48ADE2"
        //     innerCircleColor="#48ADE2"
        //     barColor="#48ADE2"
        //     ariaLabel="circles-with-bar-loading"
        //     wrapperStyle={{}}
        //     wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
        //     visible={true}
        // />

        <Bars
            height="150"
            width="100"
            color="#0A2A3B"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center z-3 d-flex spinnerPosition"
            visible={true}
        />
    )
}

export default Loading