import {Badge, Col, Row} from "react-bootstrap";

const SelectedRoles = (props) => {
    const handleDeselectMember = (selectedId) => {
        console.log('selectedID', selectedId)
        // Filtra gli elementi rimuovendo quello con l'id corrispondente
        const updatedSelectedRoles = props.selectedRoles.filter(role => role.value !== selectedId);
        props.setRolesToRemove(prevState => {
            return [...prevState, selectedId]
        })
        // Aggiorna lo stato con l'array filtrato
        props.setSelectedRoles(updatedSelectedRoles);
    }
    return (
        <Row className={'d-flex text-start justify-content-start my-2'}>
            <Col>
                {props.selectedRoles && props.selectedRoles.map(selected => {
                    return (
                        <>
                            <Badge key={selected.value} data-index={selected.index} data-id={selected.value} bg={"secondary"}
                                   className={'bkg-second me-2'} onClick={() => handleDeselectMember(selected.value)}><span
                                className={'me-2'}>X</span>{selected.label}</Badge>

                        </>
                    )
                })
                }
            </Col>
        </Row>

    )

}

export default SelectedRoles