import React, {useContext, useEffect, useState} from "react";
import '../../assets/css/Sidebar.css'

import {Link, useLocation} from "react-router-dom";
import {LuUsers} from "react-icons/lu";
import {RiAdminLine, RiTeamLine} from "react-icons/ri";
import {TfiAgenda, TfiIdBadge} from "react-icons/tfi";
import {IoDocumentsOutline, IoSettingsOutline} from "react-icons/io5";
import {IoChevronBackOutline, IoChevronForwardOutline} from "react-icons/io5";
import {Row} from "react-bootstrap";
import logo from "../../assets/img/logo.png";
import {TiPlus} from "react-icons/ti";
import {TbPlayFootball, TbShirtSport} from "react-icons/tb";

import {MdOutlineDashboard, MdOutlinePayments} from "react-icons/md";
import {HiOutlineDocumentReport} from "react-icons/hi";

import {LiaArrowAltCircleDown, LiaArrowAltCircleUp, LiaCashRegisterSolid, LiaClipboardListSolid} from "react-icons/lia";
import {PiUserList} from "react-icons/pi";
import {BiUserPlus} from "react-icons/bi";
import {CgAdd} from "react-icons/cg";
import {FaRegEnvelopeOpen} from "react-icons/fa";
import {BsEnvelopePlus} from "react-icons/bs";


const Sidebar = (props) => {
    console.log(window.innerWidth)
    const show = props.show
    const setShow = props.setShow
    const [showSubmenu, setShowSubmenu] = useState({
        'teams': true,
        'members': false,
        'match': false,
        'admin': false,
        'communication': false,
        'trainings': false,
        'payments': false,
    })
    const handleShowSubmenu = (submenu) => {
        let tempSubmenu = showSubmenu;
        tempSubmenu[submenu] = !tempSubmenu[submenu];
        setShowSubmenu(tempSubmenu);
    };

    const {pathname} = useLocation();

    const toggleSidebar = () => {
        setShow((prevShow) => (prevShow === "open" ? "closed" : "open"));
        localStorage.setItem("showSide", show === "open" ? "closed" : (window.innerWidth <= 769 ? "closed" : "open"));
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (window.innerWidth <= 768) {
            console.log('minore')
            setShow("closed");
        }
    }, [window.location.href])

    const handleResize = () => {
        if (window.innerWidth < 769) {
            setShow("closed");
        } else {
            setShow(localStorage.getItem("showSide") || "open");
        }
    };
    const mouseEnter = () => {
        document.querySelector('.sidebar').classList.add('hoverShow');
    }
    const mouseLeave = () => {
        document.querySelector('.sidebar').classList.remove('hoverShow');
    }

    return (
        <>
            <div
                className={`py-4 pt-2 sidebar ${show === 'open' ? 'overflow-y-auto' : 'hiddenSide'} bgGradientReversed`}>
                <Row className={'d-flex logoContainer mt-2 m-auto'} style={{width: '170px'}}> <img src={logo}
                                                                                                   className={'img-fluid'}/></Row>
                <div
                    className={`openSidebar d-sm-none d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex 
                     bkg-second  justify-content-center align-items-center text-white ${show === 'open' ? 'show' : 'hide'}`}
                    onClick={toggleSidebar}>
                    <a className={'text-white d-block m-0 w-100 text-center'}
                       href={'#'}> {show === 'open' ?
                        <IoChevronBackOutline className={'fs-5'}/> :
                        <IoChevronForwardOutline className={'fs-5'}/>} </a>
                </div>
                <div className={'test'} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>

                    <nav className={'nav text-start col-md-12 py-2 text-white'}>


                        <div className={'w-100 '}>
                            <ul className={'px-0 list-unstyled text-start'}>
                                <li
                                    className={`px-0 my-2  py-2 ${pathname.includes('home') ? 'active' : ''}`}>
                                    <Link to={'home'}
                                          className={'text-decoration-none text-white fs-6'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <MdOutlineDashboard
                                        className={'fs-4  rounded-0  mx-2'}/> </span> Dashboard
                                    </Link>

                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li className={`px-0 my-2  py-2  `}>
                                    <Link to={'#'} onClick={() => handleShowSubmenu('teams')}
                                          className={'text-decoration-none text-white fs-6 d-flex flex-row justify-content-between align-items-center'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <TbShirtSport
                                        className={'fs-4  rounded-0  mx-2'}/>  Teams  </span> {showSubmenu && showSubmenu?.teams ?
                                        <LiaArrowAltCircleUp size={25} className={'mx-2'}/> :
                                        <LiaArrowAltCircleDown size={25} className={'mx-2'}/>}
                                    </Link>

                                    {showSubmenu && showSubmenu?.teams && <>
                                        <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                        <ul className={'px-0 text-start mt-2 list-unstyled'}
                                            style={{marginLeft: '14px'}}>
                                            <li className={`${pathname === '/teams/lista-teams' ? 'active' : ''}`}>
                                                <Link to={'teams/lista-teams'}
                                                      className={'text-decoration-none text-white d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <LiaClipboardListSolid size={25}
                                                                           className={'fs-5  rounded-0  me-2'}/>Lista
                                                    Teams
                                                </Link>
                                            </li>
                                            <li className={`${pathname.includes('nuovo-team') ? 'active' : ''}`}>
                                                <Link to={'teams/nuovo-team'}
                                                      className={'text-decoration-none text-white d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span> <CgAdd
                                                    size={25}
                                                    className={'fs-5  rounded-0  me-2'}/>Nuovo Team
                                                </Link>
                                            </li>

                                        </ul>
                                    </>}
                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2 py-2`}>
                                    <Link to={'#'} onClick={() => handleShowSubmenu('members')}
                                          className={'text-decoration-none text-white fs-6 d-flex flex-row justify-content-between align-items-center'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <LuUsers
                                        className={'fs-4 rounded-0  mx-2'}/> Lista
                                        Soci</span> {showSubmenu && showSubmenu?.members ?
                                        <LiaArrowAltCircleUp size={25} className={'mx-2'}/> :
                                        <LiaArrowAltCircleDown size={25} className={'mx-2'}/>}
                                    </Link>

                                    {showSubmenu && showSubmenu?.members && <>
                                        <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                        <ul className={'px-0 text-start mt-2 list-unstyled mb-0'}
                                            style={{marginLeft: '14px'}}>
                                            <li className={`${pathname.includes('lista-soci') || pathname.includes('dettaglio-socio') ? 'active' : ''}`}>
                                                <Link to={'soci/lista-soci'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center '}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span> <PiUserList
                                                    size={25}
                                                    className={'rounded-0  me-2'}/> Lista soci
                                                </Link>
                                            </li>
                                            <li className={`${pathname.includes('aggiungi-socio') ? 'active' : ''}`}>
                                                <Link to={'soci/aggiungi-socio'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center '}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span> <BiUserPlus
                                                    size={25}
                                                    className={' rounded-0  me-2'}/> Nuovo Socio
                                                </Link>
                                            </li>

                                        </ul>

                                    </>}
                                </li>

                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2  py-2 ${pathname.includes('gare') ? 'active' : ''}`}>
                                    <Link to={'/gare'} className={'text-decoration-none text-white fs-6'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <TbPlayFootball
                                        className={'fs-4 rounded-0  mx-2'}/> </span> Lista Gare
                                    </Link>

                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2  py-2`}>
                                    <Link to={'#'} onClick={() => handleShowSubmenu('communication')}
                                          className={'text-decoration-none text-white fs-6 d-flex flex-row justify-content-between align-items-center'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <IoDocumentsOutline
                                        className={'fs-4 rounded-0  mx-2'}/> Comunicazioni </span> {showSubmenu && showSubmenu?.communication ?
                                        <LiaArrowAltCircleUp size={25} className={'mx-2'}/> :
                                        <LiaArrowAltCircleDown size={25} className={'mx-2'}/>}
                                    </Link>

                                    {showSubmenu && showSubmenu?.communication && <>
                                        <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                        <ul className={'px-0 text-start mt-2 list-unstyled'}
                                            style={{marginLeft: '14px'}}>
                                            <li className={`${pathname === '/comunicazioni/lista-comunicazioni' ? 'active' : ''}`}>
                                                <Link to={'/comunicazioni/lista-comunicazioni'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <FaRegEnvelopeOpen
                                                        className={'fs-5  rounded-0  me-2'}/> Lista
                                                    Comunicazioni
                                                </Link>
                                            </li>
                                            <li className={`${pathname.includes('nuova-comunicazione') ? 'active' : ''}`}>
                                                <Link to={'comunicazioni/nuova-comunicazione'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <BsEnvelopePlus
                                                        className={'fs-5  rounded-0  me-2'}/> Nuova
                                                    Comunicazione
                                                </Link>
                                            </li>
                                        </ul>
                                    </>}
                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2  py-2 ${pathname.includes('allenamenti') ? 'active' : ''}`}>
                                    <Link to={'/allenamenti/calendario-allenamenti'}
                                          className={'text-decoration-none text-white fs-6'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <TfiAgenda
                                        className={'fs-4 rounded-0  mx-2'}/> </span> Allenamenti
                                    </Link>
                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2  py-2 ${pathname.includes('impostazioni') ? 'active' : ''}`}>
                                    <Link to={'/impostazioni'}
                                          className={'text-decoration-none text-white fs-6'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <IoSettingsOutline
                                        className={'fs-4 rounded-0  mx-2'}/> </span> Impostazioni
                                    </Link>
                                </li>
                                <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                <li
                                    className={`px-0 my-2  py-2 `}>
                                    <Link to={'#'} onClick={() => handleShowSubmenu('payments')}
                                          className={'text-decoration-none text-white fs-6 d-flex flex-row justify-content-between align-items-center'}><span
                                        className={'d-inline-block rounded-5 py-2'}>  <MdOutlinePayments
                                        className={'fs-4 rounded-0  mx-2'}/> Pagamenti </span> {showSubmenu && showSubmenu?.payments ?
                                        <LiaArrowAltCircleUp size={25} className={'mx-2'}/> :
                                        <LiaArrowAltCircleDown size={25} className={'mx-2'}/>}
                                    </Link>

                                    {showSubmenu && showSubmenu?.payments && <>
                                        <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                        <ul className={'px-0 text-start mt-2 list-unstyled'}
                                            style={{marginLeft: '14px'}}>
                                            <li className={`${pathname.includes('pagamenti/overview') ? 'active' : ''}`}>
                                                <Link to={'/pagamenti/overview'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <LiaCashRegisterSolid    className={'fs-5  rounded-0  me-2'} size={25}/> Overview
                                                    pagamenti
                                                </Link>
                                            </li>
                                            <li className={`${pathname === 'pagamenti/lista-pagamenti' ? 'active' : ''}`}>
                                                <Link to={'pagamenti/lista-pagamenti'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <TfiIdBadge className={'fs-5  rounded-0  me-2'} size={25}/> Lista
                                                    abbonamenti
                                                </Link>
                                            </li>
                                            <li className={`${pathname.includes('pagamenti/report') ? 'active' : ''}`}>
                                                <Link to={'pagamenti/report'}
                                                      className={'text-decoration-none text-white  d-flex align-items-center'}><span
                                                    className={'d-inline-block rounded-5 py-2'}>  </span>
                                                    <HiOutlineDocumentReport
                                                        className={'fs-5  rounded-0  me-2'} size={25}/> Report
                                                </Link>
                                            </li>

                                        </ul>
                                    </>}
                                </li>
                                {props.user && props.user.is_admin === 1 && (
                                    <li
                                        className={`px-0 my-2  py-2 `}>
                                        <Link to={'#'} onClick={() => handleShowSubmenu('admin')}
                                              className={'text-decoration-none text-white fs-6'}><span
                                            className={'d-inline-block rounded-5 py-2'}>  <RiAdminLine
                                            className={'fs-4  rounded-0  mx-2'}/> </span> Admin
                                        </Link>
                                        {showSubmenu && showSubmenu?.admin && <>
                                            <hr style={{margin: 0, color: 'var(--secondary)'}}/>
                                            <ul className={'px-0 text-start mt-2 list-unstyled'}
                                                style={{marginLeft: '14px'}}>
                                                <li>
                                                    <Link to={'admin/lista-associazioni'}
                                                          className={'text-decoration-none text-white d-flex align-items-center'}><span
                                                        className={'d-inline-block rounded-5 py-2'}>  </span> <TiPlus
                                                        className={'fs-5  rounded-0  me-2'}/>Lista Associazioni
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'admin/nuova-associazione'}
                                                          className={'text-decoration-none text-white d-flex align-items-center'}><span
                                                        className={'d-inline-block rounded-5 py-2'}>  </span> <TiPlus
                                                        className={'fs-5  rounded-0  me-2'}/>Nuova Associazione
                                                    </Link>
                                                </li>
                                            </ul>
                                        </>}
                                    </li>
                                )}
                            </ul>


                        </div>

                    </nav>
                </div>

            </div>

        </>
    )
}

export default Sidebar