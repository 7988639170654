import PaymentsSearch from "../../Components/Payments/PaymentsSearch";
import PaymentsFilters from "../../Components/Payments/PaymentsFilter";
import React, {useEffect, useState} from "react";
import {retrievePaymentsList, searchPaymentReport, showPaymentPlan} from "../../Api/Payments/PaymentsFetch";
import ReportsTable from "../../Components/Payments/Reports/ReportsTable";
import Loading from "../../Components/Layout/Elements/Loading";
import {Alert, Card, Col, Row} from "react-bootstrap";
import MembersSearch from "../../Components/Members/MembersSearch";
import MembersFilters from "../../Components/Members/MembersFilters";
import {Link} from "react-router-dom";
import {AiOutlineUserAdd} from "react-icons/ai";
import MembersTable from "../../Components/Members/MembersTable";
import MemberContact from "../../Components/Modals/Members/MemberContact";

const PaymentsReports = () => {
    const [perPage, setPerPage] = useState('per_page=10');
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/payments/report?')
    const [payments, setPayments] = useState()
    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [show, setShow] = useState(false)
    const [filterType, setFilterType] = useState('');
    const [update, setUpdate] = useState(false)
    const [message, setMessage] = useState()
    const [paymentsList, setPaymentsList] = useState()
    const [paymentPlanName, setPaymentPlanName] = useState([])
    useEffect(() => {
        console.log('ok')
        retrievePaymentsList({setIsLoading, setPerPage, setPageUrl, pageUrl:'/payments/plan', setPaymentsList})
    }, [])

    useEffect(() => {
        if (paymentsList) {
            paymentsList.map(payment =>{
                console.log(payment)
                setPaymentPlanName(prevState => [...prevState, {
                    ['name']: payment.plan_name,
                    ['id']: payment.id
                }]
                )

            })
        }
    }, [paymentsList])

    useEffect(() => {
        console.log(paymentPlanName)
    }, [paymentPlanName])

    useEffect(() => {
        if (filterType && filterType !== '') {
            setSearch(search => ({
                    ...search, ['paid']: filterType
                })
            )
        }
    }, [filterType])
    useEffect(() => {
     if (search) {
         searchPaymentReport({
             setPayments,
             setPagination,
             pageUrl,
             perPage,
             setIsLoading,
             setErrors,
             search,
             setUpdate, update,
             filterType
         })
     }
    }, [search, pageUrl])

    return (
        <>
            <>
                <Card className={'px-2 py-4 border-0 shadow mb-2'}>
                    <PaymentsSearch setSearch={setSearch} setPageUrl={setPageUrl} paymentPlanName={paymentPlanName}/>
                </Card>
                <Card className={'px-2 py-4 border-0 shadow'}>
                    <Row>
                        <Col md={8}>
                            <PaymentsFilters setFilterType={setFilterType}  setPageUrl={setPageUrl}/>
                        </Col>

                    </Row>
                    {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                        setMessage(null)
                    }} dismissible> {message} </Alert>}
                    {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}
                    <ReportsTable payments={payments} pagination={pagination} setPageUrl={setPageUrl}
                                  setPerPage={setPerPage} setShow={setShow}
                                  setErrors={setErrors}
                                  rrors={errors} />

                </Card>
                {isLoading && <Loading/>}




            </>



            {isLoading && <Loading/>}
        </>
    )
}

export default PaymentsReports