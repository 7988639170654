import {Button, Col, Form, Modal} from "react-bootstrap";
import React from "react";


const ModalAddPlan = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true} backdrop={'static'}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
            <form id={'addPlan'}>
                <Form.Label className={'small mb-1'}>Nome piano</Form.Label>
                <Form.Control className={'mb-2'}
                              name={'plan_name'} type={'text'}
                             ></Form.Control>
                <Form.Label className={'small mb-1'}>Descrizione</Form.Label>
                <Form.Control className={'mb-2'}
                              name={'description'} type={'text'} as={'textarea'}
                ></Form.Control>
            </form>
                <Col className={'text-end'}>
                    <Button className={'btn btn-sm bkg-primary text-white border-0 mt-2'} onClick={props.action}>Aggiungi</Button>
                </Col>

            </Modal.Body>
        </Modal>
    )
}
export default ModalAddPlan