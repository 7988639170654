import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {Table} from "react-bootstrap";
import React from "react";

const InstallmentTable = ({paymentDetail, setShowConfirm, setSelectedPlan, setSelectedInstallment}) => {
    return (
       <>
           <Table>
               <thead>
               <tr>
                   <th className={'bkg-primary text-white'}>Importo</th>
                   <th className={'bkg-primary text-white'}>Causale</th>
                   <th className={'bkg-primary text-white'}>Data</th>
                   <th className={'bkg-primary text-white'}>Azioni</th>
               </tr>
               </thead>
               <tbody>
               {paymentDetail && paymentDetail?.installments && paymentDetail?.installments.length > 0 && paymentDetail?.installments.map(installment => {
                   return (
                       <tr key={installment.id}>
                           <td>{installment.amount}</td>
                           <td>{installment.causal}</td>
                           <td>{installment.payment_date}</td>
                           <td>
                               <Link to={''} data-id={installment.id} onClick={(e) => {
                                   setShowConfirm(true)
                                   setSelectedPlan(paymentDetail.id)
                                   setSelectedInstallment(installment.id)
                               }}><RiDeleteBinLine className={'fs-5 mx-2 text-danger'}/></Link>
                           </td>
                       </tr>
                   )
               })
               }
               </tbody>

           </Table>
           <p> Importo totale: {paymentDetail.total_amount} €</p>
       </>
    )
}
export default InstallmentTable