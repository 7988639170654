import React, {useEffect, useState} from "react";

import {Alert, Card} from "react-bootstrap";
import {useParams} from "react-router-dom";
import AddGameForm from "../../../Components/Teams/Games/AddGameForm";
import {retrieveTeamDetail, updateTeamFetch} from "../../../Api/Teams/TeamsFetch";
import SelectedMembersGame from "../../../Components/Teams/Games/SelectedMembersGame";
import {
    retrieveCoachesList,
    retrieveSportsList,
    retrieveSportsRoles
} from "../../../Api/Associations/AssociationsFetch";
import {
    addGame,
    editGame,
    retrieveGameDetail,
    retrieveGameField,
    retrieveGameStatus, retrieveGameType
} from "../../../Api/Teams/Games/TeamGamesFetch";
import FullCalendar from "@fullcalendar/react";
import Calendar from "../../../Components/Calendar/Calendar";


const EditTeamGame = () => {
    const [teamDetail, setTeamDetail] = useState()
    const [pageUrl, setPageUrl] = useState('team/store')
    const [perPage, setPerPage] = useState('per_page=10');
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])
    const [sportsRoles, setSportRoles] = useState([])
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const [gameDetail, setGameDetail] = useState()
    const [editedGame, setEditedGame] = useState()
    const [sportID, setSportID] = useState()
    const {id, gameId} = useParams()
    const [convocations, setConvocations] = useState([]);
    const [convocationsToRemove, setConvocationsToRemove] = useState([])
    const [gameType, setGameType] = useState([])
    const [gameField, setGameField] = useState([])

    useEffect(() => {
        retrieveTeamDetail({id, setTeamDetail, setIsLoading, setErrors, setMessage});
        retrieveGameDetail({gameId, setGameDetail, setIsLoading, setErrors, setMessage});
        retrieveGameType({setGameType,setIsLoading, setErrors, setMessage})
        retrieveGameField({setGameField,setIsLoading, setErrors, setMessage})

    }, [gameId]);

    useEffect(() => {


    }, [])

    useEffect(() => {
        if (teamDetail) {
            setSportID((prevSportID) => {
                // Use the previous state to ensure correct updates
                const newSportID = teamDetail.sport_id;
                retrieveSportsRoles({id, setSportRoles, sportID: newSportID, setErrors});
                return newSportID;
            });
        }

    }, [teamDetail])


    useEffect(() => {
        // Se c'è teamDetail e ci sono utenti associati, costruisco il selectedMembers per ripopolare la Multiselect
        if (gameDetail && gameDetail.convocations) {
            gameDetail.convocations.forEach((i, index) => {
                if (!selectedMembers.some((member) => member.value === i.profile_id)) {
                    setSelectedMembers((prevValue) => [
                        ...prevValue,
                        {
                            label: i.profile_name,
                            value: i.profile_id,
                            id: i.id,
                            key: i.profile_id,
                            index: index,
                            notes: i.notes,
                            sport_role_id: i.sport_role_id,
                            has_accepted: i.has_accepted
                        },
                    ]);

                    setConvocations((prevValue) => [
                        ...prevValue,
                        {
                            id: i.id,
                            profile_id: i.profile_id,
                            sport_role_id: i.sport_role_id,
                            notes: i.notes

                        },
                    ]);

                }
            });
        }

    }, [gameDetail]);

    useEffect(() => {
        if (editedGame) {

            editGame({gameId, editedGame, setIsLoading, setMessage, setErrors, setGeneralError})
        }

    }, [editedGame])

    return (

        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
            {generalError && typeof generalError === 'string' && <Alert variant={'danger'} onClick={() => {setGeneralError(null) }} dismissible> {generalError} </Alert>}
            <Card className={'p-4 shadow border-0'}>

                <AddGameForm gameId={gameId} gameDetail={gameDetail} setGameDetail={setGameDetail}
                             setEditedGame={setEditedGame} selectedMembers={selectedMembers}
                             setSelectedMembers={setSelectedMembers} sportsRoles={sportsRoles}
                             setConvocations={setConvocations} convocations={convocations} teamDetail={teamDetail}
                             convocationsToRemove={convocationsToRemove}
                             setConvocationsToRemove={setConvocationsToRemove} gameType={gameType} gameField={gameField} />


            </Card>

        </>
    )
}
export default EditTeamGame