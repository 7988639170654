import {Badge, Button, Col, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import TablePagination from "../Layout/Elements/TablePagination";
import {deleteCommunications} from "../../Api/Communications/CommunicationsFetch";
import {formatDataIt} from "../../Utils/DateFormat";
import parse from 'html-react-parser';
import {FaEye} from "react-icons/fa";
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import './../../assets/css/CommunicationsTable.css'


const CommunicationsTable = (props) => {
    const [show, setShow] = useState();
    const [actionMessage, setActionMessage] = useState()
    const [newsId, setNewsId] = useState()
    const handleClose = () => {
        setShow(false)
    }
    const handleConfirm = (e) => {
        deleteCommunications({
            setIsLoading: props.setIsLoading,
            setErrors:props.setErrors,
            setMessage:props.setMessage,
            newsId: newsId,
            updateComm: props.updateComm,
            setUpdateComm: props.setUpdateComm

        })
        setShow(false)
    }

    return (
        <>
            <Table className={'tableCommunication'}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white '>Titolo comunicazione</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white '>Data</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white '>Inviata a</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white '>Anteprima</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white '>Azioni</th>
                </tr>
                </thead>
                <tbody>
                {props.communicationsList && props.communicationsList.length > 0 ? props.communicationsList.map((i) => {
                        return (

                            <tr key={i.id}>
                                <td className={'text-start'} data-label={'Titolo'}>


                                    <h6 className={'fw-bold mb-0'}>
                                        <Link to={'/comunicazioni/dettaglio-comunicazione/' + i.id}
                                              className={'text-decoration-none'}> {i.title.substring(0, 40)} </Link>
                                    </h6>
                                </td>
                                <td className={'card  text-start border-0  px-3 '} data-label={'Data'}>
                           <span
                               className={'col  fw-bold text-second'}>{formatDataIt(new Date(i.created_at))}</span>
                                </td>
                                <td className={'text-start'} data-label={'Inviata a '}><Badge
                                    bg={i.send_option === 'All' ? 'warning' : i.send_option === 'Profile' ? 'primary' : 'info'}
                                    className={'col d-inline text-end'}>{i.send_option}</Badge></td>


                                <td className={'text-start'} data-label={'anteprima'}><span
                                    className={'text-smaller'}>{parse(i.preview.substring(0, 30))}</span></td>
                                <td className={'text-start'}>
                                    <Link to={'/comunicazioni/dettaglio-comunicazione/' + i.id}
                                          className={'btn-sm bkg-second btn text-white border-0 me-2'} title={'Leggi comunicazione'}> <FaEye
                                        className={'fs-6 text-white'}/> </Link>
                                    <Link to={''} title={'Elimina comunicazione'}>
                                        <RiDeleteBinLine className={'fs-5 text-danger'} onClick={() => {
                                            setShow(true)
                                            setNewsId(i.id)
                                            setActionMessage(parse('Sei sicuro di voler eliminare la comunicazione?'))

                                        }}/>
                                    </Link>
                                </td>


                            </tr>

                        )

                    })
                    : <tr>
                        <td>Nessuna comunicazione</td>
                    </tr>}

                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl}
                             perPage={props.perPage}></TablePagination>

            <ConfirmationModal show={show} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>
        </>
    )

}

export default CommunicationsTable