import logo from './logo.svg';
import './App.css';
import Layout from "./Components/Layout/Layout";
import {AuthContextProvider} from "./Contexts/authContext";
import "react-datepicker/dist/react-datepicker.css";

function App() {
    return (
        <AuthContextProvider>
            <div className="App ">
                <Layout/>
            </div>
        </AuthContextProvider>
    );
}

export default App;
