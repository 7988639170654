import {Badge, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {gamesConvocatedList, gamesList} from "../../Api/Games/GamesFetch";
import {Link} from "react-router-dom";
import GamesConvocatedModal from "../Modals/Games/GamesConvocatedModal";

const GamesListGrid = (props) => {
    const [show, setShow] = useState(false)
    const [games, setGames] = useState([])
    const [gameID, setGameID] = useState([])
    const [convocationList, setConvocationList] = useState()

    useEffect(() => {
        gamesList({
            setGames,
            setErrors: props.setErrors,
            setIsLoading: props.setIsLoading,
            setMessage: props.setMessage
        })
    }, [])
    useEffect(() => {
        if (gameID) {
            console.log(gameID)
            gamesConvocatedList({
                gameID, setConvocationList, setErrors: props.setErrors,
                setIsLoading: props.setIsLoading,
                setMessage: props.setMessage
            })
        }
    }, [gameID])
    useEffect(() => {
        console.log(convocationList)

    }, [convocationList])

    return (
        <Row className={'mx-0 '}>
            {games && games.length > 0 ? games.map(game => {
                const data = new Date(game.date_time).toLocaleDateString()
                const ora = new Date(game.date_time).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})
                return (

                    <Col md={4} key={game.id} className={'mb-4'}>
                        <Card className={'border-0 shadow p-4 text-start h-100 flex-column justify-content-between'}>
                            <div>
                                <h2 style={{fontSize: '1.5em'}}>{game.title}</h2>
                                <h4 style={{fontSize: '1.2em', fontFamily: 'Oswald'}}>{data} {ora}</h4>
                            </div>
                            <p className={'mb-0'} style={{fontSize: '14px'}}><span
                                className={'fw-bold'}>Location</span>: {game.location}</p>
                            <p className={'mb-2'} style={{fontSize: '14px'}}><span
                                className={'fw-bold'}>Indirizzo</span>: {game.location_address}</p>
                            <hr style={{margin: '10px 0', color: 'var(--secondary)'}}/>
                            <Row>
                                <Col>
                                    <Link to={'/teams/dettaglio-team/' + game.team.id}>
                                            <span
                                                className={'rounded-4 text-sm d-inline-block px-2 w-auto text-center text-white text-smaller'}
                                                style={{
                                                    backgroundColor: game.team.color ? game.team.color : 'var(--secondary)'
                                                }}>{game.team.name} </span>
                                    </Link>
                                </Col>
                                <Col className={'align-self-end text-end'}>
                                    {game?.convocations === 0 ?
                                        <span className={'text-smaller text-grey'}>Nessun convocato</span> :
                                        <Link
                                            className={'text-decoration-none bkg-primary text-white align-self-end rounded-5 shadow shadow-sm py-1 px-2 text-smaller'}
                                            onClick={() => {

                                                setGameID(game.id);
                                                setShow(true)

                                            }}>Convocati <span className={''}>({game?.convocations})</span></Link>
                                    }
                                </Col>
                            </Row>
                        </Card>
                    </Col>


                )
            }) : 'Nessuna gara in programma'}

            <GamesConvocatedModal show={show} convocatedMembers={convocationList} setShow={setShow}
                                  setConvocationList={setConvocationList}/>
        </Row>
    )
}
export default GamesListGrid