import axiosInstance from "../AxiosInstance";


export const retrieveLoginData = ({login, enteredEmail, enteredPassword, setErrors, navigate, setIsLoading, setGeneralErrors}) => {
    setIsLoading(true)
    axiosInstance.post('/login', {
        email: enteredEmail,
        password: enteredPassword,
    }).then((response) => {
        setIsLoading(false)
        // Handle successful response (status code 200)
        console.log(response)
        login(JSON.stringify(response.data.data.user), response.data.data.token)
        navigate('/home')
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setGeneralErrors(error.response.data.message)
            console.error('Error:', error);
        }
        setIsLoading(false)
    });
}

export const coachAutomaticLogin = (props) => {
    props.setIsLoading(true)
    axiosInstance.post('/token', {token: props.token}).then((response) => {
        props.setIsLoading(false)
        // Handle successful response (status code 200)
        console.log(response)
         props.login(JSON.stringify(response.data.data.user), response.data.data.token)
        // props.navigate('/teams/lista-teams')
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            props.setIsLoading(false)
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            console.error('Error:', error);
        }
    });
}