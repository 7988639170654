import axiosInstance from "../AxiosInstance";
import {retrieveTeamDetail} from "../Teams/TeamsFetch";

export const gamesList = (props) => {
    props.setIsLoading(true)

    axiosInstance.get('/games').then((response) => {

        console.log('Games List', response)
     /*   props.setMessage(response.data.message)*/
        props.setGames(response.data.data)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const gamesConvocatedList = (props) => {
    props.setIsLoading(true)
    console.log(props.gameID)
    axiosInstance.get('/games/'+props.gameID).then((response) => {

        console.log('Games Convocates', response)
        /*   props.setMessage(response.data.message)*/
        props.setConvocationList(response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}