import {React} from "react";
import {HexColorPicker} from 'react-colorful'

const CustomColorPicker = (props) => {
   return(
       <HexColorPicker color={props.color} onChange={props.setColor} />
   )

}

export default CustomColorPicker