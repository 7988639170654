import {Col, Pagination, Row} from "react-bootstrap";
import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";
import {GrDocumentDownload} from 'react-icons/gr'
import React from "react";

const TablePagination = (props) => {
    const perPage = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    console.log(props.pagination)
    const handleRecordChange = (e) => {
        props.setPerPage(e.currentTarget.value)

    }

    return (
        <>
            <Row className={'rounded-2 bg-white  my-3 py-2 align-items-center mx-0'}>

                    <Col md={2} className={'text-start'}>Record per pagina: &nbsp;&nbsp;
                        <select onChange={handleRecordChange} className={'rounded-2'} value={props.perPage}>
                            {perPage.map((records, index) => {
                                return (
                                    <option key={index} value={'per_page=' + records}>{records}</option>
                                )
                            })
                            }
                        </select>
                    </Col>



                    <Col className={''}>
                        <Pagination className={'mb-0 justify-content-end mt-3 mt-sm-0'}>
                            {props.pagination && props.pagination.length > 0 && props.pagination.map((i, index, array) => {
                                    if (i.url != null && (array.indexOf(i) <= 8 || array.indexOf(i) > 13)) {

                                        return (
                                            <Pagination.Item key={index} active={i.active} href={null}
                                                             onClick={() => props.setPageUrl(i.url)}>

                                                {sanitizeHtml(i.label)

                                                }
                                                {}
                                            </Pagination.Item>

                                        )
                                    }
                                })
                            }
                        </Pagination>
                    </Col>
            </Row>
        </>
    )
}

export default TablePagination